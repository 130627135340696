import type { ApiCredentialsProperties } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { ApiCredentialsNameSelection } from './apiCredentialsNameSelection';
import { ApiCredentialsScopesSelection } from './apiCredentialsScopesSelection';
import { ApiCredentialsTokenReveal } from './apiCredentialsTokenReveal';

export type UpsertApiCredentialsState = ApiCredentialsProperties & {
  clientSecret?: string;
  clientId?: string;
};

export const UpsertApiCredentialsFlow: Step<UpsertApiCredentialsState> = {
  name: 'Name Selection',
  Component: ApiCredentialsNameSelection,
  next: [
    {
      name: 'Scopes Selection',
      Component: ApiCredentialsScopesSelection,
      next: [
        {
          name: 'Token Reveal',
          Component: ApiCredentialsTokenReveal,
        },
      ],
    },
  ],
};
