/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class QueryDBResultResponse {
  /**
  * List of the column names in the returned records.
  */
  'columns': Array<string>;
  /**
  * List of records, returned as an array of arrays. The inner array contains the values of the columns in the same order as returned in `columns`. 
  */
  'records': Array<Array<string>>;
  /**
  * Cursor that points to the first record of the next page of results. Pass this value in the next request. 
  */
  'nextToken': string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "columns",
      "baseName": "columns",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "records",
      "baseName": "records",
      "type": "Array<Array<string>>",
      "format": ""
    },
    {
      "name": "nextToken",
      "baseName": "nextToken",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return QueryDBResultResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

