/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AccountIdFilters } from '../models/AccountIdFilters';
import { AppFilters } from '../models/AppFilters';
import { BackupPoliciesFilters } from '../models/BackupPoliciesFilters';
import { BackupRegionsFilters } from '../models/BackupRegionsFilters';
import { BackupStatusFilters } from '../models/BackupStatusFilters';
import { BooleanFilters } from '../models/BooleanFilters';
import { ControlsFilters } from '../models/ControlsFilters';
import { DataClassesFilters } from '../models/DataClassesFilters';
import { EnvironmentFilters } from '../models/EnvironmentFilters';
import { IdFilters } from '../models/IdFilters';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { ResourceNameFilters } from '../models/ResourceNameFilters';
import { ResourceTypeFilters } from '../models/ResourceTypeFilters';
import { SeverityFilters } from '../models/SeverityFilters';
import { SourceRegionFilters } from '../models/SourceRegionFilters';
import { SubnetFilters } from '../models/SubnetFilters';
import { TagKeyValuesFilters } from '../models/TagKeyValuesFilters';
import { TagKeysFilters } from '../models/TagKeysFilters';
import { VaultFilters } from '../models/VaultFilters';
import { VpcPropertyFilters } from '../models/VpcPropertyFilters';

export class InventoryFilterConditions {
  'id'?: IdFilters;
  'isMutedViolation'?: BooleanFilters | null;
  'providerResourceId'?: ResourceIdFilters;
  'resourceName'?: ResourceNameFilters;
  'resourceType'?: ResourceTypeFilters;
  'environment'?: EnvironmentFilters;
  'accountId'?: AccountIdFilters;
  'apps'?: AppFilters;
  'sourceRegion'?: SourceRegionFilters;
  'vpc'?: VpcPropertyFilters;
  'subnets'?: SubnetFilters;
  'dataClasses'?: DataClassesFilters;
  'backupPolicies'?: BackupPoliciesFilters;
  'vaults'?: VaultFilters;
  'backupStatus'?: BackupStatusFilters;
  'backupRegions'?: BackupRegionsFilters;
  'severity'?: SeverityFilters;
  'controls'?: ControlsFilters;
  'tagKeys'?: TagKeysFilters;
  'tagKeyValues'?: TagKeyValuesFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "isMutedViolation",
      "baseName": "isMutedViolation",
      "type": "BooleanFilters",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "ResourceIdFilters",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "ResourceNameFilters",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceTypeFilters",
      "format": ""
    },
    {
      "name": "environment",
      "baseName": "environment",
      "type": "EnvironmentFilters",
      "format": ""
    },
    {
      "name": "accountId",
      "baseName": "accountId",
      "type": "AccountIdFilters",
      "format": ""
    },
    {
      "name": "apps",
      "baseName": "apps",
      "type": "AppFilters",
      "format": ""
    },
    {
      "name": "sourceRegion",
      "baseName": "sourceRegion",
      "type": "SourceRegionFilters",
      "format": ""
    },
    {
      "name": "vpc",
      "baseName": "vpc",
      "type": "VpcPropertyFilters",
      "format": ""
    },
    {
      "name": "subnets",
      "baseName": "subnets",
      "type": "SubnetFilters",
      "format": ""
    },
    {
      "name": "dataClasses",
      "baseName": "dataClasses",
      "type": "DataClassesFilters",
      "format": ""
    },
    {
      "name": "backupPolicies",
      "baseName": "backupPolicies",
      "type": "BackupPoliciesFilters",
      "format": ""
    },
    {
      "name": "vaults",
      "baseName": "vaults",
      "type": "VaultFilters",
      "format": ""
    },
    {
      "name": "backupStatus",
      "baseName": "backupStatus",
      "type": "BackupStatusFilters",
      "format": ""
    },
    {
      "name": "backupRegions",
      "baseName": "backupRegions",
      "type": "BackupRegionsFilters",
      "format": ""
    },
    {
      "name": "severity",
      "baseName": "severity",
      "type": "SeverityFilters",
      "format": ""
    },
    {
      "name": "controls",
      "baseName": "controls",
      "type": "ControlsFilters",
      "format": ""
    },
    {
      "name": "tagKeys",
      "baseName": "tagKeys",
      "type": "TagKeysFilters",
      "format": ""
    },
    {
      "name": "tagKeyValues",
      "baseName": "tagKeyValues",
      "type": "TagKeyValuesFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

