import { Stack } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';

import BackupVaultCard from './backupVaultCard';

interface BackupVaultSelectionProps {
  onSelect: (vault: BackupVault) => void;
  selectedVaults: string[];
  loading?: boolean;
  vaults?: BackupVault[];
}

export const BackupVaultSelection = (props: BackupVaultSelectionProps) => {
  const { vaults, loading } = props;

  return (
    <Stack direction='row' gap='24px' flexWrap={'wrap'}>
      {loading
        ? [...Array(3)].map((_, index) => (
            <BackupVaultCard
              key={index}
              loading={true}
              style={{ width: '320px', height: '184px' }}
            />
          ))
        : vaults?.map((vault, idx) => (
            <BackupVaultCard
              style={{
                width: '320px',
                height: '184px',
              }}
              key={idx}
              data={vault}
              editable={false}
              onClick={props.onSelect}
              selected={props.selectedVaults.includes(vault.id)}
            />
          ))}
    </Stack>
  );
};
