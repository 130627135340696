/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureStorageAccount } from '../models/AzureStorageAccount';

export class ListAzureStorageAccountContainersRequest {
  'storageAccount': AzureStorageAccount;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "storageAccount",
      "baseName": "storageAccount",
      "type": "AzureStorageAccount",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAzureStorageAccountContainersRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

