import { Divider, Stack, Typography } from '@mui/material';
import classnames from 'classnames';

import { Icon } from '@/components/shared/icon';

import { getTestId } from './panelWrapper';

export interface PanelHeaderProps {
  onClose: () => void;
  onBack?: () => void;
  hideBorder?: boolean;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  alignTitles?: boolean;
  testIdPrefix?: string;
}

export const PanelHeader = (props: PanelHeaderProps) => {
  const {
    title,
    subtitle,
    testIdPrefix,
    onClose,
    onBack,
    hideBorder,
    alignTitles,
  } = props;

  return (
    <Stack>
      <Stack
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        padding='16px 24px'
      >
        <Stack alignItems='center' direction='row' className='flex-1'>
          {onBack && (
            <Icon
              iconClass={`material-symbols-chevron-left-rounded`}
              className='mr-[8px] cursor-pointer'
              onClick={onBack}
            />
          )}
          <Stack className='flex-1'>
            {typeof title === 'string' ? (
              <Typography
                className='font-semibold'
                data-testid={getTestId(testIdPrefix, 'side-panel-title')}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
          </Stack>
        </Stack>
        <div className='flex gap-4'>
          <Icon
            onClick={onClose}
            iconClass='material-symbols-close-rounded'
            data-testid={getTestId(testIdPrefix, 'side-panel-close-button')}
          />
        </div>
      </Stack>
      {subtitle &&
        (typeof title === 'string' ? (
          <Typography
            className={classnames([
              { 'px-[24px]': alignTitles, 'px-[40px]': !alignTitles },
              'pb-[20px] pt-[8px]',
            ])}
            variant='subtitle1'
            fontSize={14}
          >
            {subtitle}
          </Typography>
        ) : (
          subtitle
        ))}
      {!hideBorder && <Divider />}
    </Stack>
  );
};
