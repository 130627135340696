import type { Role } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  UpsertCustomRoleFlow,
  type UpsertCustomRoleState,
} from './upsertCustomRoleFlow';

interface UpsertRoleSidePanelProps {
  entity: Role;
  onSave: (role: Role) => void;
  onAbort: () => void;
}

export function CustomRolePanel(props: UpsertRoleSidePanelProps) {
  const save = (state: UpsertCustomRoleState) => {
    props.onSave(state);
    return true;
  };

  return (
    <Wizard<UpsertCustomRoleState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={
        props.entity.id ? UpsertCustomRoleFlow.next! : [UpsertCustomRoleFlow]
      }
      initialState={{
        ...props.entity,
      }}
      title={
        props.entity.id
          ? `Edit ${props.entity.isProjectRole ? 'Project' : 'Global Settings'} Role`
          : `Create ${props.entity.isProjectRole ? 'Project' : 'Global Settings'} Role`
      }
    />
  );
}
