'use client';

import { Box } from '@mui/material';
import { usePathname, redirect } from 'next/navigation';
import { type PropsWithChildren } from 'react';

import { Loader } from '@/components/layout/loading';
import { useFeatureFlags, useLaunchDarkly } from '@/contexts/useFeatureFlags';
import { useUser } from '@/contexts/useUser';

export function AuthChecker({ children }: PropsWithChildren) {
  const { sourceAccounts, user, currentProjectId } = useUser();
  const { skipOnboarding } = useFeatureFlags();
  const pathname = usePathname();
  const { isReady } = useLaunchDarkly();

  const isApplicationReady =
    !!user && !!currentProjectId && !!sourceAccounts && isReady;

  if (!isApplicationReady) {
    return <LoaderComponent />;
  }

  if (skipOnboarding && user?.selectedRole.isProjectRole) {
    redirect('/docs');
  }

  if (
    sourceAccounts?.length ||
    skipOnboarding ||
    pathname.startsWith('/onboarding')
  ) {
    return <>{children}</>;
  }

  redirect('/onboarding');
}

const LoaderComponent = () => (
  <Box
    className='h-full'
    sx={() => ({
      background: 'var(--mui-palette-background-pageGradient)',
    })}
  >
    <Loader />
  </Box>
);
