/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The initial state of the organization.
*/
export enum State {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  StateUnspecified = 'STATE_UNSPECIFIED'
}
