'use client';

import type {
  DatesSetArg,
  DayCellContentArg,
  EventContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import {
  SnapshotStatus,
  type SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import { PITCalendarWrapper } from './PITCalendarWrapper';
import { SnapshotIndicator } from './SnapshotIndicator';

import { PendingCircle } from '../panels/instance/tabs/pendingCircle';

export interface SnapshotMetadata {
  id: string;
  vaultId: string;
  pointInTime: Date;
  properties?: { volumeProperties?: SnapshotPropertiesVolumePropertiesInner[] };
}

interface PointInTimeEvent {
  indicatorColor: string | null;
  vaultName?: string;
  start: Date;
  status: SnapshotStatus;
}

interface InternalPointInTimeEvent extends PointInTimeEvent {
  additionalEventsInDate?: number;
}

interface PointsInTimeCalendarProps {
  isLoading?: boolean;
  events: PointInTimeEvent[];
  selectedDate: Date | undefined;
  onDateSelected: (date: Date | undefined) => void;
  onDateRangeChanged: (start: Date, end: Date) => void;
}

export const PointsInTimeCalendar = (props: PointsInTimeCalendarProps) => {
  const { showPendingSnapshots } = useFeatureFlags();
  const renderEventContent = renderEventContentFactory(showPendingSnapshots);
  const [summarizedEvents, setSummarizedEvents] = useState<
    InternalPointInTimeEvent[]
  >([]);
  useEffect(() => {
    const map = new Map<string, InternalPointInTimeEvent[]>();
    const sorted = props.events.sort(
      (a, b) => a.start.getTime() - b.start.getTime()
    );

    sorted.forEach((event) => {
      const date = event.start.toDateString();
      const events = map.get(date) || [];
      if (events.length === 2) {
        events[1].additionalEventsInDate =
          (events[1].additionalEventsInDate || 0) + 1;
      } else {
        events.push(event);
      }
      map.set(date, events);
    });

    // setting end event to be on the same day as the start one
    const events = [...map.values()].flat().map((event) => {
      const start = event.start;
      const end = new Date(start);
      end.setSeconds(end.getSeconds() + 1);
      if (start.getDate() !== end.getDate()) {
        start.setSeconds(start.getSeconds() - 1);
        end.setSeconds(end.getSeconds() - 1);
      }
      return {
        ...event,
        start,
        end,
      };
    });

    setSummarizedEvents(events);
  }, [props.events]);

  return (
    <PITCalendarWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        fixedWeekCount={false}
        showNonCurrentDates={false}
        buttonText={{ today: 'Today' }}
        dayCellContent={renderDayCell}
        dayCellClassNames={(args) => {
          const classes: string[] = [];
          if (props.isLoading) {
            classes.push('cell-loading');
          }

          const date = new Date(args.date.toDateString());
          if (props.selectedDate?.getTime() === date.getTime()) {
            classes.push('cell-selected');
          }

          if (
            summarizedEvents.every(
              (x) => x.start.toDateString() !== date.toDateString()
            )
          ) {
            classes.push('fc-day-disabled');
          }

          return classes;
        }}
        eventClick={(args) => {
          if (args.event.start) {
            props.onDateSelected(new Date(args.event.start.toDateString()));
          }
        }}
        dateClick={(args) => {
          if (
            summarizedEvents.some(
              (x) => x.start.toDateString() === args.date.toDateString()
            )
          ) {
            props.onDateSelected(new Date(args.date.toDateString()));
          }
        }}
        selectable={true}
        dayHeaders={false}
        events={summarizedEvents}
        eventContent={renderEventContent}
        datesSet={(args: DatesSetArg) => {
          props.onDateRangeChanged(args.start, args.end);
        }}
        height='auto'
      />
    </PITCalendarWrapper>
  );
};

const renderEventContentFactory =
  (showPendingSnapshots: boolean) => (args: EventContentArg) => {
    return (
      <div data-testid='snapshot-dot'>
        {showPendingSnapshots &&
          args.event._def.extendedProps.status === SnapshotStatus.Pending && (
            <PendingCircle
              data-testid='snapshot-pending-indicator'
              backgroundColor={args.event._def.extendedProps.indicatorColor}
            />
          )}

        {args.event._def.extendedProps.status !== SnapshotStatus.Pending && (
          <SnapshotIndicator
            data-testid='snapshot-indicator'
            backgroundColor={
              args.event._def.extendedProps.indicatorColor || null
            }
          />
        )}
        {args.event._def.extendedProps.additionalEventsInDate &&
          args.event._def.extendedProps.additionalEventsInDate > 0 && (
            <span data-testid='snapshot-more-indicator' className='ml-[4px]'>
              +{args.event._def.extendedProps.additionalEventsInDate}
            </span>
          )}
      </div>
    );
  };

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function renderDayCell(arg: DayCellContentArg) {
  return (
    <div>
      {weekday[arg.date.getDay()]} {arg.dayNumberText}
    </div>
  );
}
