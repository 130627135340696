import type { DBServerRecord, Configuration } from '@repo/api-gw-sdk';
import {
  GetDatabasesByServerApi,
  GetSnapshotsByServerApi,
  DatabaseSnapshotsApi,
  retry,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/db';

export const db = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new GetSnapshotsByServerApi(config);
  const getDbApi = new GetDatabasesByServerApi(config);
  const databaseSnapshotsApi = new DatabaseSnapshotsApi(config);

  return {
    snapshots: (serverId: string, startDate: Date, endDate: Date) =>
      httpClient.execute(
        [baseUrl, serverId, startDate, endDate, currentProjectId],
        () =>
          snapshotsApi.getSnapshotsByServer(
            serverId,
            startDate,
            endDate,
            currentProjectId
          )
      ),
    objectTree: (serverId: string, snapshotId: string) => {
      return httpClient.execute(
        [baseUrl, serverId, snapshotId, currentProjectId],
        () => {
          if (!snapshotId) {
            return Promise.resolve(undefined);
          }
          return getDbApi.getDatabasesByServer(
            serverId,
            snapshotId,
            currentProjectId
          );
        }
      );
    },
    objectTreeMultiple: (serverIds: string[], snapshotId: string) => {
      return httpClient.execute(
        [baseUrl, serverIds, snapshotId, currentProjectId],
        () => {
          if (!snapshotId) {
            return Promise.resolve(undefined);
          }

          return Promise.all(
            serverIds.map((serverId) =>
              getDbApi.getDatabasesByServer(
                serverId,
                snapshotId,
                currentProjectId
              )
            )
          ).then((results) => {
            const distinctResults: DBServerRecord[] = [];
            for (const y of results) {
              if (!distinctResults.some((x) => x.serverId === y.serverId)) {
                distinctResults.push(y);
              }
            }

            return distinctResults;
          });
        }
      );
    },
    querySnapshot: (
      snapshotId: string,
      databaseName: string,
      query: string,
      targetAccountId: string,
      bucketName: string,
      bucketPrefix?: string,
      signal?: AbortSignal
    ) =>
      retry(
        () =>
          databaseSnapshotsApi.runQuery(
            currentProjectId,
            snapshotId,
            {
              restoreAccountId: targetAccountId,
              databaseName,
              query,
              destination: {
                s3Bucket: {
                  bucketName,
                  prefix: bucketPrefix,
                },
              },
            },
            {
              signal,
            }
          ),
        3 // Picked by Peleg as his lucky number
      ),
    queryStatus: (queryId: string) =>
      databaseSnapshotsApi.getQueryStatus(currentProjectId, queryId),
    queryResults: (queryId: string) =>
      databaseSnapshotsApi.getQueryResult(currentProjectId, queryId),
    generateQuery: (text: string) =>
      httpClient.post('/projects/{projectId}/db/generate-query', { text }),
  };
};
