import type { Condition, Control } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  ControlCreationFlow,
  type ControlEditState,
} from './ControlCreationFlow';

export interface ControlWizardProps {
  entity: Omit<Control, 'condition'> & { condition?: Condition };
  onSave: (control: Control) => void;
  onAbort: () => void;
}

export default function ControlWizard(props: ControlWizardProps) {
  const save = (state: ControlEditState) => {
    props.onSave({
      ...props.entity,
      name: state.name,
      condition: state.condition ?? { operator: 'AND', conditions: [] },
      rules: state.rules,
    });

    return true;
  };

  return (
    <Wizard<ControlEditState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={props.entity.id ? ControlCreationFlow.next! : [ControlCreationFlow]}
      initialState={{
        id: props.entity.id,
        name: props.entity.name,
        condition: props.entity.condition,
        rules: props.entity.rules,
      }}
      title={props.entity.id ? 'Edit Control' : 'Create Control'}
      stepperLabels={[]}
    />
  );
}
