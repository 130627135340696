/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ListAzureUnassignedNetworkInterfacesRequest {
  /**
  * The resource group of the network interfaces
  */
  'resourceGroup': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAzureUnassignedNetworkInterfacesRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

