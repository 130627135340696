import type {
  UpdateApiCredentialsRequest,
  Configuration,
  CreateApiCredentialsRequest,
} from '@repo/api-gw-sdk';
import {
  DeleteApiCredentialsApi,
  ListApiCredentialsApi,
  UpdateApiCredentialsApi,
  CreateApiCredentialsApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/api-credentials';

export const apiCredentials = (
  httpClient: HttpClient,
  config: Configuration
) => {
  const listApiCredentialsApi = new ListApiCredentialsApi(config);
  const createApiCredentialsApi = new CreateApiCredentialsApi(config);
  const deleteApiCredentialsApi = new DeleteApiCredentialsApi(config);
  const updateApiCredentialsApi = new UpdateApiCredentialsApi(config);

  return {
    list: () =>
      httpClient.execute([baseUrl], () =>
        listApiCredentialsApi.listApiCredentials()
      ),
    create: (apiCredentials: CreateApiCredentialsRequest) =>
      createApiCredentialsApi.createApiCredentials(apiCredentials),
    update: (id: string, apiCredentials: UpdateApiCredentialsRequest) =>
      updateApiCredentialsApi.updateApiCredentials(id, apiCredentials),
    delete: (id: string) => deleteApiCredentialsApi.deleteApiCredentials(id),
  };
};
