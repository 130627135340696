/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The type of the db snapshot (e.g. DB_SNAPSHOT_TYPE_SCANNING, DB_SNAPSHOT_TYPE_BACKUP, etc.)
*/
export enum DBSnapshotType {
  DB_SNAPSHOT_TYPE_UNKNOWN = 0,
  DB_SNAPSHOT_TYPE_SCANNING = 1,
  DB_SNAPSHOT_TYPE_BACKUP = 2
}
