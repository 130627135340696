import { Box, CardContent, Divider, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { AWSSubnetSelect } from '@/components/regions/awsSubnetSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { SecurityGroupSelect } from '@/components/regions/securityGroupSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { InstanceProfileSelect } from '../instanceProfileSelection';
import { InstanceTypeSelect } from '../instanceTypeSelection';
import type {
  RestoreBaseState,
  RestoreEc2InstanceState,
} from '../restoreEc2InstanceWizard';

export const InstanceConfiguration = (
  props: StepProps<RestoreEc2InstanceState>
) => {
  const getValue = useCallback(
    <T extends keyof RestoreBaseState>(value: T): RestoreBaseState[T] => {
      // keeping undefined for cases where the user selected empty option intentionally.
      return value in props.wizardState.crossStepsState
        ? props.wizardState.crossStepsState[value]
        : props.wizardState.initialState[value];
    },
    [props.wizardState.crossStepsState, props.wizardState.initialState]
  );

  const [scopedState, setScopedState] = useState<RestoreBaseState>({
    regionName: getValue('regionName'),
    AWSSubnetId: getValue('AWSSubnetId'),
    instanceType: getValue('instanceType'),
    instanceProfile: getValue('instanceProfile'),
    securityGroupId: getValue('securityGroupId'),
    tags: getValue('tags'),
    keepOriginalTags: getValue('keepOriginalTags'),
  });

  useEffect(() => {
    setScopedState((state) => ({
      ...state,
      AWSSubnetId: getValue('AWSSubnetId'),
      instanceProfile: getValue('instanceProfile'),
      instanceType: getValue('instanceType'),
      securityGroupId: getValue('securityGroupId'),
    }));
  }, [getValue, scopedState.regionName]);

  useEffect(() => {
    setScopedState((state) => ({
      ...state,
      instanceType: getValue('instanceType'),
      securityGroupId: getValue('securityGroupId'),
    }));
  }, [getValue, scopedState.AWSSubnetId]);

  const onFieldChange = <T extends keyof RestoreBaseState>(
    field: T,
    value: RestoreBaseState[T],
    isUserChange?: boolean
  ) => {
    setScopedState((state) => ({
      ...state,
      [field]: value,
    }));
    if (isUserChange) {
      props.setWizardState((state) => ({
        ...state,
        crossStepsState: { ...state.crossStepsState, [field]: value },
      }));
    }
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      nextButtonText='Next'
      onBackClick={props.back}
      canGoNext={() => {
        if (
          !scopedState.regionName ||
          !scopedState.instanceType ||
          !scopedState.AWSSubnetId
        ) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        props.setWizardState((state) => ({
          ...state,
          crossStepsState: { ...state.crossStepsState, ...scopedState },
        }));
        props.setNextStep(props.currentStep.next?.[0]);
      }}
    >
      <Box className='my-[24px] mx-[40px]'>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <RegionSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            onChange={(regionName, isUserChange) =>
              onFieldChange('regionName', regionName, isUserChange)
            }
          />
          <AWSSubnetSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            subnetId={scopedState.AWSSubnetId}
            onChange={(AWSSubnetId, isUserChange) =>
              onFieldChange('AWSSubnetId', AWSSubnetId, isUserChange)
            }
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <InstanceTypeSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            subnetId={scopedState.AWSSubnetId}
            instanceType={scopedState.instanceType}
            onChange={(instanceType, isUserChange) =>
              onFieldChange('instanceType', instanceType, isUserChange)
            }
          />
          <SecurityGroupSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            securityGroupId={scopedState.securityGroupId}
            isVpcMandatory={false}
            subnetId={scopedState.AWSSubnetId}
            isSubnetIdMandatory={true}
            onChange={(securityGroupId, event) =>
              onFieldChange('securityGroupId', securityGroupId, event)
            }
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <InstanceProfileSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            instanceProfile={scopedState.instanceProfile}
            onChange={(instanceProfile, isUserChange) =>
              onFieldChange('instanceProfile', instanceProfile, isUserChange)
            }
          />
          {/* empty div for getting half size flex item that include tha gaps as well. */}
          <Box className='flex-1'></Box>
        </Stack>
      </Box>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={props.wizardState.initialState.tags || {}}
          tags={scopedState.tags || {}}
          keepOriginalTags={scopedState.keepOriginalTags}
          setKeepOriginalTags={(value) =>
            onFieldChange('keepOriginalTags', value, true)
          }
          onChange={(tags) => onFieldChange('tags', tags, true)}
        />
      </CardContent>
    </StepContainer>
  );
};
