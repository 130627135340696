'use client';

import { useSearchParams } from 'next/navigation';
import {
  useContext,
  useEffect,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react';

import type { Condition } from '@/types/advanceFilter';

import { deserializeSearchParams } from './serialization';
import { useBrowserHistory } from './useBrowserHistory';
import { ViewParametersContext } from './viewParametersProvider';

const useViewParametersSafe = () => {
  const context = useContext(ViewParametersContext);

  if (!context) {
    throw new Error(
      'useViewParameters must be used within a ViewParametersProvider'
    );
  }
  return context;
};

export const useViewParameters = (initialValues?: Record<string, unknown>) => {
  if (initialValues) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRootViewParameters(initialValues);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useBodyViewParameters();
  }
};

const useRootViewParameters = (initialValues: Record<string, unknown>) => {
  const context = useViewParametersSafe();
  const firstRender = useRef(true);
  const searchParams = useSearchParams();

  const state = firstRender.current
    ? {
        ...initialValues,
        ...deserializeSearchParams(searchParams),
      }
    : context.params();

  if (firstRender.current) {
    firstRender.current = false;
    context.setParamsWithoutRender(state);
  }

  useBrowserHistory({
    params: state,
    setParams: (state) => context.setParams(state),
  });

  // Sync context with initialValues
  useEffect(() => {
    const deserializedSearchParams = deserializeSearchParams(searchParams);
    context.setInitialValues({
      ...initialValues,
      ...deserializedSearchParams,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return extractState(state, context.setParams);
};

const useBodyViewParameters = () => {
  const context = useViewParametersSafe();

  const state = context.params();
  return extractState(state, context.setParams);
};

const extractState = (
  state: Record<string, unknown>,
  setParams: Dispatch<SetStateAction<Record<string, unknown>>>
) => {
  const { pageIndex, pageSize, pageToken, sort, query, ...rest } = state;

  return {
    pageIndex: pageIndex as number,
    pageSize: pageSize as number,
    sort: sort as string,
    pageToken: pageToken as string,
    query: query as Condition | undefined,
    ...rest,
    getAll: () => state,
    setParams,
  };
};
