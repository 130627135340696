import type { Account, CloudAccountConfiguration } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  AwsRestoreAccountConfigFlow,
  type AwsRestoreAccountConfigState,
} from './awsRestoreAccountConfigFlow';

interface AwsRestoreAccountConfigPanelProps {
  account: Account;
  config: CloudAccountConfiguration;
  onSave: (accountId: string, entity: CloudAccountConfiguration) => void;
  onAbort: () => void;
}

export function AwsRestoreAccountConfigPanel(
  props: AwsRestoreAccountConfigPanelProps
) {
  const save = (state: AwsRestoreAccountConfigState) => {
    props.onSave(state.account.id, state.config);
    return true;
  };

  return (
    <Wizard<AwsRestoreAccountConfigState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={[AwsRestoreAccountConfigFlow]}
      initialState={{
        account: props.account,
        config: { ...props.config },
      }}
      title={`Edit restore server configuration`}
    />
  );
}
