/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupJobsFilterConditions } from '../models/BackupJobsFilterConditions';
import { SortBackupJobBy } from '../models/SortBackupJobBy';

export class ListBackupJobsRequest {
  'filters'?: BackupJobsFilterConditions;
  'sorts'?: Array<SortBackupJobBy>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "filters",
      "baseName": "filters",
      "type": "BackupJobsFilterConditions",
      "format": ""
    },
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortBackupJobBy>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListBackupJobsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

