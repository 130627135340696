/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ResourceType } from '../models/ResourceType';

export class ResourceDetails {
  /**
  * Eon-assigned resource ID.
  */
  'id': string;
  /**
  * Cloud-provider-assigned resource ID.
  */
  'providerResourceId': string;
  /**
  * Resource display name.
  */
  'resourceName': string;
  'resourceType': ResourceType;
  /**
  * The account ID of the inventory item
  */
  'providerAccountId': string;
  /**
  * Total storage size at the source, in bytes.
  */
  'sourceStorageSizeBytes': number;
  /**
  * The number of eon snapshots of the inventory item
  */
  'eonSnapshotCount'?: number;
  /**
  * The time of the last successful backup
  */
  'lastSuccessfulBackupTime'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "sourceStorageSizeBytes",
      "baseName": "sourceStorageSizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "eonSnapshotCount",
      "baseName": "eonSnapshotCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "lastSuccessfulBackupTime",
      "baseName": "lastSuccessfulBackupTime",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return ResourceDetails.attributeTypeMap;
  }

  public constructor() {
  }
}



