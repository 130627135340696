/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DestinationDetails } from '../models/DestinationDetails';
import { JobExecutionDetails } from '../models/JobExecutionDetails';

export class RestoreJob {
  'jobExecutionDetails': JobExecutionDetails;
  'destinationDetails': DestinationDetails;
  /**
  * Indicates whether the job is granular
  */
  'isGranular'?: boolean;
  /**
  * The output of the restore job
  */
  'outputResources'?: { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "jobExecutionDetails",
      "baseName": "jobExecutionDetails",
      "type": "JobExecutionDetails",
      "format": ""
    },
    {
      "name": "destinationDetails",
      "baseName": "destinationDetails",
      "type": "DestinationDetails",
      "format": ""
    },
    {
      "name": "isGranular",
      "baseName": "isGranular",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "outputResources",
      "baseName": "outputResources",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreJob.attributeTypeMap;
  }

  public constructor() {
  }
}

