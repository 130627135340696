/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupPolicy } from '../models/BackupPolicy';
import { BackupStatus } from '../models/BackupStatus';
import { Classifications } from '../models/Classifications';
import { ControlViolations } from '../models/ControlViolations';
import { DatabaseProperties } from '../models/DatabaseProperties';
import { InventoryResourceMetadata } from '../models/InventoryResourceMetadata';
import { InventoryVault } from '../models/InventoryVault';
import { InventoryVolume } from '../models/InventoryVolume';
import { Provider } from '../models/Provider';
import { ResourceProperties } from '../models/ResourceProperties';
import { ResourceType } from '../models/ResourceType';
import { SnapshotStorage } from '../models/SnapshotStorage';
import { SourceStorage } from '../models/SourceStorage';

export class InventoryResource {
  /**
  * Eon-assigned resource ID.
  */
  'id': string;
  'backupStatus': BackupStatus;
  /**
  * Cloud-provider-assigned resource ID.
  */
  'providerResourceId': string;
  /**
  * Resource display name.
  */
  'resourceName': string;
  /**
  * Resource group ID.
  */
  'resourceGroupId'?: string;
  /**
  * Resource group name.
  */
  'resourceGroupName'?: string;
  'resourceType': ResourceType;
  'classifications'?: Classifications;
  'cloudProvider': Provider;
  /**
  * Date and time the resource was first discovered.
  */
  'dateDiscoveredTime'?: Date;
  /**
  * The tags of the inventory item
  */
  'tags': { [key: string]: string; };
  /**
  * The account ID of the inventory item
  */
  'accountId': string;
  'resourceProperties': ResourceProperties;
  'snapshotStorage': SnapshotStorage;
  /**
  * Date and time the resource record was created in Eon.
  */
  'createdTime'?: Date;
  /**
  * Date and time of the resource\'s latest Eon snapshot.
  */
  'newestSnapshotTime'?: Date;
  /**
  * Date and time of the resource\'s first Eon snapshot.
  */
  'oldestSnapshotTime'?: Date;
  /**
  * The backup policy names of the inventory item
  */
  'backupPolicies': Array<BackupPolicy>;
  /**
  * The vault names of the inventory item
  */
  'vaults': Array<InventoryVault>;
  'sourceStorage': SourceStorage;
  /**
  * List of volumes attached to the resource.
  */
  'volumes'?: Array<InventoryVolume>;
  'controlViolations'?: ControlViolations;
  'databaseProperties'?: DatabaseProperties | null;
  'metadata'?: InventoryResourceMetadata;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "backupStatus",
      "baseName": "backupStatus",
      "type": "BackupStatus",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroupId",
      "baseName": "resourceGroupId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroupName",
      "baseName": "resourceGroupName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "classifications",
      "baseName": "classifications",
      "type": "Classifications",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "dateDiscoveredTime",
      "baseName": "dateDiscoveredTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "accountId",
      "baseName": "accountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceProperties",
      "baseName": "resourceProperties",
      "type": "ResourceProperties",
      "format": ""
    },
    {
      "name": "snapshotStorage",
      "baseName": "snapshotStorage",
      "type": "SnapshotStorage",
      "format": ""
    },
    {
      "name": "createdTime",
      "baseName": "createdTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "newestSnapshotTime",
      "baseName": "newestSnapshotTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "oldestSnapshotTime",
      "baseName": "oldestSnapshotTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "backupPolicies",
      "baseName": "backupPolicies",
      "type": "Array<BackupPolicy>",
      "format": ""
    },
    {
      "name": "vaults",
      "baseName": "vaults",
      "type": "Array<InventoryVault>",
      "format": ""
    },
    {
      "name": "sourceStorage",
      "baseName": "sourceStorage",
      "type": "SourceStorage",
      "format": ""
    },
    {
      "name": "volumes",
      "baseName": "volumes",
      "type": "Array<InventoryVolume>",
      "format": ""
    },
    {
      "name": "controlViolations",
      "baseName": "controlViolations",
      "type": "ControlViolations",
      "format": ""
    },
    {
      "name": "databaseProperties",
      "baseName": "databaseProperties",
      "type": "DatabaseProperties",
      "format": ""
    },
    {
      "name": "metadata",
      "baseName": "metadata",
      "type": "InventoryResourceMetadata",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryResource.attributeTypeMap;
  }

  public constructor() {
  }
}



