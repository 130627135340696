import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

export const NetworkInterfaceSelect = ({
  accountId,
  resourceGroup,
  networkInterface,
  onChange,
}: {
  accountId?: string;
  resourceGroup?: string;
  networkInterface?: string;
  onChange: (networkInterface: string) => void;
}) => {
  if (!accountId || !resourceGroup) {
    return <EmptySelect title='Network Interface*' selectClass='h-[58px]' />;
  }

  return (
    <InnerNetworkInterfaceSelect
      accountId={accountId}
      resourceGroup={resourceGroup}
      networkInterface={networkInterface}
      onChange={onChange}
    />
  );
};

const InnerNetworkInterfaceSelect = ({
  accountId,
  resourceGroup,
  networkInterface,
  onChange,
}: {
  accountId: string;
  resourceGroup: string;
  networkInterface?: string;
  onChange: (networkInterface: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.azureRestoreConfig.listNetworkInterfaces(
    accountId,
    resourceGroup
  );

  if (!body?.networkInterfaces?.length) {
    return <EmptySelect title='Network Interface*' selectClass='h-[58px]' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Network Interface*</FormLabel>
      <Select
        className='h-[58px]'
        size='small'
        value={networkInterface || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {body.networkInterfaces.map((network) => (
          <MenuItem key={network} value={network}>
            {network}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
