import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

const title = 'VPC*';

export const VpcSelect = ({
  accountId,
  regionName,
  vpc,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  vpc?: string;
  onChange: (vpc: string) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerVpcSelect
      accountId={accountId}
      regionName={regionName}
      vpc={vpc}
      onChange={onChange}
    />
  );
};

const InnerVpcSelect = ({
  accountId,
  regionName,
  vpc,
  onChange,
}: {
  accountId: string;
  regionName: string;
  vpc?: string;
  onChange: (vpc: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.rdsSubnetGroup.list(accountId, regionName);

  if (!body?.rdsSubnetGroups.length) {
    return <EmptySelect title={title} />;
  }

  const options = Array.from(
    new Set(body.rdsSubnetGroups.map((value) => value.vpc))
  ).sort();

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        data-testid='vpsSelect'
        size='small'
        value={vpc || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((value, idx) => (
          <MenuItem data-testid={`vpc-${idx}`} key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
