import { Radio, Stack, Typography } from '@mui/material';
import { SnapshotStatus, type Snapshot } from '@repo/api-gw-sdk';
import classnames from 'classnames';

import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import type { BackupVault } from '@/data/vaults/backupVault';
import { isDateValid } from '@/utils/dateTime';
import { dayjs, TIME_FORMAT } from '@/utils/dayjs';

import { PendingCircle } from './pendingCircle';

interface SnapshotDetailsHeaderProps {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected: boolean;
  volume?: string; // todo: remove this once merging fullInstanceRestore
  onSelect: () => void;
  snapshotSelection: boolean;
}

export const SnapshotDetailsHeader = ({
  snap,
  isSelected,
  onSelect,
  vault,
  volume,
  snapshotSelection,
}: SnapshotDetailsHeaderProps) => {
  const { showPendingSnapshots } = useFeatureFlags();
  const date = isDateValid(snap.pointInTime)
    ? snap.pointInTime
    : snap.createTime;

  return (
    <Stack
      className={classnames('flex-1', { 'cursor-pointer': snapshotSelection })}
      direction='row'
      gap='16px'
      alignItems='center'
      onClick={() => onSelect()}
    >
      {snapshotSelection && <Radio size='small' checked={isSelected} />}
      {vault &&
        showPendingSnapshots &&
        snap.status === SnapshotStatus.Pending && (
          <PendingCircle backgroundColor={vault.backgroundColor} />
        )}
      {vault && snap.status !== SnapshotStatus.Pending && (
        <SnapshotIndicator backgroundColor={vault.backgroundColor || null} />
      )}
      {volume && <Typography variant='body1'>{volume}</Typography>}
      {snap.status !== SnapshotStatus.Pending && (
        <Typography variant='body1'>
          {`${dayjs.utc(date).format(TIME_FORMAT)} UTC`}
        </Typography>
      )}
      {showPendingSnapshots && snap.status === SnapshotStatus.Pending && (
        <Typography variant='body1'>
          {`Started at ${dayjs.utc(date).format(TIME_FORMAT)} UTC`}
        </Typography>
      )}
    </Stack>
  );
};
