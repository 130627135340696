import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';
import { parseJson } from '@/utils/string';

const paramTypes: Record<string, string> = {
  pageIndex: 'number',
  pageSize: 'number',
  query: 'condition',
  startDate: 'date',
  endDate: 'date',
};

export const deserializeSearchParams = (searchParams: URLSearchParams) => {
  return [...searchParams.entries()].reduce<Record<string, unknown>>(
    (agg, [key, value]) => {
      switch (paramTypes[key]) {
        case 'number': {
          const parsed = parseInt(value || '', 10);
          if (!isNaN(parsed)) {
            agg[key] = parsed;
          }
          break;
        }
        case 'boolean': {
          if (value === 'true') {
            agg[key] = true;
          } else if (value === 'false') {
            agg[key] = false;
          }
          break;
        }
        case 'condition': {
          if (value) {
            agg[key] = parseJson(value);
          }
          break;
        }
        case 'date': {
          if (value) {
            agg[key] = new Date(value);
          }
          break;
        }
        default: {
          agg[key] = value;
        }
      }

      return agg;
    },
    {}
  );
};

export const serialize = (params: Record<string, unknown>) => {
  return Object.entries(params).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (key === 'query') {
        if (value) {
          acc[key] = toJsonText(value as Condition);
        }
      } else if (value instanceof Date) {
        acc[key] = value.toISOString();
      } else {
        acc[key] = String(value);
      }
      return acc;
    },
    {}
  );
};
