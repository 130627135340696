/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { CloudAccountType } from '../models/CloudAccountType';
import { Provider } from '../models/Provider';

export class CloudAccountNotificationArguments {
  /**
  * The id of the provider account
  */
  'providerAccountId'?: string;
  /**
  * The project ID
  */
  'projectId'?: string;
  'type'?: CloudAccountType;
  'cloudProvider'?: Provider;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "CloudAccountType",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CloudAccountNotificationArguments.attributeTypeMap;
  }

  public constructor() {
  }
}



