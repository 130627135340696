/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The type of the cloud account
*/
export enum CloudAccountType {
  AccountTypeUnspecified = 'ACCOUNT_TYPE_UNSPECIFIED',
  Source = 'Source',
  Scanning = 'Scanning',
  Vault = 'Vault',
  Restore = 'Restore'
}
