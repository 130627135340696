/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuthParams } from '../models/AuthParams';
import { State } from '../models/State';

export class AwsOrganization {
  /**
  * Eon-assigned account ID.
  */
  'id': string;
  'authParams': AuthParams;
  /**
  * The organization display name in Eon.
  */
  'name': string;
  /**
  * AWS Organization OU ID.
  */
  'organizationUnitId': string;
  /**
  * AWS Organization management account ID.
  */
  'managementAccountId': string;
  'state': State;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "authParams",
      "baseName": "authParams",
      "type": "AuthParams",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "organizationUnitId",
      "baseName": "organizationUnitId",
      "type": "string",
      "format": ""
    },
    {
      "name": "managementAccountId",
      "baseName": "managementAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "state",
      "baseName": "state",
      "type": "State",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsOrganization.attributeTypeMap;
  }

  public constructor() {
  }
}



