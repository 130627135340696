import { Box, Divider, Stack, Typography } from '@mui/material';
import type { CloudAccountConfigurationAwsConfigInner } from '@repo/api-gw-sdk';
import { useRef, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { CircleImage } from '@/components/shared/circleImage';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { CloudProviders } from '@/data/inventory/data';

import type { AwsRestoreAccountConfigState } from './awsRestoreAccountConfigFlow';
import { CloudAccountConfigurationAwsConfigDialog } from './cloudAccountConfigurationAwsConfigDialog';
import { cloudAccountConfigurationAwsSecurityGroupsKeys } from './types';
import { VpcSelectionDialog } from './vpcSelectionDialog';

export const AwsRestoreAccountConfigVpcSelection = (
  props: StepProps<AwsRestoreAccountConfigState>
) => {
  const dal = useDAL();
  const addCustomVPCSettingsButtonRef = useRef<HTMLButtonElement>(null);

  const [showVpcSelectionDialog, setShowVpcSelectionDialog] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >();
  const [editedConfig, setEditedConfig] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >();

  const { body: availabilityZonesBody } = dal.restore.availabilityZones.list(
    props.wizardState.account.id,
    selectedConfig?.region || ''
  );

  return (
    <StepContainer
      hideStepper
      onBackClick={props.back}
      canGoNext={() => true}
      nextButtonText={'Save Changes'}
      backButtonText='Cancel'
      onNextClick={props.finish}
    >
      <Stack>
        <Stack gap='12px' className='px-[50px]'>
          <Stack direction='row' alignItems='center'>
            {CloudProviders[props.wizardState.account.cloudProvider] && (
              <CircleImage
                key={props.wizardState.account.cloudProvider}
                alt={props.wizardState.account.cloudProvider}
                src={
                  CloudProviders[props.wizardState.account.cloudProvider].logo
                }
                className='mr-[8px]'
              />
            )}
            {props.wizardState.account.name
              ? `${
                  props.wizardState.account.name
                } (${props.wizardState.account.providerAccountId})`
              : props.wizardState.account.providerAccountId}
          </Stack>
          <Typography className='max-w-[680px]' lineHeight={'20px'}>
            {`Restore operations are run by a temporary restore server. Unless you specify otherwise, the restore server operates in the destination region and uses the default subnet and security group.`}
          </Typography>
        </Stack>
        <Divider className='my-[40px] mx-[-40px]' />
        <Stack className='px-[50px]' direction='row'>
          <Stack className='w-[280px]' gap='40px'>
            {!!props.wizardState.config.awsConfig.length && (
              <Stack gap='40px'>
                <Typography>Customized VPCs</Typography>
                <Stack gap='24px'>
                  {Object.entries(
                    props.wizardState.config.awsConfig.reduce<
                      Record<string, CloudAccountConfigurationAwsConfigInner[]>
                    >((agg, val) => {
                      agg[val.region] = agg[val.region] || [];
                      agg[val.region].push(val);
                      return agg;
                    }, {})
                  )
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .map(([regionName, configs], index) => (
                      <Stack key={index} gap='24px'>
                        <Typography
                          className='uppercase'
                          variant='body2'
                          sx={{
                            fontSize: '12px',
                          }}
                        >
                          {regionName}
                        </Typography>
                        {configs.map((config) => (
                          <Stack
                            key={config.vpc}
                            justifyContent='space-between'
                            direction='row'
                            alignItems='center'
                            className='pr-[22px] cursor-pointer'
                            sx={{
                              ...(selectedConfig === config
                                ? { borderRight: '2px solid black' }
                                : { borderRight: '2px solid transparent' }),
                            }}
                            onClick={() => setSelectedConfig(config)}
                          >
                            <Typography>{config.vpc}</Typography>
                            <OptionMenu
                              iconClassName='text-textPrimary'
                              icon='material-symbols-more-horiz'
                              options={[
                                {
                                  testId: 'delete-config',
                                  text: 'Delete',
                                  menuItemProps: {
                                    onClick: () => {
                                      if (selectedConfig === config) {
                                        setSelectedConfig(undefined);
                                      }
                                      props.setWizardState((state) => ({
                                        ...state,
                                        config: {
                                          ...state.config,
                                          awsConfig:
                                            state.config.awsConfig.filter(
                                              (x) => x !== config
                                            ),
                                        },
                                      }));
                                    },
                                  },
                                },
                              ]}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    ))}
                </Stack>
              </Stack>
            )}
            <Box>
              <ButtonWithIcon
                ref={addCustomVPCSettingsButtonRef}
                variant='outlined'
                iconClass='material-symbols-add-rounded'
                text={
                  props.wizardState.config.awsConfig.length
                    ? 'Add Another'
                    : 'Add Custom VPC Settings'
                }
                onClick={() => setShowVpcSelectionDialog(true)}
              />
            </Box>
            {!!props.wizardState.config.awsConfig.length && (
              <>
                <Divider className='mt-[20px] ml-[-100px]' />
                <Typography
                  className='w-[188px]'
                  fontSize={'12px'}
                  fontWeight={'500'}
                  color={'var(--mui-palette-text-disabled)'}
                >
                  Default subnets and security groups used for all other VPCs
                </Typography>
              </>
            )}
            {showVpcSelectionDialog && (
              <VpcSelectionDialog
                buttonRef={addCustomVPCSettingsButtonRef}
                accountId={props.wizardState.account.id}
                onClose={(regionName, vpc) => {
                  setShowVpcSelectionDialog(false);
                  if (
                    !regionName ||
                    !vpc ||
                    props.wizardState.config.awsConfig.some(
                      (x) => x.region === regionName && x.vpc === vpc
                    )
                  ) {
                    return;
                  }

                  const config = {
                    region: regionName,
                    vpc,
                    cloudAccountConfigurationAwsSubnets: [],
                    cloudAccountConfigurationAwsSecurityGroups: [],
                  };

                  props.setWizardState((state) => ({
                    ...state,
                    config: {
                      ...state.config,
                      awsConfig: [...state.config.awsConfig, config],
                    },
                  }));
                  setSelectedConfig(config);
                  setEditedConfig(config);
                }}
              />
            )}
          </Stack>
          {!!props.wizardState.config.awsConfig.length && (
            <Divider flexItem orientation='vertical' />
          )}
          {selectedConfig && (
            <Stack className='ml-[40px]' gap='40px' flexGrow={1}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' gap='4px'>
                  <Typography component='span'>{`Restore server in`}</Typography>
                  <Typography component='span' className='font-semibold'>
                    {selectedConfig.vpc}
                  </Typography>
                  <Typography component='span'>{`will use these settings:`}</Typography>
                </Stack>
                <ButtonWithIcon
                  variant='outlined'
                  iconClass='material-symbols-stylus-outline'
                  text='Edit Preferences'
                  onClick={() => setEditedConfig(selectedConfig)}
                />
              </Stack>
              <Stack direction='row' gap='100px'>
                <Stack gap='36px' className='w-[240px]'>
                  <Typography className='font-semibold'>
                    Subnets per AZ
                  </Typography>
                  <Typography lineHeight={'20px'}>
                    {`The restore server will use the specified subnet in the
                    availability zone it’s restoring to.`}
                  </Typography>
                </Stack>
                <Stack flexGrow={1} gap='24px' divider={<Divider />}>
                  {(availabilityZonesBody?.availabilityZones || [])
                    .sort()
                    .map((x) => {
                      const subnetId =
                        selectedConfig.cloudAccountConfigurationAwsSubnets.find(
                          (y) => y.availabilityZone === x
                        )?.subnetId;

                      return (
                        <Stack key={x}>
                          <Stack direction='row' alignItems='center' gap='40px'>
                            <Typography className='w-[150px]'>{x}</Typography>
                            {subnetId && <Typography>{subnetId}</Typography>}
                            {!subnetId && (
                              <Typography
                                color={'var(--mui-palette-text-disabled)'}
                              >
                                Default subnet
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
              <Divider className='my-[20px]' />
              <Stack direction='row' gap='100px'>
                <Stack gap='36px' className='w-[240px]'>
                  <Typography className='font-semibold'>
                    Security groups per resource type
                  </Typography>
                  <Typography lineHeight={'20px'}>
                    {`The restore server will use the specified security group for the type of resource being restored.`}
                  </Typography>
                </Stack>
                <Stack flexGrow={1} gap='24px' divider={<Divider />}>
                  {Object.entries(
                    cloudAccountConfigurationAwsSecurityGroupsKeys
                  ).map(([key, title]) => {
                    const securityGroupId =
                      selectedConfig.cloudAccountConfigurationAwsSecurityGroups.find(
                        (y) => y.key === key
                      )?.securityGroupId;

                    return (
                      <Stack key={key}>
                        <Stack direction='row' alignItems='center' gap='40px'>
                          <Typography className='w-[150px]'>{title}</Typography>
                          {securityGroupId && (
                            <Typography>{securityGroupId}</Typography>
                          )}
                          {!securityGroupId && (
                            <Typography
                              color={'var(--mui-palette-text-disabled)'}
                            >
                              Default security group
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <CloudAccountConfigurationAwsConfigDialog
        accountId={props.wizardState.account.id}
        config={editedConfig}
        availabilityZones={availabilityZonesBody?.availabilityZones || []}
        onCancel={() => setEditedConfig(undefined)}
        onSave={(updatedConfig) => {
          props.setWizardState((state) => ({
            ...state,
            config: {
              ...state.config,
              awsConfig: state.config.awsConfig.map((x) =>
                x === editedConfig ? updatedConfig : x
              ),
            },
          }));
          setSelectedConfig(updatedConfig);
          setEditedConfig(undefined);
        }}
      />
    </StepContainer>
  );
};
