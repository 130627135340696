import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import type { InventoryResource, BackupStatus } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import { BackupStatusIcon } from '@/components/backupStatus/backupStatusIcon';
import { AuthorizationTooltip } from '@/components/tooltips/authorizationTooltip';
import {
  InventoryResourceActionsLevels,
  type ResourceAction,
} from '@/data/inventory/actions';
import { BackupStatuses } from '@/data/inventory/data';

interface BackupStatusCardProps {
  backupStatus: BackupStatus;
  actions: Partial<Record<InventoryResourceActionsLevels, ResourceAction[]>>;
  entity: InventoryResource;
}

export function BackupStatusCard(props: BackupStatusCardProps) {
  const { backupStatus, actions, entity } = props;
  const { iconColor, title, fullDescription } = BackupStatuses[backupStatus];

  const moreActions = actions[InventoryResourceActionsLevels.SubMore];
  const primaryActions = actions[InventoryResourceActionsLevels.SubPrimary];
  const secondaryActions = actions[InventoryResourceActionsLevels.SubSecondary];

  return (
    <Box
      margin={'40px 0 24px'}
      padding={'24px 0 24px'}
      border={'1px solid transparent'}
      borderRadius={'16px'}
      sx={{
        background: `linear-gradient(var(--mui-palette-background-paper) 0 0) padding-box,  ${iconColor} border-box`,
      }}
    >
      <Stack marginLeft={'24px'} marginRight={'24px'}>
        <CardHeader actions={moreActions || []} entity={entity} />
        <Stack alignItems={'center'} spacing={'12px'}>
          <BackupStatusIcon backupStatus={backupStatus} />
          <Typography
            data-testid='backup-status-title'
            fontSize={'16px'}
            sx={{ color: 'var(--mui-palette-text-primary) !important' }}
          >
            {title}
          </Typography>
          <Typography
            align={'center'}
            width={'65%'}
            className='text-textSecondary mb-[12px]'
            lineHeight={'20px'}
          >
            {fullDescription}
          </Typography>
          <CardFooter
            primaryActions={primaryActions || []}
            secondaryActions={secondaryActions || []}
            entity={entity}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

function CardHeader(props: {
  actions: ResourceAction[];
  entity: InventoryResource;
}) {
  const { actions, entity } = props;

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
    >
      {/* todo: Missing text: "Since January 1, 2024" text */}
      <Typography className='font-semibold'>Backup status</Typography>
      {!!actions.length && (
        <OptionMenu
          data-testid='backup-status-card-options'
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={actions.map((action) => ({
            text: action.title || action.tooltip,
            menuItemProps: {
              onClick: () => action.execute(entity),
              disabled: action.disabled?.(),
            },
            testId: action.testId,
          }))}
        />
      )}
    </Stack>
  );
}

function CardFooter(props: {
  primaryActions: ResourceAction[];
  secondaryActions: ResourceAction[];
  entity: InventoryResource;
}) {
  const { primaryActions, secondaryActions, entity } = props;

  if (!primaryActions.length && !secondaryActions.length) {
    return null;
  }

  return (
    <Fragment>
      <Divider
        flexItem
        variant='middle'
        className='mx-auto mb-[12px] w-[70%]'
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        width='100%'
      >
        {secondaryActions.map((action) => (
          <AuthorizationTooltip
            isAuthorized={action.isAuthorized}
            key={action.title}
          >
            <div>
              <Button
                disabled={action.disabled?.()}
                onClick={() => action.execute(entity)}
                variant={'text'}
              >
                {action.icon && (
                  <i
                    className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
                  />
                )}
                {action.title ?? action.tooltip}
              </Button>
            </div>
          </AuthorizationTooltip>
        ))}
        {!!secondaryActions.length && !!primaryActions.length && (
          <Divider
            orientation='vertical'
            flexItem
            variant='middle'
            className='mx-[14px] my-auto h-[24px]'
          />
        )}
        {primaryActions.map((action) => (
          <AuthorizationTooltip
            isAuthorized={action.isAuthorized}
            key={action.title}
          >
            <div>
              <Button
                disabled={action.disabled?.()}
                onClick={() => action.execute(entity)}
                variant={'outlined'}
              >
                {action.icon && (
                  <i
                    className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
                  />
                )}
                {action.title ?? action.tooltip}
              </Button>
            </div>
          </AuthorizationTooltip>
        ))}
      </Stack>
    </Fragment>
  );
}
