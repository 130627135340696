import { Tooltip } from '@mui/material';
import type { Role } from '@repo/api-gw-sdk';

import { RolePermissions } from './rolePermissions';

import { Tag } from '../tag';

export const RoleTag = ({ role }: { role: Role }) => {
  return (
    <Tooltip
      disableInteractive={false}
      PopperProps={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['left', 'right', 'top', 'bottom'],
            },
          },
        ],
      }}
      title={
        <RolePermissions
          role={role}
          hideUnauthorizedPermissions
          hidePermissionGroups
        />
      }
      slotProps={{
        tooltip: {
          sx: {
            padding: '24px',
            maxWidth: '1200px',

            '*': { color: 'currentColor !important' },

            hr: {
              borderColor: 'rgba(255, 255, 255, 0.05)',
            },
          },
        },
      }}
    >
      <Tag variant='filled' content={role.name} />
    </Tooltip>
  );
};
