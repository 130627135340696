import { Divider, Stack, Typography } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import { resourceId } from '@/components/queryBuilder/properties';
import { Icon } from '@/components/shared/icon';
import { Link } from '@/components/shared/link';
import { useRoles } from '@/contexts/useRoles';
import { createCombineCondition, StringOperator } from '@/types/advanceFilter';

const JobsLink = ({
  type,
  resource,
}: {
  type: 'backup' | 'restore';
  resource: InventoryResource;
}) => {
  const { isAuthorized } = useRoles();

  return (
    <Link
      prefetch={false}
      disabled={!isAuthorized('read:jobs')}
      href={`/jobs/${type}?query=${toJsonText(
        createCombineCondition([
          {
            type: 'String',
            operator: StringOperator.Contains,
            value: [resource.providerResourceId],
            property: resourceId.name,
          },
        ])
      )}`}
    >
      <Stack
        direction='row'
        alignItems='center'
        className='font-semibold'
        gap='8px'
      >
        View
        <Icon iconClass='material-symbols-arrow-right-alt' />
      </Stack>
    </Link>
  );
};

export const JobsSection = ({ resource }: { resource: InventoryResource }) => {
  return (
    <Stack className='w-full' gap='20px'>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className='px-[40px]'
      >
        <Typography>Backup jobs</Typography>
        <JobsLink resource={resource} type='backup' />
      </Stack>
      <Divider />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className='px-[40px]'
      >
        <Typography>Restore jobs</Typography>
        <JobsLink resource={resource} type='restore' />
      </Stack>
    </Stack>
  );
};
