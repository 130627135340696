export interface PermissionDescriptor {
  scopes: string[];
  category: string;
  group?: string;
  description?: string;
  isConditional?: boolean;
  conditionalScopesOverrides?: string[];
}

export type NamedPermissionDescriptor = PermissionDescriptor & {
  name: string;
};

export const permissions: Record<string, PermissionDescriptor> = {
  'View global settings': {
    scopes: ['read:account_users', 'read:idp_configs'],
    category: 'Account',
    description: `Can view the organization's settings, including projects, connected source accounts, and roles and access.`,
  },
  'Manage user access': {
    scopes: ['admin:account_users'],
    category: 'Account',
    description: `Can invite and remove users, assign roles, and grant access to projects.`,
  },
  'Manage SAML group access': {
    scopes: ['admin:idp_configs'],
    category: 'Account',
    description: `Can create and edit SAML configurations, and assign and remove roles to SAML groups, and grant group access to projects.`,
  },
  'Manage API credentials': {
    scopes: ['admin:api_credentials'],
    category: 'Account',
    description: `Can create, edit, and delete API credentials, their role and the projects they can access.`,
  },
  'Manage custom roles': {
    scopes: ['admin:account_roles'],
    category: 'Account',
    description: 'Can create, edit, and delete custom roles.',
  },
  'Manage source accounts': {
    scopes: ['admin:source_accounts'],
    category: 'Account',
    description: 'Can connect source accounts to your organization.',
  },
  'Manage projects': {
    scopes: ['admin:projects'],
    category: 'Account',
    description: 'Can create and edit projects.',
  },

  'View dashboard': {
    scopes: ['read:dashboard'],
    category: 'Viewing',
    description: `Can view general details in a project's dashboard, including storage statistics and number of control violations.`,
  },
  'View inventory': {
    scopes: [
      'read:inventory',
      'read:policies',
      'read:vaults',
      'read:controls',
      'read:notifications',
    ],
    category: 'Viewing',
    description: `Can view resource details in the inventory.`,
    isConditional: true,
    conditionalScopesOverrides: ['read:inventory'],
  },
  'View jobs': {
    scopes: ['read:jobs'],
    category: 'Viewing',
    description: `Can view details about backup and restore jobs.`,
  },
  'View audit logs': {
    scopes: ['read:audit_logs'],
    category: 'Viewing',
    description:
      'Can view actions taken in a project and who performed each action.',
  },
  'View backup settings': {
    scopes: [
      'read:backup_settings',
      'read:vaults',
      'read:restore_accounts',
      'read:policies',
      'read:controls',
    ],
    category: 'Viewing',
    description: `Can view a project's backup settings, including connected restore accounts, policies, controls, and vaults.`,
  },

  'Search for tables and use database explorer': {
    scopes: ['read:db'],
    category: 'Viewing',
    group: 'Search and explore',
    description: `Can search for tables by name, column name, or database name. Can view database schema details, including table and column metadata.`,
    isConditional: true,
  },
  'Search for files and use file explorer': {
    scopes: ['read:files'],
    category: 'Viewing',
    group: 'Search and explore',
    description:
      'Can search for files in file systems or storage resources. Can traverse the folder structure in a storage resource and see filenames.',
    isConditional: true,
  },

  'Take snapshots on demand': {
    scopes: ['create:jobs'],
    category: 'Operation',
    isConditional: true,
    description: 'Can take a snapshot of a resource on demand.',
  },
  'Restore resources': {
    scopes: [
      'create:restore_resource',
      'read:restore',
      'read:restore_accounts',
    ],
    category: 'Operation',
    isConditional: true,
    conditionalScopesOverrides: ['create:restore_resource'],
    description:
      'Can restore resources to a restore account connected to a project.',
  },
  'Restore files': {
    scopes: ['create:restore_file', 'read:restore', 'read:restore_accounts'],
    category: 'Operation',
    group: 'Restore granular data',
    isConditional: true,
    conditionalScopesOverrides: ['create:restore_file'],
    description:
      'Can restore individual files to a restore account connected to a project.',
  },
  'Query databases': {
    scopes: ['create:db'],
    category: 'Operation',
    group: 'Restore granular data',
    isConditional: true,
    description:
      'Can run SQL queries against database snapshots, restoring query results to a restore account connected to a project.',
  },
  'Download records': {
    scopes: ['create:export_db_csv'],
    category: 'Operation',
    group: 'Restore granular data',
    isConditional: true,
    description: 'Can download database query results.',
  },

  'Override data classes': {
    scopes: ['update:data_classification'],
    category: 'Operation',
    group: 'Override classifications',
    isConditional: true,
    description: `Can set a resource's data classes manually or set it to auto-classification.`,
  },
  'Override environments': {
    scopes: ['update:environment_classification'],
    category: 'Operation',
    group: 'Override classifications',
    isConditional: true,
    description: `Can set a resource's environment manually or set it to auto-classification.`,
  },
  'Override detected apps': {
    scopes: ['update:apps_classification'],
    category: 'Operation',
    group: 'Override classifications',
    isConditional: true,
    description: `Can set a resource's apps manually or set it to auto-classification.`,
  },

  'Mute and unmute violations': {
    scopes: ['update:control_violations'],
    category: 'Project administration',
    isConditional: true,
    description: `Can mute or unmute reporting of control violations.`,
  },
  'Manage backup exclusion': {
    scopes: ['update:exclude_resource'],
    category: 'Project administration',
    isConditional: true,
    description: `Can exclude or cancel exclusion of a resource from being scanned and backed up.`,
  },
  'Manage vaults': {
    scopes: ['admin:vaults'],
    category: 'Project administration',
    group: 'Manage backup settings',
    description: 'Can add and edit vaults.',
  },
  'Manage restore accounts': {
    scopes: ['admin:restore_accounts'],
    category: 'Project administration',
    group: 'Manage backup settings',
    description: 'Can connect restore accounts to a project.',
  },
  'Manage backup policies': {
    scopes: ['admin:policies'],
    category: 'Project administration',
    group: 'Manage backup settings',
    description: 'Can create, edit, or delete backup policies.',
  },
  'Manage controls': {
    scopes: ['admin:controls'],
    category: 'Project administration',
    group: 'Manage backup settings',
    description: 'Can create, edit, or delete backup posture controls.',
  },
  'Manage notification policies': {
    scopes: ['admin:notification_policies'],
    category: 'Project administration',
    group: 'Manage backup settings',
    description: 'Can create, edit, or delete notification policies.',
  },
};
