/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreImageImageDeviceInput } from '../models/RestoreImageImageDeviceInput';

export class RestoreImageImageInput {
  /**
  * The image ID to use for the restore
  */
  'imageId': string;
  /**
  * The image devices to include in the output
  */
  'devices': Array<RestoreImageImageDeviceInput>;
  /**
  * The image root device name
  */
  'rootDeviceName': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "imageId",
      "baseName": "imageId",
      "type": "string",
      "format": ""
    },
    {
      "name": "devices",
      "baseName": "devices",
      "type": "Array<RestoreImageImageDeviceInput>",
      "format": ""
    },
    {
      "name": "rootDeviceName",
      "baseName": "rootDeviceName",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreImageImageInput.attributeTypeMap;
  }

  public constructor() {
  }
}

