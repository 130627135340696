'use client';

import { useSearchParams } from 'next/navigation';
import {
  createContext,
  useReducer,
  useRef,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react';

import {
  createCombineCondition,
  isValueCondition,
  type Condition,
} from '@/types/advanceFilter';

import { deserializeSearchParams } from './serialization';

const normalizeFilter = (filter: Condition | undefined) => {
  if (!filter) {
    return undefined;
  }

  if (isValueCondition(filter)) {
    return createCombineCondition([filter]);
  }

  return filter.conditions.length === 0 ? undefined : filter;
};

export const ViewParametersContext = createContext<
  | {
      params: () => Record<string, unknown>;
      setParams: Dispatch<SetStateAction<Record<string, unknown>>>;
      setInitialValues: (initialValues: Record<string, unknown>) => void;
      setParamsWithoutRender: (newParams: Record<string, unknown>) => void;
    }
  | undefined
>(undefined);

export const ViewParametersProvider = ({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams();
  const deserializedSearchParams = deserializeSearchParams(searchParams);
  const params = useRef<Record<string, unknown>>(deserializedSearchParams);

  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const setParams = (newParams: Record<string, unknown>, render: boolean) => {
    if (newParams?.query) {
      newParams.query = normalizeFilter(newParams.query as Condition);
    }

    if (params.current.query !== newParams.query) {
      newParams.pageIndex = 0;
    }

    params.current = newParams;
    if (render) {
      forceUpdate();
    }
  };

  return (
    <ViewParametersContext.Provider
      value={{
        params: () => params.current,
        setInitialValues: (initialValues) => {
          setParams(initialValues, true);
        },
        setParamsWithoutRender: (newParams) => {
          setParams(newParams, false);
        },
        setParams: (p) => {
          const newParams =
            typeof p === 'function' ? p(params.current || {}) : p;
          setParams(newParams, true);
        },
      }}
    >
      {children}
    </ViewParametersContext.Provider>
  );
};
