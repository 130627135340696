import { Provider, type Account, type VolumeSettings } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';

import { ConvertToEbsSnapshotConfiguration as ConvertToEbsSnapshotConfiguration } from './steps/convertToEbsSnapshotConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Encryption } from '../encryption/encryptionComponent';
import type { RestoreActionType } from '../panels/instance/tabs/vmSnapshotRow';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreVolumeState {
  providerVolumeId: string;
  actionType: RestoreActionType;
  resourceIdForSnapshots: string;
  snapshotId?: string;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  regionName?: string;
  availabilityZone?: string;
  tags?: Record<string, string>;
  initialTags?: Record<string, string>;
  keepOriginalTags?: boolean;
  volumeSettings?: VolumeSettings;
  initialVolumeSettings?: VolumeSettings;
  keepOriginalSettings?: boolean;
  encryption?: Encryption;
}

const restoreVolumeFlow: Step<RestoreVolumeState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'ConvertToEbsSnapshotConfiguration',
      Component: ConvertToEbsSnapshotConfiguration,
    },
  ],
};

export const RestoreVolumeWizard = ({
  instanceId,
  snapshotId,
  providerVolumeId,
  volumeRegion,
  actionType,
}: {
  instanceId: string;
  snapshotId: string;
  providerVolumeId: string;
  volumeRegion: string;
  actionType: RestoreActionType;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;
  const dal = useDAL();
  const { body: snapshotResponse } = dal.inventory.snapshots.get(snapshotId);

  const volumeProperties =
    snapshotResponse?.snapshot.resourceProperties?.volumeProperties?.find(
      (x) => x.volumeName === providerVolumeId
    );

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: instanceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  if (!volumeProperties) {
    return null;
  }

  const getTitle = () => {
    switch (actionType) {
      case 'convert-snapshot':
        return 'Convert to EBS Snapshot';
      case 'convert-image':
        return 'Convert to AMI';
      default:
        return 'Restore Volume';
    }
  };

  return (
    <Wizard<RestoreVolumeState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreVolumeFlow]}
      initialState={{
        providerVolumeId,
        actionType,
        resourceIdForSnapshots: instanceId,
        availabilityZone: volumeProperties.availabilityZone,
        initialVolumeSettings: volumeProperties.volumeSettings,
        initialTags: volumeProperties.tags || undefined,
        regionName: volumeRegion,
        snapshotId: snapshotId,
        supportedCloudProviders: [Provider.Aws],
        keepOriginalTags: true,
        keepOriginalSettings: true,
      }}
      title={getTitle()}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
