/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Query status. If completed, the query results can be retrieved. 
*/
export enum QueryDBStatus {
  QUERY_STATUS_UNSPECIFIED = 'QUERY_STATUS_UNSPECIFIED',
  QUERY_STATUS_IN_PROGRESS = 'QUERY_STATUS_IN_PROGRESS',
  QUERY_STATUS_COMPLETED = 'QUERY_STATUS_COMPLETED',
  QUERY_STATUS_CANCELED = 'QUERY_STATUS_CANCELED',
  QUERY_STATUS_FAILED = 'QUERY_STATUS_FAILED'
}
