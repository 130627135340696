import type { InventoryResource } from '@repo/api-gw-sdk';

import ConfirmationDialog from '@/components/dialogs/confirmationDialog';
import { useDAL } from '@/data/dal';

interface CancelBackupExclusionProps {
  isOpen: boolean;
  entities: InventoryResource[];
  onClose: () => void;
}

export default function CancelBackupExclusionDialog(
  props: CancelBackupExclusionProps
) {
  const { entities, isOpen, onClose } = props;
  const dal = useDAL();

  const cancelBackupExclusions = async () => {
    await Promise.all(
      entities.map((entity) =>
        dal.inventory.resources.cancelResourceBackupExclusion(entity.id)
      )
    );
    onClose();
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title='Cancel backup exclusions'
      description='Eon will manage the selected resources using the applicable backup policies and controls.'
      onCancel={onClose}
      onConfirm={cancelBackupExclusions}
      buttonIconClass='material-symbols-add-circle-outline'
      buttonTitle='Cancel exclusions'
      cancelButtonTitle='Never mind'
    />
  );
}
