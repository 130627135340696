import { Stack } from '@mui/material';
import classnames from 'classnames';
import { forwardRef, type PropsWithChildren } from 'react';

import {
  PanelFooter,
  type PanelFooterProps,
} from '@/components/panels/panelFooter';
import {
  PanelHeader,
  type PanelHeaderProps,
} from '@/components/panels/panelHeader';

export interface PanelWrapperProps {
  testIdPrefix?: string;
  rounded?: boolean;
  header?: PanelHeaderProps;
  footer?: PanelFooterProps;
}

export const getTestId = (testIdPrefix: string | undefined, suffix: string) =>
  testIdPrefix ? `${testIdPrefix}-${suffix}` : suffix;

export const PanelWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PanelWrapperProps>
>((props, ref) => {
  const { testIdPrefix, children, footer, header, rounded } = props;

  return (
    <Stack
      data-testid={getTestId(testIdPrefix, 'root')}
      ref={ref}
      className='h-full'
    >
      {header && <PanelHeader {...header} />}
      <Stack
        className={classnames('overflow-auto h-full', {
          'rounded-b-[16px]': rounded,
        })}
        data-testid={getTestId(testIdPrefix, 'side-panel-body')}
      >
        {children}
      </Stack>
      {footer && <PanelFooter {...footer} />}
    </Stack>
  );
});
