/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { NotificationArguments } from '../models/NotificationArguments';
import { NotificationStatus } from '../models/NotificationStatus';
import { NotificationType } from '../models/NotificationType';

export class Notification {
  /**
  * The id of the vault
  */
  'id': string;
  /**
  * The project ID
  */
  'projectId': string;
  /**
  * The timestamp of the notification
  */
  'timestamp': Date;
  /**
  * The policy ID of the notification
  */
  'policyId': string;
  /**
  * The policy name of the notification
  */
  'policyName': string;
  'type': NotificationType;
  'status': NotificationStatus;
  'arguments'?: NotificationArguments;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "timestamp",
      "baseName": "timestamp",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "policyId",
      "baseName": "policyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "policyName",
      "baseName": "policyName",
      "type": "string",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "NotificationType",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "NotificationStatus",
      "format": ""
    },
    {
      "name": "arguments",
      "baseName": "arguments",
      "type": "NotificationArguments",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Notification.attributeTypeMap;
  }

  public constructor() {
  }
}



