/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotStatusFilters } from '../models/SnapshotStatusFilters';

export class SnapshotFilterConditions {
  'status'?: SnapshotStatusFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "status",
      "baseName": "status",
      "type": "SnapshotStatusFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

