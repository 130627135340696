import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';
import type { SamlIdentityProvider } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { type SamlGroup } from '@/types/groups';

import { PanelWrapper } from '../panels/panelWrapper';
import { ProjectRolesComponent } from '../permissions/projectRolesComponent';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const GroupPanel = ({
  isEditing,
  initialGroup,
  onDelete,
  onSave,
}: {
  isEditing: boolean;
  initialGroup: SamlGroup;
  onSave: (saml: SamlIdentityProvider) => void;
  onDelete?: (saml: SamlIdentityProvider) => void;
}) => {
  const { roles, isAuthorized } = useRoles();
  const accountRoles = roles.filter((x) => !x.isProjectRole);
  const dal = useDAL();
  const { body: samlProvidersBody } = dal.saml.list();
  const [updatedGroup, setUpdatedGroup] = useState({ ...initialGroup });
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (
      initialGroup.groupName !== updatedGroup.groupName ||
      initialGroup.saml.id !== updatedGroup.saml.id
    ) {
      setUpdatedGroup(initialGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGroup]);

  const checkedRoles = Object.keys(updatedGroup.roleToProjectsMappings).filter(
    (x) => updatedGroup.roleToProjectsMappings[x]
  );

  return (
    <PanelWrapper
      header={{
        title: isEditing ? 'Edit Group Mapping' : 'Add a New Group Mapping',
        onClose: close,
      }}
    >
      <CardContent>
        <Box>
          <FormControl size='small' className='w-full'>
            <FormLabel>Group Name</FormLabel>
            <TransactionalTextField
              initValue={updatedGroup.groupName}
              onChange={(value) =>
                setUpdatedGroup({
                  ...updatedGroup,
                  groupName: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>SAML</FormLabel>
            <Select
              size='small'
              disabled={
                isEditing || !samlProvidersBody?.samlIdentityProviders?.length
              }
              value={
                (samlProvidersBody?.samlIdentityProviders &&
                  updatedGroup.saml.id) ||
                ''
              }
              onChange={(event) => {
                const saml = samlProvidersBody?.samlIdentityProviders?.find(
                  (x) => x.id === event.target.value
                );

                if (saml) {
                  setUpdatedGroup({
                    ...updatedGroup,
                    saml,
                  });
                }
              }}
            >
              {(samlProvidersBody?.samlIdentityProviders || []).map((saml) => (
                <MenuItem key={saml.id} value={saml.id}>
                  {saml.providerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className='w-full mt-[24px]'>
          <FormLabel>Roles</FormLabel>
          <ProjectRolesComponent
            roleToProjectsMappings={updatedGroup.roleToProjectsMappings}
            onChange={(roleToProjectsMappings) =>
              setUpdatedGroup({ ...updatedGroup, roleToProjectsMappings })
            }
          />
        </Box>
        {isEditing && (
          <Button
            disabled={!isAuthorized('update:idp_configs')}
            variant='outlined'
            className='mt-[24px] mr-[8px]'
            onClick={() => {
              void dal.saml
                .update(initialGroup.saml.id, {
                  ...initialGroup.saml,
                  groupToRoleMappingList: (
                    updatedGroup.saml.groupToRoleMappingList || []
                  ).filter((x) => x.groupName !== initialGroup.groupName),
                })
                .then((saml) => onDelete?.(saml));
            }}
          >
            Delete Group
          </Button>
        )}
        <Button
          variant='contained'
          className='mt-[24px]'
          disabled={
            !updatedGroup.groupName ||
            checkedRoles.length === 0 ||
            !isAuthorized('update:idp_configs')
          }
          onClick={() => {
            const accountRoleMappings = Object.fromEntries(
              Object.entries(updatedGroup.roleToProjectsMappings).filter(
                (entry) => accountRoles.some((role) => role.id === entry[0])
              )
            );

            const projectRoleMappings = Object.fromEntries(
              Object.entries(updatedGroup.roleToProjectsMappings).filter(
                (entry) => accountRoles.every((role) => role.id !== entry[0])
              )
            );

            const roleToProjectsMappings = Object.keys(accountRoleMappings)
              .length
              ? accountRoleMappings
              : projectRoleMappings;

            updatedGroup.saml.groupToRoleMappingList = [
              ...(updatedGroup.saml.groupToRoleMappingList || []).filter(
                (x) => x.groupName !== initialGroup.groupName
              ),
              ...Object.entries(roleToProjectsMappings).map((x) => ({
                groupName: updatedGroup.groupName,
                roleId: x[0],
                projectIds: x[1].ids,
              })),
            ];

            void dal.saml
              .update(updatedGroup.saml.id, {
                ...updatedGroup.saml,
                groupToRoleMappingList:
                  updatedGroup.saml.groupToRoleMappingList || [],
              })
              .then((s) => onSave(s));
          }}
        >
          Save
        </Button>
      </CardContent>
    </PanelWrapper>
  );
};
