import { Box, Dialog, Typography } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';

import useBackupVaults from '@/data/vaults/useBackupVaults';

import { BackupVaultSelection } from './backupVaultSelection';

interface BackupVaultSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (vault: BackupVault) => void;
  scheduleWindow: string;
  selectedVaults: string[];
}

export function BackupVaultSelectionDialog(
  props: BackupVaultSelectionDialogProps
) {
  const { data: vaults, loading } = useBackupVaults();
  return (
    <Dialog
      onClose={props.onClose}
      hideBackdrop
      open={props.open}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '840px',
          width: '50%',
          minWidth: '600px',
          maxHeight: '50%',
          margin: 0,
        },
      }}
    >
      <Typography margin='40px 40px 20px'>
        {`Choose a vault for your ${props.scheduleWindow}`}
      </Typography>
      <Box margin='0 40px 40px'>
        <BackupVaultSelection
          onSelect={props.onSelect}
          selectedVaults={props.selectedVaults}
          loading={loading}
          vaults={vaults}
        />
      </Box>
    </Dialog>
  );
}
