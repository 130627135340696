export const Panels = {
  InventoryInstance: 'InventoryInstance',
  InventoryMultiInstance: 'InventoryMultiInstance',
  FileSearchInstance: 'FileSearchInstance',
  RestoreVolumeWizard: 'RestoreVolumeWizard',
  RestoreEc2InstanceWizard: 'RestoreEc2InstanceWizard',
  RestoreFileWizard: 'RestoreFileWizard',
  RestoreRDSWizard: 'RestoreRDSWizard',
  RestoreS3Wizard: 'RestoreS3Wizard',
  RestoreMongoAtlasWizard: 'RestoreMongoAtlasWizard',
  RestoreAzureVMWizard: 'RestoreAzureVMWizard',
  RestoreAzureDiskWizard: 'RestoreAzureDiskWizard',
  EditBackupVault: 'EditBackupVault',
  BackupPolicy: 'BackupPolicy',
  CustomRole: 'CustomRole',
  ApiCredentials: 'ApiCredentials',
  NotificationPolicy: 'NotificationPolicy',
  Group: 'Group',
  SAML: 'SAML',
  Project: 'Project',
  Filters: 'Filters',
  FiltersDrillDown: 'FiltersDrillDown',
  Control: 'Control',
  CloudAccount: 'CloudAccount',
  UpsertUser: 'UpsertUser',
  ConvertToAMIWizard: 'ConvertToAMIWizard',
  AwsRestoreAccountConfig: 'AwsRestoreAccountConfig',
  ConvertToMultipleEonSnapshots: 'ConvertToMultipleEonSnapshots',
} as const;

export type Panels = (typeof Panels)[keyof typeof Panels];
