import { Button, Stack, Typography } from '@mui/material';
import type { BackupSchedule } from '@repo/api-gw-sdk';

import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { RenameEntityName } from '@/components/wizard/renameEntityName';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';
import { BackupPolicySchedule } from './BackupPolicySchedule';

export const BackupPolicySettings = (
  props: StepProps<BackupPolicyEditState>
) => {
  const addSchedule = () => {
    const newState = {
      ...props.wizardState,
    };

    newState.schedules = [
      ...(newState.schedules ?? []),
      {
        id: crypto.randomUUID(),
        policyId: '',
        duration: 24 * 60, // 24 hours default
        backupRetention: 30,
        vaultId: '',
        windows: '0 0 * * *',
      },
    ];

    props.setWizardState(newState);
  };

  const updateSchedule = (schedule: BackupSchedule) => {
    const schedules =
      props.wizardState.schedules?.map((s) =>
        s.id === schedule.id ? schedule : s
      ) ?? [];

    props.setWizardState({ ...props.wizardState, schedules });
  };

  const removeSchedule = (schedule: BackupSchedule) => {
    if (props.wizardState.schedules?.length === 1) return;
    props.setWizardState({
      ...props.wizardState,
      schedules: props.wizardState.schedules?.filter(
        (s) => s.id !== schedule.id
      ),
    });
  };

  const duplicateSchedule = (schedule: BackupSchedule) => {
    props.setWizardState({
      ...props.wizardState,
      schedules: [
        ...(props.wizardState.schedules ?? []),
        { ...schedule, id: crypto.randomUUID() },
      ],
    });
  };

  return (
    <>
      <StepContainer
        navigationComponent={
          <RenameEntityName
            label='Backup policy name'
            name={props.wizardState.name}
            onChange={(name) =>
              props.setWizardState((state) => ({ ...state, name }))
            }
          />
        }
        stepperLabels={props.stepperLabels}
        stepperIndex={2}
        onBackClick={props.back}
        canGoNext={() => {
          if (!props.wizardState.schedules?.length) {
            return false;
          }

          for (const schedule of props.wizardState.schedules) {
            if (!schedule.vaultId) {
              return false;
            }
          }

          return true;
        }}
        nextButtonText={props.wizardState.id ? 'Save Changes' : 'Create'}
        onNextClick={() => {
          props.finish();
        }}
      >
        <Stack sx={{ margin: '100px' }}>
          <Typography variant='body1' sx={{ mb: '16px' }}>
            Set your frequency, retention and vault:
          </Typography>
          {props.wizardState.schedules?.map((schedule) => (
            <BackupPolicySchedule
              deleteAllowed={(props.wizardState.schedules?.length ?? 0) > 1}
              schedule={schedule}
              key={schedule.id}
              onUpdate={updateSchedule}
              onDuplicate={duplicateSchedule}
              onDelete={removeSchedule}
            />
          ))}
          <div style={{ marginTop: '16px' }}>
            <Button
              variant='outlined'
              onClick={addSchedule}
              sx={{ borderColor: 'transparent' }}
              data-testid='create-backup-policy-add-backup-schedule-btn'
            >
              <i className='material-symbols-add-rounded mr-[8px]' />
              Add another
            </Button>
          </div>
        </Stack>
      </StepContainer>
    </>
  );
};
