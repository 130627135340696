import { Button } from '@mui/material';

// can remove this component once we move to MUI v6, as there a built in loading prop for the Button component.
export default function LoaderButton() {
  return (
    <Button className='cursor-default pointer-events-none' variant='outlined'>
      <i className='line-md-loading-loop w-[20px] h-[20px]' />
    </Button>
  );
}
