/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupSchedule } from '../models/BackupSchedule';
import { Condition } from '../models/Condition';

export class BackupPolicy {
  /**
  * The ID of the policy
  */
  'id': string;
  /**
  * The name of the policy
  */
  'name': string;
  /**
  * Whether the policy is enabled
  */
  'enabled': boolean;
  /**
  * The user who created the policy
  */
  'userCreated': string;
  /**
  * The date the policy was created
  */
  'dateCreated': Date;
  /**
  * The date the policy was last modified
  */
  'dateModified': Date;
  /**
  * The number of resources attached to the policy
  */
  'attachedResources': number;
  /**
  * Whether the policy is currently processing changes
  */
  'processing'?: boolean;
  'condition'?: Condition;
  'include': Array<string>;
  'exclude': Array<string>;
  'schedules': Array<BackupSchedule>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "enabled",
      "baseName": "enabled",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "userCreated",
      "baseName": "userCreated",
      "type": "string",
      "format": ""
    },
    {
      "name": "dateCreated",
      "baseName": "dateCreated",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "dateModified",
      "baseName": "dateModified",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "attachedResources",
      "baseName": "attachedResources",
      "type": "number",
      "format": ""
    },
    {
      "name": "processing",
      "baseName": "processing",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "condition",
      "baseName": "condition",
      "type": "Condition",
      "format": ""
    },
    {
      "name": "include",
      "baseName": "include",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "exclude",
      "baseName": "exclude",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "schedules",
      "baseName": "schedules",
      "type": "Array<BackupSchedule>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupPolicy.attributeTypeMap;
  }

  public constructor() {
  }
}

