/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuthParams } from '../models/AuthParams';
import { Provider } from '../models/Provider';

export class RestoreAccount {
  /**
  * Eon-assigned restore account ID.
  */
  'id': string;
  'cloudProvider': Provider;
  /**
  * Cloud-provider-assigned account ID.
  */
  'providerAccountId': string;
  'authParams': AuthParams;
  /**
  * Regions Eon can restore data to.
  */
  'regions'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "authParams",
      "baseName": "authParams",
      "type": "AuthParams",
      "format": ""
    },
    {
      "name": "regions",
      "baseName": "regions",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreAccount.attributeTypeMap;
  }

  public constructor() {
  }
}



