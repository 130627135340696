/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Provider } from '../models/Provider';

export class Account {
  /**
  * The ID of the account
  */
  'id': string;
  'cloudProvider': Provider;
  /**
  * The account ID
  */
  'providerAccountId': string;
  /**
  * The role to access the account
  */
  'role': string;
  /**
  * The relevant regions for the account
  */
  'regions'?: Array<string>;
  'tags'?: { [key: string]: string; };
  /**
  * Friendly name for the account
  */
  'name': string;
  /**
  * The tenant ID (Used for Azure accounts)
  */
  'tenantId'?: string;
  /**
  * The application ID (Used for Azure restore accounts)
  */
  'applicationId'?: string;
  /**
  * The principal ID (Used for Azure restore accounts)
  */
  'principalId'?: string;
  /**
  * Whether this account overrides the default network configuration
  */
  'overrideDefaultNetworkConfig'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "role",
      "baseName": "role",
      "type": "string",
      "format": ""
    },
    {
      "name": "regions",
      "baseName": "regions",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": ""
    },
    {
      "name": "applicationId",
      "baseName": "applicationId",
      "type": "string",
      "format": ""
    },
    {
      "name": "principalId",
      "baseName": "principalId",
      "type": "string",
      "format": ""
    },
    {
      "name": "overrideDefaultNetworkConfig",
      "baseName": "overrideDefaultNetworkConfig",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Account.attributeTypeMap;
  }

  public constructor() {
  }
}



