/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Field to sort by.
*/
export enum BackupJobSortField {
  BACKUP_JOB_SORT_ID = 'id',
  BACKUP_JOB_SORT_STATUS = 'status',
  BACKUP_JOB_SORT_START_TIME = 'startTime',
  BACKUP_JOB_SORT_UPDATE_TIME = 'updateTime',
  BACKUP_JOB_SORT_END_TIME = 'endTime',
  BACKUP_JOB_SORT_INITIATOR_NAME = 'isOnDemand',
  BACKUP_JOB_SORT_ERROR_MESSAGE = 'initiatorName',
  ErrorMessage = 'errorMessage',
  BackupPolicyId = 'backupPolicyId'
}
