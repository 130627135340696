import {
  Box,
  Button,
  Checkbox,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import type {
  AzureOnboardingStatus,
  AzureSubscription,
  AzureTenant,
} from '@repo/api-gw-sdk';
import { useRef, useState } from 'react';

import { useDAL } from '@/data/dal';

import type { AccountType } from './useCreateAccount';

interface CreateAzureAccountPanelProps {
  accountType: AccountType;
  onCreated: (success: boolean, sourceAccountId: string) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export default function CreateAzureAccountPanel(
  props: CreateAzureAccountPanelProps
) {
  const dal = useDAL();
  const requestId = useRef<string>(crypto.randomUUID());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedTenantId, setSelectedTenantId] = useState<
    string | undefined
  >();
  const [tenants, setTenants] = useState<AzureTenant[] | undefined>();
  const [subscriptions, setSubscriptions] = useState<
    AzureSubscription[] | null
  >();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    AzureSubscription[]
  >([]);
  const [onboardingStatus, setOnboardingStatus] = useState<
    AzureOnboardingStatus[] | null
  >(null);
  const [state, setState] = useState<'initial' | 'pending' | 'completed'>(
    'initial'
  );

  /**
   * Switch directory, then *pass the new tenant ID* into openConsentDialog
   * so that openConsentDialog is guaranteed to use the updated ID.
   */
  const switchDirectory = (tenantId: string) => {
    setSelectedTenantId(tenantId);
    setSubscriptions(null);
    setSelectedSubscriptions([]);
    setOnboardingStatus(null);
    setErrorMessage(null);

    // Pass the new tenant ID directly
    openConsentDialog(tenantId);
  };

  /**
   * Updated to accept an optional tenantIdParam.
   * If provided, use that; otherwise fall back to the selectedTenantId state.
   */
  const openConsentDialog = (tenantIdParam?: string) => {
    const tid = tenantIdParam ?? selectedTenantId;
    const width = 600,
      height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    props.setLoading(true);
    setErrorMessage(null);
    setSubscriptions(null);
    setSelectedSubscriptions([]);
    setOnboardingStatus(null);

    void dal.azure.getConsentUrl(requestId.current, tid).then((resp) => {
      if (resp.error) {
        props.setLoading(false);
        setErrorMessage(resp.error);
        return;
      }

      const authWindow = window.open(
        resp.url,
        'authWindow',
        `width=${width},height=${height},top=${top},left=${left}`
      );

      // Monitor the popup window
      const interval = setInterval(() => {
        try {
          if (!authWindow) {
            clearInterval(interval);
            props.setLoading(false);
            return;
          }

          // Close if user closes the window
          if (authWindow.closed) {
            clearInterval(interval);
            void dal.azure
              .listSubscriptions(requestId.current)
              .then((resp) => {
                if (resp.subscriptions?.length > 0) {
                  setSelectedTenantId(resp.subscriptions[0].tenantId);
                }
                setSubscriptions(resp.subscriptions);
                setTenants(resp.tenants);
              })
              .catch(() => {
                props.setLoading(false);
                setErrorMessage('Operation failed');
                setSubscriptions(null);
              });
          }
        } catch {
          // Ignore cross-origin errors
        }
      }, 500);
    });
  };

  const onboardSubscriptions = () => {
    setState('pending');
    setSubscriptions(selectedSubscriptions);
    void dal.azure
      .onboardSubscriptions(
        requestId.current,
        props.accountType,
        selectedSubscriptions
      )
      .then(() => {
        let onboardingInProgress = false;

        const checkOnboardingStatus = async () => {
          if (onboardingInProgress) return; // Prevent overlapping calls
          onboardingInProgress = true;

          try {
            const resp = await dal.azure.getOnboardingStatus(requestId.current);
            setOnboardingStatus(resp.subscriptions);

            const allCompleted = resp.subscriptions.every(
              (sub) => sub.status !== 'Accepted' && sub.status !== 'Running'
            );

            if (allCompleted) {
              setState('completed');
            } else {
              setTimeout(() => {
                void checkOnboardingStatus();
              }, 2000); // Retry after 2 seconds
            }
          } catch {
            setState('completed');
            setSubscriptions(null);
            setErrorMessage('Failed to onboard subscriptions');
          } finally {
            onboardingInProgress = false; // Reset the flag after each call
          }
        };

        void checkOnboardingStatus();
      })
      .catch(() => {
        setState('completed');
        setErrorMessage('Failed to onboard subscriptions');
      });
  };

  return (
    <Box className='px-[20px]'>
      {!subscriptions && (
        <>
          <Box className='my-[15px]'>
            Before we proceed,{' '}
            <b>
              please ensure you have sufficient permissions to create and manage
              a service principal and assign roles in your Azure subscriptions
              <Tooltip
                title={
                  <Box>
                    In order to onboard your Azure subscriptions, Eon requires
                    the creation of a service principal and the assignment of
                    specific roles.
                    <br />
                    <br />
                    In a Microsoft Entra ID directory where user setting Users
                    can register applications has been set to No, you must be a
                    member of one of the following Microsoft Entra ID built-in
                    roles (which have the action:
                    microsoft.directory/applications/createAsOwner or
                    microsoft.directory/applications/create):
                    <br />
                    <br />
                    <ul className='list-disc ml-[24px]'>
                      <li>Global Administrator</li>
                      <li>Application Administrator</li>
                      <li>Cloud Application Administrator</li>
                      <li>Application Developer</li>
                    </ul>
                  </Box>
                }
                slotProps={{
                  tooltip: {
                    sx: {
                      maxWidth: '600px',
                      padding: '16px',
                    },
                  },
                }}
              >
                <i className='info-icon material-symbols-info-outline h-[20px] w-[20px] p-[2px] ml-[4px] align-middle cursor-default' />
              </Tooltip>
              .
            </b>
          </Box>
          <Box className='my-[15px]'>
            After you log in, we&rsquo;ll query the Microsoft Graph to retrieve
            the Eon application principal ID, then deploy an ARM template to
            update the required RBAC roles on the selected permissions.
          </Box>
          <Box className='flex flex-col items-center justify-center my-[30px] gap-[8px]'>
            <Button
              variant='contained'
              disabled={props.loading}
              data-testid='azure-login-btn'
              onClick={() => openConsentDialog()}
              sx={{ width: '400px' }}
            >
              {props.loading && (
                <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
              )}
              Open Microsoft Azure{' '}
              <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
            </Button>
          </Box>
        </>
      )}
      {subscriptions && (
        <>
          {state !== 'completed' && (
            <>
              <Box className='my-[15px]'>
                {tenants && tenants?.length > 1 && (
                  <>
                    <Box className='my-[15px]'>Available Directories:</Box>
                    <Box className='my-[15px]'>
                      <ul className='ml-[24px] list-disc'>
                        {tenants?.map((tenant) => (
                          <li key={tenant.id}>
                            <Stack
                              direction='row'
                              alignItems='center'
                              sx={{
                                '& a.hover-vis': {
                                  visibility: 'hidden',
                                },
                                '&:hover a.hover-vis': {
                                  visibility: 'visible',
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight:
                                    selectedTenantId === tenant.id
                                      ? 'bold'
                                      : 'normal',
                                }}
                              >
                                {tenant.displayName}
                              </Typography>
                              {state === 'initial' &&
                                selectedTenantId !== tenant.id && (
                                  <Link
                                    className='hover-vis ml-[8px]'
                                    onClick={() => switchDirectory(tenant.id)}
                                    href='#'
                                    underline='hover'
                                  >
                                    Switch
                                  </Link>
                                )}
                            </Stack>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </>
                )}
                <Box className='my-[15px]'>
                  Choose which subscriptions to connect to your Eon account:
                </Box>
                <Box className='my-[15px]'>
                  {subscriptions.map((sub) => (
                    <Stack
                      direction='row'
                      key={sub.id}
                      alignItems='center'
                      gap='4px'
                    >
                      <Checkbox
                        disabled={state !== 'initial'}
                        checked={selectedSubscriptions.some(
                          (selectedSub) => selectedSub.id === sub.id
                        )}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedSubscriptions([
                              ...selectedSubscriptions,
                              sub,
                            ]);
                          } else {
                            setSelectedSubscriptions(
                              selectedSubscriptions.filter(
                                (selectedSub) => selectedSub.id !== sub.id
                              )
                            );
                          }
                        }}
                      />
                      <Typography>{sub.name}</Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
              <Box className='flex flex-col justify-center items-center my-[30px] gap-[8px]'>
                <Button
                  variant='contained'
                  disabled={
                    state !== 'initial' || selectedSubscriptions.length === 0
                  }
                  onClick={() => onboardSubscriptions()}
                  sx={{ width: '400px' }}
                >
                  {state === 'pending' && (
                    <>
                      <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
                      Connecting your subscriptions...
                    </>
                  )}
                  {state !== 'pending' && <>Connect Selected Subscriptions</>}
                </Button>
              </Box>
            </>
          )}
          {state === 'completed' && (
            <Box className='my-[15px]'>
              <Box className='my-[15px]'>
                <b>Operation completed.</b>
                <Typography>
                  Please check the status for each of your subscriptions:
                </Typography>
              </Box>
              <ul
                className='my-[15px]'
                style={{ listStyle: 'disc', marginLeft: '20px' }}
              >
                {onboardingStatus?.map((sub) => (
                  <li key={sub.subscriptionId}>
                    {
                      subscriptions.find(
                        (s) => s.subscriptionId === sub.subscriptionId
                      )?.name
                    }{' '}
                    - {sub.status}
                  </li>
                ))}
              </ul>
              <Box className='flex flex-col justify-center items-center my-[30px] gap-[8px]'>
                <Button
                  variant='contained'
                  onClick={() =>
                    props.onCreated(
                      onboardingStatus?.every((s) => s.status !== 'Failed') ||
                        false,
                      onboardingStatus?.find((s) => s.status === 'Succeeded')
                        ?.sourceAccountId || ''
                    )
                  }
                  sx={{ width: '400px' }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
      {errorMessage && (
        <Typography variant='body1' color='error' data-testid='error-message'>
          <Stack className='my-[15px]' direction='row' alignItems='center'>
            <i className='material-symbols-error-outline-rounded mr-[8px]'></i>
            {errorMessage}
          </Stack>
        </Typography>
      )}
    </Box>
  );
}
