// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-parameters
export const parseJson = <T = any>(
  value: string | undefined
): T | undefined => {
  try {
    return value ? (JSON.parse(value) as T) : undefined;
  } catch {
    return undefined;
  }
};

export function stringToHash(input: string, max = 10): number {
  let hash = 0;

  if (input.length === 0) return hash;

  for (let i = 0; i < input.length; i++) {
    const c = input.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash = hash & hash;
  }

  return Math.abs(hash) % max;
}

export function trimAny(str: string, chars: string): string {
  let start = 0,
    end = str.length;

  while (start < end && chars.includes(str[start])) ++start;

  while (end > start && chars.includes(str[end - 1])) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

export function decodePageIndex(pageToken: string | undefined): number {
  return +atob(pageToken || '').split(':')[1] || 0;
}

export function encodePageToken(pageIndex: number): string {
  return btoa(`${crypto.randomUUID()}:${pageIndex}`);
}

export type Stringifyable =
  | string
  | number
  | boolean
  | null
  | undefined
  | Stringifyable[]
  | { [key: string]: Stringifyable };
