/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ListSubnetsResponseV2SubnetsInner } from '../models/ListSubnetsResponseV2SubnetsInner';

export class ListSubnetsResponseV2 {
  /**
  * The available zones for the region
  */
  'subnets': Array<ListSubnetsResponseV2SubnetsInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "subnets",
      "baseName": "subnets",
      "type": "Array<ListSubnetsResponseV2SubnetsInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListSubnetsResponseV2.attributeTypeMap;
  }

  public constructor() {
  }
}

