import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';
import type { ResourceAction } from '@/data/inventory/actions';

import { BackupPoliciesTab } from './instance/tabs/backupPoliciesTab';
import { EonSnapshotsTab } from './instance/tabs/eonSnapshotsTab';
import { OverviewTab } from './instance/tabs/overviewTab';
import { PanelWrapper } from './panelWrapper';

import InfoSnackbar from '../infoSnackbar';
import { Loader } from '../layout/loading';

export interface InstancePanelProps {
  entity: InventoryResource;
  onEntityChange?: () => Promise<void>;
  fields: {
    id: string;
    title: (entity: InventoryResource) => string;
    group: string | undefined;
    value: (entity: InventoryResource) => React.ReactNode;
  }[];
  actions: ResourceAction[];
}

const InstancePanelComponent = (props: InstancePanelProps) => {
  const { entity } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    {
      name: 'Overview',
      Component: (props: InstancePanelProps) => <OverviewTab {...props} />,
    },
    ...(entity.backupPolicies?.length
      ? [
          {
            name: 'Backup policies',
            Component: () => (
              <BackupPoliciesTab activePolicies={entity.backupPolicies} />
            ),
          },
        ]
      : []),
    ...(entity.resourceType
      ? [
          {
            name: 'Eon snapshots',
            dataTestId: 'eon-snapshots-tab',
            Component: () => <EonSnapshotsTab entity={entity} />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    // Sometimes switching across resources we get to a resource without the selected tab in that case we go to the first tab
    const navigateToNonExistingTab = selectedIndex >= tabs.length;
    if (navigateToNonExistingTab) {
      setSelectedIndex(0);
    }
  }, [selectedIndex, tabs.length]);

  const selectedTab = tabs[selectedIndex];

  return (
    <Stack direction='column' overflow='hidden' flexGrow='1'>
      {tabs.length > 1 && (
        <Tabs
          onChange={(e, i) => setSelectedIndex(i)}
          value={selectedIndex}
          className='px-[40px] min-h-[50px]'
          sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
        >
          {tabs.map((value) => (
            <Tab
              data-testid={value.dataTestId}
              key={value.name}
              label={value.name}
              className='text-[14px] font-normal'
              disableRipple
            />
          ))}
        </Tabs>
      )}
      {selectedTab?.Component && <selectedTab.Component {...props} />}
    </Stack>
  );
};

export const InstancePanel = ({
  isLoading,
  onClose,
  entity,
  onEntityChange,
  fields,
  actions,
}: {
  isLoading: boolean;
  onClose: () => void;
  entity: InventoryResource | undefined;
  onEntityChange?: () => Promise<void>;
  fields: InstancePanelProps['fields'];
  actions: InstancePanelProps['actions'];
}) => {
  const dal = useDAL();
  const { isAuthorizedResource } = useRoles();
  const { user } = useUser();
  const { isDev } = useEnvironment();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Scan Initiated');
  if (isLoading) {
    return <Loader />;
  }

  if (!entity) {
    onClose();
    return null;
  }

  const Title = (
    <Stack direction='row' alignItems='center'>
      <Typography className='font-semibold'>Resource Details</Typography>

      {(isDev || user?.email?.endsWith('@eon.io')) && (
        <>
          <Tooltip title='Scan Now'>
            <IconButton
              className='ml-auto mr-20px'
              size='small'
              disabled={
                !isAuthorizedResource('create:jobs', entity) || disabled
              }
              onClick={() => {
                setSnackbarMessage('Scan Initiated');
                setSnackbarOpen(true);
                setDisabled(true);
                void dal.inventory.jobs
                  .createScanJob(entity.id)
                  .catch((e) => {
                    setSnackbarMessage('Scan Failed');
                    throw e;
                  })
                  .finally(() => {
                    setDisabled(false);
                  });
              }}
            >
              <i className='material-symbols-avg-pace-rounded' />
            </IconButton>
          </Tooltip>
          <InfoSnackbar
            message={snackbarMessage}
            isOpen={snackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
          />
        </>
      )}
    </Stack>
  );

  return (
    <PanelWrapper
      header={{
        title: Title,
        onClose,

        hideBorder: true,
      }}
      testIdPrefix='instance-panel'
    >
      <InstancePanelComponent
        entity={entity}
        onEntityChange={onEntityChange}
        actions={actions}
        fields={fields}
      />
    </PanelWrapper>
  );
};
