import { Stack } from '@mui/material';
import { SnapshotStatus, type InventoryResource } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { PointsInTimeCalendar } from '@/components/snapshots/pointsInTimeCalendar';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';

import OtherSnapshotsToggle from './otherSnapshotsToggle';
import { RestoreSnapshotDrawer } from './restoreSnapshotDrawer';

import ConvertToEonSnapshotsBar from '../../../bars/convertToEonSnapshotsBar';

export const EonSnapshotsTab = ({ entity }: { entity: InventoryResource }) => {
  const { convertToEonSnapshot } = useFeatureFlags();
  const dal = useDAL();
  const { showPendingSnapshots } = useFeatureFlags();
  const [snapshotDataOpen, setSnapshotDataOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Date | undefined>();
  const { data: vaults } = useBackupVaults();

  const { body, isLoading } = dal.inventory.snapshots.list(
    entity.id,
    'eon',
    0,
    100
  );

  const snapshots = (body?.data || []).filter((x) => {
    if (showPendingSnapshots && x.status === SnapshotStatus.Pending) {
      return true;
    }

    if (
      x.status === SnapshotStatus.Completed &&
      isDateValid(x.pointInTime) &&
      x.vaultId
    ) {
      return vaults.some((v) => v.id === x.vaultId);
    }
  });

  const events = snapshots.map((x) => {
    const vault = vaults.find((v) => v.id === x.vaultId);

    const date = isDateValid(x.pointInTime) ? x.pointInTime : x.createTime;

    const end = new Date(date);
    end.setMinutes(end.getMinutes() + 1);

    return {
      start: date,
      end,
      vaultName: vault?.name,
      indicatorColor: vault?.backgroundColor || null,
      status: x.status,
    };
  });

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      height='100%'
      sx={{ margin: '0 auto' }}
      flexGrow='1'
      overflow='hidden'
    >
      {convertToEonSnapshot && (
        <OtherSnapshotsToggle cloudProvider={entity.cloudProvider} />
      )}
      <Stack flexGrow='1' overflow='hidden'>
        <PointsInTimeCalendar
          isLoading={isLoading}
          onDateRangeChanged={() => {
            setSelected(undefined);
          }}
          events={events}
          onDateSelected={(selected) => {
            if (selected) {
              setSnapshotDataOpen(true);
            }
            setSelected(
              selected ? new Date(selected.toDateString()) : undefined
            );
          }}
          selectedDate={selected}
        />
      </Stack>
      {snapshotDataOpen ? (
        <RestoreSnapshotDrawer
          vaults={vaults}
          snapshots={snapshots}
          selectedDate={selected}
          entity={entity}
          onClose={() => {
            setSelected(undefined);
            setSnapshotDataOpen(false);
          }}
        />
      ) : (
        <ConvertToEonSnapshotsBar
          numOfOtherSnapshots={entity.snapshotStorage.otherSnapshotCount || 0}
          cloudProvider={entity.cloudProvider}
          resourceId={entity.id}
        />
      )}
    </Stack>
  );
};
