import { Box, Stack, Tooltip } from '@mui/material';
import { useEffect, useMemo, useRef, useState, type RefObject } from 'react';

import { Tag } from '../tag';

export const TagsElasticContainer = ({
  tags,
}: {
  tags: Record<string, string>;
}) => {
  if (!Object.keys(tags || {}).length) {
    return null;
  }

  return <InnerTagsElasticContainer tags={tags} />;
};

const InnerTagsElasticContainer = ({
  tags,
}: {
  tags: Record<string, string>;
}) => {
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [shownTagsCount, setShownTagsCount] = useState<number>(0);

  const values = useMemo(
    () => Object.entries(tags).map((entry) => entry.filter((x) => x).join('=')),
    [tags]
  );

  useEffect(() => {
    let observerRefValue = null;
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  useEffect(() => {
    if (width === 0 || !ref.current) {
      return;
    }

    const tagsWithoutPlaceholder = getTagNodes(ref, 0);
    if (!tagsWithoutPlaceholder.length) {
      return;
    }

    let firstTagY = tagsWithoutPlaceholder[0].getBoundingClientRect().y;
    if (
      tagsWithoutPlaceholder.every(
        (x) => x.getBoundingClientRect().y === firstTagY
      )
    ) {
      return setShownTagsCount(tagsWithoutPlaceholder.length);
    }

    const tagsWithPlaceholder = getTagNodes(ref, 1);
    if (!tagsWithPlaceholder.length) {
      return;
    }

    firstTagY = tagsWithPlaceholder[0].getBoundingClientRect().y;
    const tagsOnFirstRow =
      tagsWithPlaceholder.filter(
        (x) => x.getBoundingClientRect().y === firstTagY
      ).length - 1; // need to compensate for the placeholder tag

    setShownTagsCount(Math.max(tagsOnFirstRow, 1));
  }, [ref, width]);

  const hiddenTagsCount = values.length - shownTagsCount;

  return (
    <Box className='relative' ref={ref}>
      <TagsContainerRenderer withPlaceholder={false} values={values} />
      <TagsContainerRenderer withPlaceholder={true} values={values} />
      <Stack
        gap='8px'
        direction='row'
        className='py-[10px] h-[48px] overflow-hidden'
      >
        {values.slice(0, shownTagsCount).map((value) => (
          <Tag variant='outlined' key={value} content={value} />
        ))}
        {hiddenTagsCount > 0 && (
          <Tooltip
            disableInteractive={false}
            slotProps={{
              tooltip: {
                sx: {
                  padding: '24px',
                  backgroundColor: 'var(--mui-palette-background-paper)',
                  boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.10)',
                },
              },
            }}
            title={
              <Stack
                gap='8px'
                flexWrap='wrap'
                direction='row'
                onClick={(e) => e.stopPropagation()}
              >
                {values.map((value) => (
                  <Tag variant='outlined' key={value} content={value} />
                ))}
              </Stack>
            }
          >
            <Tag variant='outlined' content={`+${hiddenTagsCount}`} />
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};

const getTagNodes = (rootRef: RefObject<HTMLDivElement>, index: number) => {
  if (!rootRef.current?.childNodes) {
    return [];
  }

  const tagsContainer = rootRef.current.childNodes.item(index) as HTMLElement;
  if (!tagsContainer?.childNodes.length) {
    return [];
  }

  return Array.from(tagsContainer.childNodes) as HTMLElement[];
};

const TagsContainerRenderer = ({
  values,
  withPlaceholder,
}: {
  withPlaceholder: boolean;
  values: string[];
}) => (
  <Stack
    gap='8px'
    direction='row'
    flexWrap='wrap'
    className='absolute top-0 left-0 right-0 invisible'
  >
    {withPlaceholder && <Tag variant='outlined' content='+10' />}
    {values.map((value) => (
      <Tag variant='outlined' key={value} content={value} />
    ))}
  </Stack>
);
