/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ListAzureSKUs200ResponseSkusInner {
  /**
  * SKU name
  */
  'name': string;
  /**
  * SKU tier
  */
  'tier': string;
  /**
  * SKU capabilities
  */
  'capabilities': { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "tier",
      "baseName": "tier",
      "type": "string",
      "format": ""
    },
    {
      "name": "capabilities",
      "baseName": "capabilities",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAzureSKUs200ResponseSkusInner.attributeTypeMap;
  }

  public constructor() {
  }
}

