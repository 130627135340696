import {
  Box,
  Button,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { TransactionalTextField } from '@/components/shared/transactionalTextField';

import { Tag } from '../tag';

export const TagsSection = ({
  initialTags,
  tags,
  keepOriginalTags,
  setKeepOriginalTags,
  onChange,
}: {
  tags: Record<string, string>;
  initialTags: Record<string, string>;
  keepOriginalTags?: boolean;
  setKeepOriginalTags: (value: boolean) => void;
  onChange: (tags: Record<string, string>) => void;
}) => {
  return (
    <Box className='w-full py-[24px] px-[40px]'>
      <Box className='flex justify-between items-center'>
        <Typography className='font-semibold' variant='body1'>
          Keep original tags
        </Typography>
        <Switch
          size='small'
          checked={keepOriginalTags}
          onChange={() => {
            setKeepOriginalTags(!keepOriginalTags);
          }}
        />
      </Box>
      {keepOriginalTags && (
        <Stack
          className='mt-[20px] w-full'
          gap='8px'
          direction='row'
          flexWrap='wrap'
        >
          {Object.entries(initialTags).map((entry) => (
            <Tag
              variant='outlined'
              key={entry[0]}
              content={entry.filter((x) => x).join('=')}
            />
          ))}
        </Stack>
      )}
      {!keepOriginalTags && (
        <Box className='mt-[20px] w-1/2'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='border-0 pl-0'>Key</TableCell>
                <TableCell className='border-0 pl-0'>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(tags).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell className='border-0 pl-0'>
                    <TransactionalTextField
                      initValue={key}
                      onChange={(newKey) => {
                        if (key !== newKey) {
                          const newState = {
                            ...tags,
                            [newKey]: tags[key],
                          };

                          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                          delete newState[key];
                          onChange(newState);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <TextField
                      tabIndex={2}
                      size='small'
                      value={value}
                      onChange={(event) => {
                        const newState = {
                          ...tags,
                          [key]: event.target.value,
                        };
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                  <TableCell className='border-0 pl-0'>
                    <i
                      className={
                        'material-symbols-close-rounded cursor-pointer table-cell'
                      }
                      onClick={() => {
                        const newState = {
                          ...tags,
                        };

                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete newState[key];
                        onChange(newState);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            className='mt-[20px]'
            size={'small'}
            variant='outlined'
            onClick={() => onChange({ ...tags, '': '' })}
          >
            + Add Tag
          </Button>
        </Box>
      )}
    </Box>
  );
};
