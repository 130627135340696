/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { S3RestoreTargetS3Bucket } from '../models/S3RestoreTargetS3Bucket';

export class S3RestoreTarget {
  's3Bucket': S3RestoreTargetS3Bucket;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "s3Bucket",
      "baseName": "s3Bucket",
      "type": "S3RestoreTargetS3Bucket",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3RestoreTarget.attributeTypeMap;
  }

  public constructor() {
  }
}

