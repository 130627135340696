import {
  Button,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import type { SamlIdentityProvider } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';

import { PanelWrapper } from '../panels/panelWrapper';

export const SamlPanel = ({
  saml,
  onSave,
  onDelete,
}: {
  saml: SamlIdentityProvider;
  onSave: (saml: SamlIdentityProvider) => void;
  onDelete?: (id: string) => void;
}) => {
  const dal = useDAL();
  const { isAuthorized } = useRoles();
  const [updatedSaml, setUpdatedSaml] = useState(saml);
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (saml.id !== updatedSaml.id) {
      setUpdatedSaml(saml);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saml]);

  return (
    <PanelWrapper
      header={{
        title: updatedSaml.id ? 'Edit SAML' : 'Create SAML',
        onClose: close,
      }}
    >
      <CardContent className='w-1/2'>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Provider name</FormLabel>
          <TextField
            size='small'
            value={updatedSaml.providerName}
            onChange={(event) =>
              setUpdatedSaml({
                ...updatedSaml,
                providerName: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>SSO URL</FormLabel>
          <TextField
            size='small'
            value={updatedSaml.ssoUrl}
            onChange={(event) =>
              setUpdatedSaml({
                ...updatedSaml,
                ssoUrl: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Issuer URL</FormLabel>
          <TextField
            size='small'
            value={updatedSaml.issuerUrl}
            onChange={(event) =>
              setUpdatedSaml({
                ...updatedSaml,
                issuerUrl: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Logout URL</FormLabel>
          <TextField
            size='small'
            value={updatedSaml.logoutUrl}
            onChange={(event) =>
              setUpdatedSaml({
                ...updatedSaml,
                logoutUrl: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Certificate</FormLabel>
          <TextField
            multiline
            sx={{ textarea: { padding: 0 } }}
            size='small'
            value={updatedSaml.certificate}
            onChange={(event) =>
              setUpdatedSaml({
                ...updatedSaml,
                certificate: event.target.value,
              })
            }
          />
        </FormControl>
        <Button
          disabled={!isAuthorized('update:idp_configs')}
          variant='outlined'
          className='mt-[24px]'
          onClick={() => {
            if (updatedSaml.id) {
              void dal.saml
                .update(updatedSaml.id, {
                  ...updatedSaml,
                  groupToRoleMappingList:
                    updatedSaml.groupToRoleMappingList || [],
                })
                .then((newSaml) => onSave(newSaml));
            } else {
              void dal.saml
                .create({
                  ...updatedSaml,
                  groupToRoleMappingList:
                    updatedSaml.groupToRoleMappingList || [],
                })
                .then((newSaml) => onSave(newSaml));
            }
          }}
        >
          Save
        </Button>
        {updatedSaml.id && isAuthorized('delete:idp_configs') && (
          <Button
            variant='outlined'
            className='mt-[24px] ml-[8px]'
            onClick={() => {
              void dal.saml
                .delete(updatedSaml.id)
                .then(() => onDelete?.(updatedSaml.id));
            }}
          >
            Delete
          </Button>
        )}
      </CardContent>
    </PanelWrapper>
  );
};
