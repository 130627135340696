/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Environment } from '../models/Environment';
import { FileSnapshot } from '../models/FileSnapshot';
import { Provider } from '../models/Provider';
import { ResourceType } from '../models/ResourceType';

export class FileExplorerResult {
  /**
  * The resource ID, containing the file
  */
  'resourceId': string;
  /**
  * The instance ID of the resource
  */
  'instanceId'?: string;
  /**
  * The name of the resource
  */
  'resourceName': string;
  'resourceType'?: ResourceType;
  /**
  * The resource group of the resource
  */
  'resourceGroup': string;
  /**
  * The path of the file
  */
  'path': string;
  /**
  * The type of the path (file - \'f\' or directory - \'d\' or symlink - \'l\')
  */
  'type': string;
  'environment'?: Environment;
  /**
  * The depth of the path
  */
  'depth': number;
  'cloudProvider'?: Provider;
  /**
  * The apps associated with the resource
  */
  'apps'?: Array<string>;
  /**
  * The tags of the volume
  */
  'tags'?: { [key: string]: string; };
  /**
  * The date the path snapshots expires
  */
  'expiresOn': Date;
  /**
  * The snapshots of the file
  */
  'snapshots': Array<FileSnapshot>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "instanceId",
      "baseName": "instanceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "environment",
      "baseName": "environment",
      "type": "Environment",
      "format": ""
    },
    {
      "name": "depth",
      "baseName": "depth",
      "type": "number",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "apps",
      "baseName": "apps",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "expiresOn",
      "baseName": "expiresOn",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "snapshots",
      "baseName": "snapshots",
      "type": "Array<FileSnapshot>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return FileExplorerResult.attributeTypeMap;
  }

  public constructor() {
  }
}



