import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { AzureResourceGroup } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

export const ResourceGroupSelect = ({
  accountId,
  resourceGroup,
  onChange,
}: {
  accountId?: string;
  resourceGroup?: string;
  onChange: (resourceGroup: AzureResourceGroup | undefined) => void;
}) => {
  if (!accountId) {
    return <EmptySelect title='Resource Group*' selectClass='h-[58px]' />;
  }

  return (
    <InnerResourceGroupSelect
      accountId={accountId}
      resourceGroup={resourceGroup}
      onChange={onChange}
    />
  );
};

const InnerResourceGroupSelect = ({
  accountId,
  resourceGroup,
  onChange,
}: {
  accountId: string;
  resourceGroup?: string;
  onChange: (resourceGroup: AzureResourceGroup | undefined) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.azureRestoreConfig.listResourceGroups(accountId);

  if (!body?.resourceGroups.length) {
    return <EmptySelect title='Resource Group*' selectClass='h-[58px]' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Resource Group*</FormLabel>
      <Select
        className='h-[58px]'
        size='small'
        value={resourceGroup || ''}
        onChange={(event) =>
          onChange(
            body.resourceGroups.find((x) => x.name === event.target.value)
          )
        }
      >
        {body.resourceGroups.map((group) => (
          <MenuItem key={group.name + group.location} value={group.name}>
            <Stack direction={'column'} className='py-[4px]'>
              <Typography>{group.name}</Typography>
              <Typography variant='body2' className='mt-[4px]'>
                {group.location}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
