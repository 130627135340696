import { FormControl, FormLabel, Select } from '@mui/material';

export const EmptySelect = ({
  title,
  selectClass,
}: {
  title: string;
  selectClass?: string;
}) => {
  return (
    <FormControl size='small' className='flex-1'>
      {title && <FormLabel>{title}</FormLabel>}
      <Select size='small' disabled value='' className={selectClass} />
    </FormControl>
  );
};
