import { Button, type ButtonProps } from '@mui/material';
import { forwardRef } from 'react';

interface ButtonWithIconProps extends ButtonProps {
  iconClass?: string;
  iconPosition?: 'left' | 'right';
  text: string;
}

const ButtonWithIcon = forwardRef<HTMLButtonElement, ButtonWithIconProps>(
  (props, ref) => {
    const { iconClass, text, iconPosition = 'left', ...rest } = props;

    return (
      <Button ref={ref} size='small' className='py-[6px]' {...rest}>
        {iconPosition === 'left' ? (
          <>
            {iconClass && (
              <i
                className={`${iconClass} text-lg ml-[2px] mr-[8px] align-middle`}
              />
            )}
            {text}
          </>
        ) : (
          <>
            {text}
            {iconClass && (
              <i
                className={`${iconClass} text-lg mr-[2px] ml-[8px] align-middle`}
              />
            )}
          </>
        )}
      </Button>
    );
  }
);

export default ButtonWithIcon;
