import { Box, Tooltip } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';

export const OverflowTooltip = ({
  text = '',
  renderContent,
}: {
  text: string | undefined;
  renderContent?: (
    text: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: React.RefObject<any>
  ) => React.ReactElement;
}) => {
  const textElementRef = useRef<HTMLElement>();
  const [isOverflowed, setIsOverflow] = useState(false);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      );
    }
  }, []);

  return (
    <Tooltip title={text} disableHoverListener={!isOverflowed}>
      {renderContent ? (
        renderContent(text, textElementRef)
      ) : (
        <Box ref={textElementRef} className='truncate'>
          {text}
        </Box>
      )}
    </Tooltip>
  );
};
