import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  CardContent,
  Divider,
} from '@mui/material';

import { TagsSection } from '@/components/tags/tagsSection';
import { DiskSettingsSection } from '@/components/volumes/diskSettings';
import type { StepProps } from '@/components/wizard/StepProps';

import type {
  AzureDiskConfigState,
  RestoreAzureVMState,
} from '../restoreAzureVMWizard';

export const SingleAzureDiskConfig = ({
  accountID,
  location,
  diskId,
  initialSettings,
  diskSettings,
  setWizardState,
  showTags = true,
}: {
  accountID: string;
  location: string;
  diskId: string;
  initialSettings: AzureDiskConfigState;
  diskSettings: AzureDiskConfigState;
  setWizardState: StepProps<RestoreAzureVMState>['setWizardState'];
  showTags?: boolean;
}) => {
  const setVolumesConfigState = <T extends keyof AzureDiskConfigState>(
    field: T,
    value: AzureDiskConfigState[T]
  ) => {
    setWizardState((state) => {
      const disksState = state.disksState.map((d) =>
        d.diskSettings.diskId === diskId ? { ...d, [field]: value } : d
      );
      return { ...state, disksState };
    });
  };

  return (
    <Accordion defaultExpanded={false} className='my-[2px]'>
      <AccordionSummary>
        <Typography variant='body1'>
          {
            (diskSettings.keepOriginalSettings ? initialSettings : diskSettings)
              .diskSettings.diskName
          }
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='p-0'>
        <CardContent className='p-0'>
          <DiskSettingsSection
            initialSettings={initialSettings.diskSettings}
            settings={diskSettings.diskSettings}
            keepOriginalSettings={diskSettings.keepOriginalSettings}
            accountId={accountID}
            location={location}
            onChange={(diskSettings) =>
              setVolumesConfigState('diskSettings', diskSettings)
            }
            setKeepOriginalSettings={(value: boolean) =>
              setVolumesConfigState('keepOriginalSettings', value)
            }
          />
        </CardContent>
        {showTags && (
          <>
            <Divider />
            <CardContent className='p-0'>
              <TagsSection
                initialTags={initialSettings.tags || {}}
                tags={diskSettings.tags || initialSettings.tags || {}}
                keepOriginalTags={diskSettings.keepOriginalTags}
                setKeepOriginalTags={(keepOriginalTags: boolean) =>
                  setVolumesConfigState('keepOriginalTags', keepOriginalTags)
                }
                onChange={(tags) => setVolumesConfigState('tags', tags)}
              />
            </CardContent>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
