import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const Tag = forwardRef<
  HTMLSpanElement,
  {
    content: string;
    variant: 'outlined' | 'filled';
    color?: string;
    frameColor?: string;
  }
>(
  (
    {
      content,
      variant,
      color = 'var(--mui-palette-text-primary)',
      frameColor = 'var(--mui-palette-divider)',
      ...rest
    },
    ref
  ) => (
    <Box
      {...rest}
      ref={ref}
      color={color}
      fontSize='12px'
      component='span'
      className='px-[8px] py-[4px] rounded-[4px] flex-inline'
      sx={{
        backgroundColor: variant === 'outlined' ? 'transparent' : frameColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: variant === 'outlined' ? frameColor : 'transparent',
      }}
    >
      {content}
    </Box>
  )
);
