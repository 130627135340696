const siUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const binaryUnits = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export function fileSizeFormatter(
  bytes: number | undefined,
  dp = 1,
  si = true
) {
  const thresh = si ? 1000 : 1024;
  const units = si ? siUnits : binaryUnits;

  if (typeof bytes !== 'number') {
    bytes = 0;
  }

  if (Math.abs(bytes) < thresh) {
    return `${Math.round(Math.abs(bytes))} B`;
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

export const convertToBytes = (size: number, unit: string) => {
  const siIndex = siUnits.indexOf(unit);
  const binaryIndex = binaryUnits.indexOf(unit);

  const { thresh, index } =
    siIndex !== -1
      ? { thresh: 1000, index: siIndex }
      : binaryIndex !== -1
        ? { thresh: 1024, index: binaryIndex }
        : {};

  if (index === undefined || thresh === undefined) {
    throw new Error(`Invalid unit: ${unit}`);
  }

  return size * thresh ** (index + 1);
};

export const convertFromBytes = (size: number, unit: string) => {
  const siIndex = siUnits.indexOf(unit);
  const binaryIndex = binaryUnits.indexOf(unit);

  const { thresh, index } =
    siIndex !== -1
      ? { thresh: 1000, index: siIndex }
      : binaryIndex !== -1
        ? { thresh: 1024, index: binaryIndex }
        : {};

  if (index === undefined || thresh === undefined) {
    throw new Error(`Invalid unit: ${unit}`);
  }

  for (let i = 0; i <= index; i++) {
    size /= thresh;
  }

  return size;
};
