import { useMemo } from 'react';

import { useUser } from '@/contexts/useUser';
import type { FilterProperty } from '@/types/advanceFilter';

import { createColumns, createFields } from '../queryBuilder/propertiesUtils';

export const useColumns = <T>(properties: FilterProperty[]) => {
  const { sourceAccounts } = useUser();

  return useMemo(() => {
    const providers = new Set(
      (sourceAccounts || []).map((x) => x.cloudProvider)
    );
    const relevantColumns = properties.filter((x) => {
      if (!x.propertyPredicate) {
        return true;
      }

      return x.propertyPredicate(providers);
    });

    return createColumns<T>(relevantColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useFields = (properties: FilterProperty[]) => {
  return useMemo(
    () => createFields(properties),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
