/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class BackupPolicyAppliesNotificationArguments {
  /**
  * The id of the policy
  */
  'id'?: string;
  /**
  * The resource IDs of the backup policy
  */
  'resourceIds'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceIds",
      "baseName": "resourceIds",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupPolicyAppliesNotificationArguments.attributeTypeMap;
  }

  public constructor() {
  }
}

