import { Tooltip } from '@mui/material';
import { useEffect } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { FiltersPanelProps } from '../filters/filtersPanel';
import { Panels } from '../panels/panels';
import { Icon } from '../shared/icon';

interface FilterButtonProps extends FiltersPanelProps {
  openFilters?: boolean;
}

export default function FilterButton(props: FilterButtonProps) {
  const { columns, openFilters } = props;
  const { leftPanel } = useWorkspace();
  const { setIsOpen, isOpen, setComponent, Component } = leftPanel;

  const setFiltersPanel = () => {
    setComponent({
      panel: Panels.Filters,
      props: {
        columns: columns,
      },
    });
  };

  useEffect(() => {
    if (openFilters) {
      setIsOpen(true);
    }

    if (!Component || Component.panel === 'Filters') {
      setFiltersPanel();
    }

    if (Component?.panel === 'FiltersDrillDown') {
      setComponent({
        panel: Component.panel,
        props: {
          ...Component.props,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip title={'Filter resources'} className='mr-[8px]'>
      <Icon
        data-testid='filter-resources-button'
        iconClass='material-symbols-filter-alt-outline'
        isActive={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
          setFiltersPanel();
        }}
      />
    </Tooltip>
  );
}
