import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Provider } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useUser } from '@/contexts/useUser';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';
import type { BackupVault } from '@/data/vaults/backupVault';
import type BackupVaultPreferences from '@/data/vaults/backupVaultPrefrences';

import { PanelWrapper } from '../panels/panelWrapper';

const ColorRadioButton = styled(Radio)<{ value: string }>((props) => ({
  display: 'inline',
  width: '24px',
  height: '24px',
  backgroundColor: props.value,
  // borderRadius: '6px',
  margin: '6px',
  '&:first-child': {
    marginLeft: 0,
  },
  '&:hover': {
    backgroundColor: props.value,
    opacity: 0.7,
  },
  '& svg': { display: 'none' },
  '&.Mui-checked': {
    border: '2px solid gray',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
}));

const LargeCheckBox = styled(Checkbox)({
  margin: '-3px',
  marginRight: '5px',
  padding: 0,
  '& svg': {
    fontSize: '28px',
  },
  '&.Mui-disabled svg': {
    opacity: 0.6,
  },
});

const SupportedAWSRegions = [
  { Name: 'US East (N. Virginia)', Id: 'us-east-1' },
  { Name: 'US East (Ohio)', Id: 'us-east-2' },
  { Name: 'US West (N. California)', Id: 'us-west-1' },
  { Name: 'US West (Oregon)', Id: 'us-west-2' },
  { Name: 'Canada (Central)', Id: 'ca-central-1' },
  { Name: 'Europe (Ireland)', Id: 'eu-west-1' },
  { Name: 'Europe (London)', Id: 'eu-west-2' },
  { Name: 'Europe (Frankfurt)', Id: 'eu-central-1' },
];

const SupportedAzureRegions = [
  { Name: 'East US', Id: 'eastus' },
  { Name: 'East US 2', Id: 'eastus2' },
  { Name: 'Central US', Id: 'centralus' },
  { Name: 'North Central US', Id: 'northcentralus' },
  { Name: 'South Central US', Id: 'southcentralus' },
  { Name: 'West US', Id: 'westus' },
  { Name: 'West US 2', Id: 'westus2' },
  { Name: 'West US 3', Id: 'westus3' },
  { Name: 'West Central US', Id: 'westcentralus' },
  { Name: 'Canada Central', Id: 'canadacentral' },
  { Name: 'Canada East', Id: 'canadaeast' },
  { Name: 'West Europe', Id: 'westeurope' },
  { Name: 'North Europe', Id: 'northeurope' },
  { Name: 'Australia Central', Id: 'australiacentral' },
  { Name: 'Australia East', Id: 'australiaeast' },
  { Name: 'Australia Southeast', Id: 'australiasoutheast' },
  { Name: 'France Central', Id: 'francecentral' },
  { Name: 'Germany West Central', Id: 'germanywestcentral' },
  { Name: 'Japan East', Id: 'japaneast' },
  { Name: 'Japan West', Id: 'japanwest' },
  { Name: 'Sweden Central', Id: 'swedencentral' },
  { Name: 'UK South', Id: 'uksouth' },
  { Name: 'UK West', Id: 'ukwest' },
  { Name: 'Central India', Id: 'centralindia' },
  { Name: 'West India', Id: 'westindia' },
  { Name: 'South India', Id: 'southindia' },
];

interface EditBackupVaultPanelProps {
  entity: BackupVault;
  onChange: () => void;
}

enum EditMode {
  Create,
  Edit,
}

export const VaultColors = [
  '#FEB2F1',
  '#8AE3FF',
  '#BDBEF7',
  '#E3B2A1',
  '#D1B9C0',
  '#A1FFD8',
];

export function EditBackupVaultPanel(props: EditBackupVaultPanelProps) {
  const [data, setData] = useState(props.entity);
  const [error, setError] = useState<string | undefined>();
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);
  const dal = useDAL();
  const [loading, setLoading] = useState(false);
  const { isDev, isDemo } = useEnvironment();
  const mode = props.entity.id ? EditMode.Edit : EditMode.Create;
  const { body: pref, isLoading: prefLoading } = dal.preferences.getAccountPref(
    PreferencesKey.BackupVault
  );
  const { user } = useUser();
  const { body: accounts, isLoading: loadingAccounts } =
    dal.vaults.getAccounts();

  function validate(): boolean {
    if (!data.name || data.name.length < 5) {
      setError('Name must be at least 5 characters long');
      return false;
    }

    const regexp = /^[a-zA-Z0-9-_ ]+$/;
    if (data.name.search(regexp) === -1) {
      setError('Name can only contain letters, numbers, and hyphens');
      return false;
    }

    if (mode === EditMode.Create) {
      if (!data.cloudProvider) {
        setError('Please select a cloud provider');
        return false;
      }

      if (!data.cloudAccountId) {
        setError('Please select an account');
        return false;
      }

      if (!data.region) {
        setError('Please select a region');
        return false;
      }
    }

    return true;
  }

  function clearValidationError() {
    setError(undefined);
  }

  async function save() {
    if (!validate()) return;

    setLoading(true);
    if (data.id) {
      await dal.vaults.update(data);
    } else {
      const res = await dal.vaults.create(data);
      data.id = res?.id || '';
    }

    if (data.backgroundColor && !prefLoading) {
      if (!pref?.value) {
        void dal.preferences.updateAccountPref(PreferencesKey.BackupVault, {
          colors: [{ id: data.id, color: data.backgroundColor }],
        } satisfies BackupVaultPreferences);
      } else {
        const vpref = pref.value?.colors.find((p) => p.id === data.id);
        if (vpref) {
          vpref.color = data.backgroundColor;
        } else {
          pref.value?.colors.push({
            id: data.id,
            color: data.backgroundColor,
          });
        }

        void dal.preferences.updateAccountPref(
          PreferencesKey.BackupVault,
          pref.value
        );
      }
    }

    setIsOpen(false);
    props.onChange?.();
  }

  return (
    <>
      <PanelWrapper
        header={{
          onClose: close,
          title: props.entity.id
            ? 'Edit Backup Vault'
            : 'Create New Backup Vault',
        }}
      >
        <Box className='h-full flex flex-col p-[20px]'>
          <Typography variant='body1'>
            Backup vaults sets the target for your backups. Please specify the
            following details to create a new backup vault.
          </Typography>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{'Name'}</FormLabel>
            <TextField
              disabled={loading}
              size='small'
              defaultValue={data.name}
              inputProps={{ 'data-testid': 'vault-name' }}
              onChange={(event) => {
                clearValidationError();
                setData({
                  ...data,
                  name: event.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{'Cloud Provider'}</FormLabel>
            <Select
              disabled={loading || mode === EditMode.Edit}
              value={data.cloudProvider}
              data-testid='vault-cloud-provider'
              onChange={(event) => {
                clearValidationError();
                setData({
                  ...data,
                  cloudProvider: event.target.value as Provider,
                  cloudAccountId: '',
                });
              }}
            >
              <MenuItem
                value={Provider.Aws}
                data-testid='vault-cloud-provider-AWS'
              >
                AWS
              </MenuItem>
              <MenuItem
                disabled
                value={Provider.Gcp}
                data-testid='vault-cloud-provider-GCP'
              >
                GCP
              </MenuItem>
              <MenuItem
                value={Provider.Azure}
                data-testid='vault-cloud-provider-Azure'
                disabled={!user?.eonAccount?.azureSubscriptionId}
              >
                Azure
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{'Account'}</FormLabel>
            <Select
              disabled={loading || loadingAccounts || mode === EditMode.Edit}
              value={data.cloudAccountId}
              data-testid='vault-cloud-account'
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, cloudAccountId: event.target.value });
              }}
            >
              {accounts?.accounts
                ?.filter((a) => a.cloudProvider === data.cloudProvider)
                .map((account, i) => (
                  <MenuItem
                    value={account.id}
                    key={i}
                    data-testid={`vault-cloud-account-${i}`}
                  >
                    {account.name} ({account.id})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{'Region'}</FormLabel>
            <Select
              disabled={
                loading ||
                loadingAccounts ||
                mode === EditMode.Edit ||
                data?.cloudAccountId === ''
              }
              data-testid='vault-region'
              value={data.region}
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, region: event.target.value });
              }}
            >
              {(data.cloudProvider === Provider.Aws
                ? SupportedAWSRegions
                : SupportedAzureRegions
              ).map((region, i) => (
                <MenuItem
                  value={region.Id}
                  key={i}
                  data-testid={`vault-region-${region.Id}`}
                >
                  {region.Name} - {region.Id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(isDev || isDemo) && data.cloudProvider === Provider.Aws && (
            <FormControl
              size='small'
              className='w-full mt-[24px] flex flex-row items-center'
            >
              <LargeCheckBox
                disabled={true}
                // disabled={loading || mode === EditMode.Edit}
                checked={data.objectLock}
                inputProps={{ ['data-testid' as string]: 'vault-object-lock' }}
                onChange={(event) => {
                  clearValidationError();
                  setData({ ...data, objectLock: event.target.checked });
                }}
              />
              <FormLabel className='mb-0'>{'Object Lock'}</FormLabel>
            </FormControl>
          )}
          <FormControl
            size='small'
            className='w-full mt-[24px] flex flex-row items-center justify-between'
          >
            <FormLabel className='mb-0'>
              {'Preferred Background Color'}
            </FormLabel>
            <RadioGroup
              className='flex-row'
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={data.backgroundColor || VaultColors[0]}
              onChange={(event) => {
                setData({ ...data, backgroundColor: event.target.value });
              }}
            >
              {VaultColors.map((color) => (
                <ColorRadioButton
                  data-testid={`vault-color-btn`}
                  key={color}
                  value={color}
                  inputProps={{
                    ['data-testid' as string]: `vault-color-${color}`,
                  }}
                  disabled={loading}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {error && (
            <div style={{ height: '25px', marginTop: '35px' }}>
              <Typography color='error' variant='body2'>
                <i className='clarity-error-line text-[22px] text-error align-middle mb-[2px]' />
                {error}
              </Typography>
            </div>
          )}
        </Box>
        <Box className='flex justify-end px-[20px] py-[16px]'>
          <Button
            disabled={loading}
            size='small'
            variant='outlined'
            className='mr-[12px]'
            onClick={() => setIsOpen(false)}
          >
            {'Cancel'}
          </Button>
          <Button
            disabled={loading}
            size='small'
            variant='contained'
            data-testid='save-vault'
            onClick={() => {
              void save();
            }}
          >
            {props.entity.id ? 'Save Changes' : 'Create'}
          </Button>
        </Box>
      </PanelWrapper>
    </>
  );
}
