/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotFilterConditions } from '../models/SnapshotFilterConditions';

export class ListInventorySnapshotsRequest {
  'filters'?: SnapshotFilterConditions;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "filters",
      "baseName": "filters",
      "type": "SnapshotFilterConditions",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventorySnapshotsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

