import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';

import { NetworkInterfaceSelect } from '@/components/regions/networkInterfaceSelect';
import { ResourceGroupSelect } from '@/components/regions/resourceGroupSelect';
import { VirtualMachineSizeSelect } from '@/components/regions/virtualMachineSizeSelect';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreAzureVMState } from '../restoreAzureVMWizard';

export const AzureVmConfiguration = (props: StepProps<RestoreAzureVMState>) => {
  const setVMName = (vmName?: string) =>
    props.setWizardState((state) => ({ ...state, vmName }));
  const onKeepOriginalTagsChange = (keepOriginalTags: boolean) =>
    props.setWizardState((state) => ({ ...state, keepOriginalTags }));
  const onTagsChange = (tags: Record<string, string>) =>
    props.setWizardState((state) => ({ ...state, tags }));

  const onNextClick = () => {
    props.setNextStep(props.currentStep.next?.[0]);
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      onBackClick={props.back}
      canGoNext={() =>
        props.wizardState.vmName !== undefined &&
        props.wizardState.resourceGroup !== undefined &&
        props.wizardState.location !== undefined &&
        props.wizardState.networkInterface !== undefined &&
        props.wizardState.vmSize !== undefined
      }
      onNextClick={onNextClick}
    >
      <Box className='mx-[40px] my-[24px]'>
        <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
          <FormControl size='small' className='flex-1'>
            <FormLabel>VM Name*</FormLabel>
            <TransactionalTextField
              initValue={props.wizardState.vmName ?? ''}
              onChange={setVMName}
            />
          </FormControl>
        </Stack>
        <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
          <ResourceGroupSelect
            accountId={props.wizardState.restoreAccount?.id}
            resourceGroup={props.wizardState.resourceGroup}
            onChange={(group) =>
              props.setWizardState((state) => ({
                ...state,
                resourceGroup: group?.name,
                location: group?.location,
              }))
            }
          />
          <NetworkInterfaceSelect
            accountId={props.wizardState.restoreAccount?.id}
            resourceGroup={props.wizardState.resourceGroup}
            networkInterface={props.wizardState.networkInterface}
            onChange={(networkInterface) =>
              props.setWizardState((state) => ({
                ...state,
                networkInterface,
              }))
            }
          />
        </Stack>
        <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
          <VirtualMachineSizeSelect
            vmSize={props.wizardState.vmSize}
            disksState={props.wizardState.disksState}
            originalVMSize={props.wizardState.originalVMSize}
            accountId={props.wizardState.restoreAccount?.id}
            location={props.wizardState.location}
            onChange={(vmSize) =>
              props.setWizardState((state) => ({ ...state, vmSize }))
            }
          />
        </Stack>
      </Box>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={props.wizardState.initialTags ?? {}}
          tags={props.wizardState.tags ?? {}}
          keepOriginalTags={props.wizardState.keepOriginalTags}
          setKeepOriginalTags={onKeepOriginalTagsChange}
          onChange={onTagsChange}
        />
      </CardContent>
    </StepContainer>
  );
};
