import type { S3Bucket } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useDAL } from '@/data/dal';

export const useBuckets = (accountId?: string, region?: string): S3Bucket[] => {
  const dal = useDAL();
  const { body: s3BucketsResponse } = dal.restore.s3Buckets.list(
    accountId || ''
  );
  const [buckets, setBuckets] = useState<S3Bucket[]>(
    s3BucketsResponse?.buckets || []
  );

  useEffect(() => {
    const generateBuckets = () => {
      if (!accountId) {
        return [];
      }

      const originBuckets = s3BucketsResponse?.buckets || [];
      if (!region) {
        return originBuckets;
      }

      return originBuckets.filter((bucket) => bucket.regionName === region);
    };

    setBuckets(generateBuckets());
  }, [s3BucketsResponse, region, accountId]);

  return buckets || [];
};
