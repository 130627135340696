/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SnapshotLocation {
  /**
  * The ID of the snapshot
  */
  'snapshotId': string;
  /**
  * The region where the snapshot is located
  */
  'region': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotLocation.attributeTypeMap;
  }

  public constructor() {
  }
}

