import type { ApiCredentialsProperties } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  UpsertApiCredentialsFlow,
  type UpsertApiCredentialsState,
} from './upsertApiCredentialsFlow';

interface UpsertApiCredentialsPanelProps {
  entity: ApiCredentialsProperties;
  onSave: (apiCredentials: ApiCredentialsProperties) => void;
  onAbort: () => void;
}

export function ApiCredentialsPanel(props: UpsertApiCredentialsPanelProps) {
  const save = (state: UpsertApiCredentialsState) => {
    props.onSave(state);
    return true;
  };

  return (
    <Wizard<UpsertApiCredentialsState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={
        props.entity.id
          ? UpsertApiCredentialsFlow.next!
          : [UpsertApiCredentialsFlow]
      }
      initialState={{
        ...props.entity,
      }}
      title={props.entity.clientId ? `Edit Credentials` : `Create Credentials`}
    />
  );
}
