/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Eon Job status. 
*/
export enum JobStatus {
  JOB_UNSPECIFIED = 'JOB_UNSPECIFIED',
  JOB_PENDING = 'JOB_PENDING',
  JOB_RUNNING = 'JOB_RUNNING',
  JOB_COMPLETED = 'JOB_COMPLETED',
  JOB_FAILED = 'JOB_FAILED',
  JOB_PARTIAL = 'JOB_PARTIAL'
}
