/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreJobSortField } from '../models/RestoreJobSortField';
import { SortOrder } from '../models/SortOrder';

/**
* List of sorting options. Sorting is applied in the order passed in the list. 
*/
export class SortRestoreJobBy {
  'field': RestoreJobSortField;
  'order': SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "field",
      "baseName": "field",
      "type": "RestoreJobSortField",
      "format": ""
    },
    {
      "name": "order",
      "baseName": "order",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SortRestoreJobBy.attributeTypeMap;
  }

  public constructor() {
  }
}



