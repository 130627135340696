import { Stack, Typography } from '@mui/material';

import { PanelWrapper } from './panelWrapper';

interface ConvertAllToEonSnapshotsPanelProps {
  onClose: () => void;
  back: () => void;
}

export default function ConvertAllToEonSnapshotsPanel(
  props: ConvertAllToEonSnapshotsPanelProps
) {
  const { onClose, back } = props;
  return (
    <PanelWrapper
      header={{
        onBack: back,
        title: 'Convert to Eon snapshots',
        subtitle: (
          <Stack width='87%'>
            <Typography variant='subtitle1' fontSize={14}>
              The AWS snapshots will be copied and converted to Eon’s snapshot
              format, allowing you to search and explore your data. If there are
              multiple snapshots in a day, only the last snapshot is converted.
              <br /> <br />
              The original snapshots won’t be deleted.
            </Typography>
          </Stack>
        ),
        onClose,
      }}
      footer={{
        primaryButton: {
          'data-testid': 'convert-to-eon-button',
          text: 'Convert',
          onClick: () => {},
          disabled: false,
        },
        secondaryButton: {
          'data-testid': 'convert-to-eon-cancel-button',
          text: 'Cancel',
          onClick: back,
        },
      }}
      testIdPrefix='convert-to-eon-snapshots-panel'
    >
      {/* <ConvertToEonSingleBody /> */}
      {/* <ConvertAllToEonBody /> */}
    </PanelWrapper>
  );
}

// const ConvertAllToEonBody = () => {
//   const [selectedColor, setSelectedColor] = useState<string>('#8AE3FF');

//   return (
//     <ConvertLifetimeLayout
//       source={{
//         dot: <OtherSnapshotsMultipleDots />,
//         title: 'AWS snapshots',
//         details: 'AWS snapshots',
//       }}
//       target={{
//         dot: <EonSnapshotsMultipleDots fill={selectedColor} />,
//         title: 'Eon snapshots',
//         details: 'Eon snapshots',
//       }}
//     />
//   );
// };
