/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AccountIdFilters } from '../models/AccountIdFilters';
import { BooleanFilters } from '../models/BooleanFilters';
import { IdFilters } from '../models/IdFilters';
import { InitiatorNameFilters } from '../models/InitiatorNameFilters';
import { JobStatusFilters } from '../models/JobStatusFilters';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { ResourceNameFilters } from '../models/ResourceNameFilters';
import { ResourceTypeFilters } from '../models/ResourceTypeFilters';

export class BackupJobsFilterConditions {
  'id'?: IdFilters;
  'workflowId'?: IdFilters;
  'snapshotId'?: IdFilters;
  'status'?: JobStatusFilters;
  'isOnDemand'?: BooleanFilters | null;
  'resourceType'?: ResourceTypeFilters;
  'providerAccountId'?: AccountIdFilters;
  'resourceName'?: ResourceNameFilters;
  'providerResourceId'?: ResourceIdFilters;
  'resourceId'?: IdFilters;
  'vaultId'?: IdFilters;
  'initiatorName'?: InitiatorNameFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "workflowId",
      "baseName": "workflowId",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "JobStatusFilters",
      "format": ""
    },
    {
      "name": "isOnDemand",
      "baseName": "isOnDemand",
      "type": "BooleanFilters",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceTypeFilters",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "AccountIdFilters",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "ResourceNameFilters",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "ResourceIdFilters",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "IdFilters",
      "format": ""
    },
    {
      "name": "initiatorName",
      "baseName": "initiatorName",
      "type": "InitiatorNameFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupJobsFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

