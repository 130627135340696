import { useContext } from 'react';

import { UserContext, type UserContextType } from './userContext';

export const useUser = (getDefaultsOutsideProvider = false) => {
  const context = useContext(UserContext);
  if (getDefaultsOutsideProvider && !context) {
    return {
      sourceAccounts: undefined,
      user: undefined,
      currentProjectId: '',
      changeRole: async () => {},
      changeProject: async () => {},
      recalculateAccounts: () => {},
      eonAccount: undefined,
    } satisfies UserContextType;
  }

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
