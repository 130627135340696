/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Initiator } from '../models/Initiator';
import { JobStatus } from '../models/JobStatus';
import { ResourceDetails } from '../models/ResourceDetails';

export class JobExecutionDetails {
  /**
  * The ID of the job
  */
  'id': string;
  /**
  * The ID of the workflow
  */
  'workflowId': string;
  /**
  * The ID of the snapshot
  */
  'snapshotId'?: string;
  'status': JobStatus;
  /**
  * The date the job started
  */
  'startTime': Date;
  /**
  * The date the job was last updated
  */
  'updatedTime': Date;
  /**
  * The date the job ended
  */
  'endTime'?: Date;
  /**
  * Whether the job is on demand (by user or by BP)
  */
  'isOnDemand'?: boolean;
  'onDemandInitiator'?: Initiator;
  'resourceDetails'?: ResourceDetails;
  /**
  * The ID of the vault
  */
  'vaultId'?: string;
  /**
  * The name of the vault
  */
  'vaultName'?: string;
  /**
  * The error message of the job
  */
  'errorMessage'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "workflowId",
      "baseName": "workflowId",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "JobStatus",
      "format": ""
    },
    {
      "name": "startTime",
      "baseName": "startTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "updatedTime",
      "baseName": "updatedTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "endTime",
      "baseName": "endTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "isOnDemand",
      "baseName": "isOnDemand",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "onDemandInitiator",
      "baseName": "onDemandInitiator",
      "type": "Initiator",
      "format": ""
    },
    {
      "name": "resourceDetails",
      "baseName": "resourceDetails",
      "type": "ResourceDetails",
      "format": ""
    },
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "vaultName",
      "baseName": "vaultName",
      "type": "string",
      "format": ""
    },
    {
      "name": "errorMessage",
      "baseName": "errorMessage",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return JobExecutionDetails.attributeTypeMap;
  }

  public constructor() {
  }
}



