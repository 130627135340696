/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { CloudAccountConfigurationAwsSecurityGroup } from '../models/CloudAccountConfigurationAwsSecurityGroup';
import { CloudAccountConfigurationAwsSubnet } from '../models/CloudAccountConfigurationAwsSubnet';

export class CloudAccountConfigurationAwsConfigInner {
  'region': string;
  'vpc': string;
  'cloudAccountConfigurationAwsSubnets': Array<CloudAccountConfigurationAwsSubnet>;
  'cloudAccountConfigurationAwsSecurityGroups': Array<CloudAccountConfigurationAwsSecurityGroup>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "vpc",
      "baseName": "vpc",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudAccountConfigurationAwsSubnets",
      "baseName": "cloudAccountConfigurationAwsSubnets",
      "type": "Array<CloudAccountConfigurationAwsSubnet>",
      "format": ""
    },
    {
      "name": "cloudAccountConfigurationAwsSecurityGroups",
      "baseName": "cloudAccountConfigurationAwsSecurityGroups",
      "type": "Array<CloudAccountConfigurationAwsSecurityGroup>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CloudAccountConfigurationAwsConfigInner.attributeTypeMap;
  }

  public constructor() {
  }
}

