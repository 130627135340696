import { Typography, Box, List, ListItem, Tooltip } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';

import { CircleImage } from '@/components/shared/circleImage';
import { useRoles } from '@/contexts/useRoles';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';

import { AutoClassification } from './autoClassifcationButtons';

export function AppsViewer({
  entity,
  onEditApplications,
  onResumeApplicationClassification,
}: {
  entity: InventoryResource;
  onEditApplications: () => void;
  onResumeApplicationClassification: () => void;
}) {
  const { isAuthorizedResource } = useRoles();
  const appText = !entity.classifications?.appsDetails?.apps?.length
    ? 'No apps were detected'
    : 'These apps were detected:';

  const manualOverride = entity.classifications?.appsDetails?.isOverridden;

  return (
    <>
      <AutoClassification
        testId='apps-auto-classification-buttons'
        isAutoClassificationOn={!manualOverride}
        textOn={appText}
        restartDetectionClicked={() => onResumeApplicationClassification()}
        editOverridesClicked={() => onEditApplications()}
        setEditorOpen={() => onEditApplications()}
        disabled={!isAuthorizedResource('update:apps_classification', entity)}
      />
      {!!entity.classifications?.appsDetails?.apps?.length && (
        <Box>
          <List data-testid='apps-viewer-app-list'>
            {entity.classifications.appsDetails.apps.map((v, i) => (
              <ListItem key={i} sx={{ height: '40px', paddingLeft: 0 }}>
                <Tooltip title={v.name}>
                  <CircleImage
                    alt={v.name}
                    src={ApplicationsLogosWithAliases[v.name]}
                  />
                </Tooltip>
                <Typography>{v.name}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
}
