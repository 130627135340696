import { IconButton, Tooltip, type IconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

interface ExtendedIconButtonProps extends IconButtonProps {
  tooltip?: React.ReactNode;
}

export const ExtendedIconButton = forwardRef<
  HTMLButtonElement,
  ExtendedIconButtonProps
>(function ExtendedIconButton(props, ref) {
  const { tooltip, children, ...iconButtonProps } = props;

  return (
    <Tooltip title={tooltip || ''} disableHoverListener={!tooltip}>
      {/* adding span here so the tooltip will work even when the button is disabled
      https://mui.com/material-ui/react-tooltip/#disabled-elements
      */}
      <span>
        <IconButton {...iconButtonProps} ref={ref} size='small' color='primary'>
          {props.children}
        </IconButton>
      </span>
    </Tooltip>
  );
});
