// MUI Imports
import type { Theme } from '@mui/material';

const formControl: Theme['components'] = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: () => ({
        margin: 0,

        // Radio & Checkbox
        '&.Mui-disabled:not(:has(.MuiSwitch-switchBase))': {
          opacity: 0.45,
        },

        // Switch
        '&.Mui-disabled .MuiSwitch-root:has(.MuiSwitch-switchBase.Mui-disabled), &.Mui-disabled:has(.MuiSwitch-switchBase) .MuiFormControlLabel-label':
          {
            opacity: 0.45,
          },
      }),
      label: {
        '&, &.Mui-disabled': {
          color: 'var(--mui-palette-text-primary)',
        },
      },
    },
  },
};

export default formControl;
