/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotLocation } from '../models/SnapshotLocation';

export class GenericSnapshotData {
  /**
  * The date the snapshot creation was initiated
  */
  'startedAt'?: Date;
  'snapshotLocation'?: SnapshotLocation;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "snapshotLocation",
      "baseName": "snapshotLocation",
      "type": "SnapshotLocation",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return GenericSnapshotData.attributeTypeMap;
  }

  public constructor() {
  }
}

