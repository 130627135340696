import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { DailyStorageSummaries } from '../models/DailyStorageSummaries';

/**
 * no description
 */
export class DashboardApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get Daily Storage Summaries
   * @param projectId [ID of the project](/global-settings/projects) whose daily storage summaries you want to retrieve. 
   * @param startDate First day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   * @param endDate Last day of the date range in &#x60;YYYY-MM-DD&#x60; format.
   */
  public async getDailyStorageSummaries(projectId: string, startDate: string, endDate: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("DashboardApi", "getDailyStorageSummaries", "projectId");
    }


    // verify required parameter 'startDate' is not null or undefined
    if (startDate === null || startDate === undefined) {
      throw new RequiredError("DashboardApi", "getDailyStorageSummaries", "startDate");
    }


    // verify required parameter 'endDate' is not null or undefined
    if (endDate === null || endDate === undefined) {
      throw new RequiredError("DashboardApi", "getDailyStorageSummaries", "endDate");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/dashboard/daily-storage-summary'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (startDate !== undefined) {
      requestContext.setQueryParam("startDate", ObjectSerializer.serialize(startDate, "string", "date"));
    }

    // Query Params
    if (endDate !== undefined) {
      requestContext.setQueryParam("endDate", ObjectSerializer.serialize(endDate, "string", "date"));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class DashboardApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getDailyStorageSummaries
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getDailyStorageSummariesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<DailyStorageSummaries >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: DailyStorageSummaries = ObjectSerializer.deserialize(
        await response.body.json(),
        "DailyStorageSummaries", ""
      ) as DailyStorageSummaries;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: DailyStorageSummaries = await response.body.json() as DailyStorageSummaries;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
