import { Stack } from '@mui/material';
import type {
  SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
  InventoryResource,
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotDetails } from './snapshotDetails';
import { SnapshotRow } from './snapshotRow';

export type RestoreActionType =
  | 'convert-snapshot'
  | 'convert-image'
  | 'volume'
  | 'full-instance';

interface VMSnapshotRowProps {
  snap: Snapshot;
  vault: BackupVault | undefined;
  onRestore: (
    vols: (
      | SnapshotPropertiesAzurePropertiesDiskPropertiesInner
      | SnapshotPropertiesVolumePropertiesInner
    )[],
    actionType: RestoreActionType
  ) => void;
  isSelected: boolean;
  onSelect: () => void;
  resource: InventoryResource;
  snapshotSelection: boolean;
}

export const VMSnapshotRow = (props: VMSnapshotRowProps) => {
  if (props.resource.cloudProvider === Provider.Azure) {
    return <AzureVMSnapshotRow {...props} />;
  }

  if (props.resource.cloudProvider === Provider.Aws) {
    return <AwsEc2SnapshotRow {...props} />;
  }

  return null;
};

const AwsEc2SnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
  snapshotSelection,
}: VMSnapshotRowProps) => {
  const [selectedVolume, setSelectedVolume] = useState<string>(
    snap.resourceProperties?.volumeProperties?.[0].volumeId || ''
  );
  const { fullInstanceRestore } = useFeatureFlags();

  if (!snap.resourceProperties?.volumeProperties) {
    return null;
  }

  const numOfVols = snap.resourceProperties.volumeProperties.length || 0;
  const onSelectSnapshot =
    numOfVols > 1
      ? (volId: string) => {
          setSelectedVolume(volId);
          onSelect();
        }
      : undefined;

  let enabledFullInstanceRestore = false;
  if (fullInstanceRestore?.filterType === 'exclude') {
    enabledFullInstanceRestore =
      !fullInstanceRestore.providerResourceIds.includes(
        resource.providerResourceId
      );
  } else if (fullInstanceRestore?.filterType === 'include') {
    enabledFullInstanceRestore =
      fullInstanceRestore.providerResourceIds.includes(
        resource.providerResourceId
      );
  }

  return (
    <Stack data-testid='vm-snapshot-row' key={snap.id} className='w-full'>
      {enabledFullInstanceRestore ? (
        <SnapshotDetails
          resource={resource}
          key={snap.id}
          isSelected={isSelected}
          onSelect={onSelect}
          snap={snap}
          vault={vault}
          snapshotSelection={snapshotSelection}
          onRestore={(volumes, actionType) => onRestore(volumes, actionType)}
          onConvertSnapshot={(volumes, actionType) =>
            onRestore(volumes, actionType)
          }
        />
      ) : (
        snap.resourceProperties.volumeProperties.map((vol) => (
          <SnapshotRow
            resource={resource}
            key={`${snap.id}_${vol.volumeId}`}
            isSelected={selectedVolume === vol.volumeId}
            onSelect={() => onSelectSnapshot?.(vol.volumeId)}
            snap={snap}
            vault={vault}
            volume={vol.volumeName}
            onRestore={() => onRestore([vol], 'volume')}
            onConvertSnapshot={() => onRestore([vol], 'convert-snapshot')}
            snapshotSelection={snapshotSelection}
          />
        ))
      )}
    </Stack>
  );
};

const AzureVMSnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
  snapshotSelection,
}: VMSnapshotRowProps) => {
  return (
    <Stack data-testid='vm-snapshot-row' key={snap.id} className='w-full'>
      <SnapshotDetails
        resource={resource}
        key={snap.id}
        isSelected={isSelected}
        onSelect={onSelect}
        snap={snap}
        vault={vault}
        snapshotSelection={snapshotSelection}
        onRestore={(volumes, actionType) => onRestore(volumes, actionType)}
      />
    </Stack>
  );
};
