import type { Account, CloudAccountConfiguration } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { AwsRestoreAccountConfigVpcSelection } from './awsRestoreAccountConfigVpcSelection';

export interface AwsRestoreAccountConfigState {
  account: Account;
  config: CloudAccountConfiguration;
}

export const AwsRestoreAccountConfigFlow: Step<AwsRestoreAccountConfigState> = {
  name: 'VPC Selection',
  Component: AwsRestoreAccountConfigVpcSelection,
  next: [],
};
