/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { QueryDBStatus } from '../models/QueryDBStatus';

export class QueryDBStatusResponse {
  /**
  * Time the query took to run, in milliseconds.
  */
  'runningTimeMs'?: number;
  'status': QueryDBStatus;
  /**
  * Error message. Present only if the status is `FAILED`. 
  */
  'errorMessage'?: string | null;
  /**
  * Location where the query results are saved to. This is set by `destination` in the [Run Query](./run-query) request body. 
  */
  'outputLocation'?: string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "runningTimeMs",
      "baseName": "runningTimeMs",
      "type": "number",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "QueryDBStatus",
      "format": ""
    },
    {
      "name": "errorMessage",
      "baseName": "errorMessage",
      "type": "string",
      "format": ""
    },
    {
      "name": "outputLocation",
      "baseName": "outputLocation",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return QueryDBStatusResponse.attributeTypeMap;
  }

  public constructor() {
  }
}



