import type { InventoryResource } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';

import { RestartAutoClassificationPanel } from './resumeAutoClassificationPanel';

export function RestartApplicationDetection({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const dal = useDAL();

  const onSave = () => {
    void dal.inventory.apps
      .deleteOverride(entities)
      .then(() => {
        for (const entity of entities) {
          entity.classifications = entity.classifications || {};
          entity.classifications.appsDetails =
            entity.classifications.appsDetails || {};
          entity.classifications.appsDetails.apps = [];
          entity.classifications.appsDetails.isOverridden = false;
        }
      })
      .finally(onClose);
  };
  if (!open) {
    return null;
  }

  return (
    <RestartAutoClassificationPanel
      open={open}
      onClose={onClose}
      onSave={onSave}
      title='Resume auto-classification for applications'
      description='Applications will be automatically detected, and your overridden applications will be removed. This could change the applied backup policies and controls.'
    />
  );
}
