/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreDbToRdsInstanceRequestDestination } from '../models/RestoreDbToRdsInstanceRequestDestination';

export class RestoreDbToRdsInstanceRequest {
  /**
  * Eon-assigned ID of the restore account.
  */
  'restoreAccountId': string;
  'destination': RestoreDbToRdsInstanceRequestDestination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "destination",
      "baseName": "destination",
      "type": "RestoreDbToRdsInstanceRequestDestination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreDbToRdsInstanceRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

