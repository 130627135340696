import type { Account } from '@repo/api-gw-sdk';
import { useEffect } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { BucketSelection } from './bucketSelection';
import { useBuckets } from './useBuckets';

interface RestoreGenericStorageProps {
  region?: string;
  restoreMode: 'select' | 'manual';
  prefix?: string;
  S3: { Bucket?: string; BucketOverride?: string };
  StorageAccount: {
    AccountName?: string;
    AccountNameOverride?: string;
    ResourceGroup?: string;
    ResourceGroupOverride?: string;
    Container?: string;
    ContainerOverride?: string;
  };
}

type BucketSelectionStepProps<T> = StepProps<T & { restoreAccount?: Account }>;

export const BucketSelectionStep = <T extends RestoreGenericStorageProps>(
  props: BucketSelectionStepProps<T>
) => {
  const { setWizardState: setBucketSelectionState } = props;
  const dal = useDAL();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.RestoreBucket
  );
  const chosenBucket = props.wizardState.S3.Bucket || pref?.value?.bucketName;
  const buckets = useBuckets(
    props.wizardState.restoreAccount?.id,
    props.wizardState.region
  );

  useEffect(() => {
    if (chosenBucket && buckets.length) {
      const availableBucket = buckets.find(
        (x) => x.bucketName === chosenBucket
      );
      const S3 = availableBucket
        ? {
            Bucket: availableBucket.bucketName,
            BucketOverride: availableBucket.bucketName,
          }
        : {};

      setBucketSelectionState((state) => ({
        ...state,
        S3,
      }));
    }
  }, [buckets, chosenBucket, setBucketSelectionState]);

  return (
    <BucketSelection
      {...props.wizardState}
      restoreAccount={props.wizardState.restoreAccount}
      setBucketSelectionState={setBucketSelectionState}
      buckets={buckets}
    />
  );
};
