import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ActivateAwsOrganizationResponse } from '../models/ActivateAwsOrganizationResponse';
import { ActivateSourceAccountResponse } from '../models/ActivateSourceAccountResponse';
import { ConnectAwsOrganizationRequest } from '../models/ConnectAwsOrganizationRequest';
import { ConnectAwsOrganizationResponse } from '../models/ConnectAwsOrganizationResponse';
import { ConnectSourceAccountRequest } from '../models/ConnectSourceAccountRequest';
import { ConnectSourceAccountResponse } from '../models/ConnectSourceAccountResponse';
import { DeactivateAwsOrganizationResponse } from '../models/DeactivateAwsOrganizationResponse';
import { DeactivateSourceAccountResponse } from '../models/DeactivateSourceAccountResponse';
import { ListRestoreAccountsResponse } from '../models/ListRestoreAccountsResponse';

/**
 * no description
 */
export class AccountsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Activate an aws organization and its accounts
   * @param id ID of the aws organization to activate
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the aws organization to. 
   */
  public async activateAwsOrganization(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("AccountsApi", "activateAwsOrganization", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "activateAwsOrganization", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/aws-organizations/{id}/activate'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Activate an account
   * @param id ID of the account to activate
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the aws organization to. 
   */
  public async activateSourceAccount(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("AccountsApi", "activateSourceAccount", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "activateSourceAccount", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/source-accounts/{id}/activate'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Connect Aws Organization
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the aws organization to. 
   * @param connectAwsOrganizationRequest 
   */
  public async connectAwsOrganization(projectId: string, connectAwsOrganizationRequest: ConnectAwsOrganizationRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "connectAwsOrganization", "projectId");
    }


    // verify required parameter 'connectAwsOrganizationRequest' is not null or undefined
    if (connectAwsOrganizationRequest === null || connectAwsOrganizationRequest === undefined) {
      throw new RequiredError("AccountsApi", "connectAwsOrganization", "connectAwsOrganizationRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/aws-organizations'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(connectAwsOrganizationRequest, "ConnectAwsOrganizationRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Connect Source Account
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the source account to. 
   * @param connectSourceAccountRequest 
   */
  public async connectSourceAccount(projectId: string, connectSourceAccountRequest: ConnectSourceAccountRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "connectSourceAccount", "projectId");
    }


    // verify required parameter 'connectSourceAccountRequest' is not null or undefined
    if (connectSourceAccountRequest === null || connectSourceAccountRequest === undefined) {
      throw new RequiredError("AccountsApi", "connectSourceAccount", "connectSourceAccountRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/source-accounts'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(connectSourceAccountRequest, "ConnectSourceAccountRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Deactivate an aws organization and its accounts
   * @param id ID of the aws organization to deactivate
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the aws organization to. 
   */
  public async deactivateAwsOrganization(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("AccountsApi", "deactivateAwsOrganization", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "deactivateAwsOrganization", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/aws-organizations/{id}/deactivate'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Deactivate an account
   * @param id ID of the account to deactivate
   * @param projectId [ID of the project](/global-settings/projects) you want to connect the aws organization to. 
   */
  public async deactivateSourceAccount(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("AccountsApi", "deactivateSourceAccount", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "deactivateSourceAccount", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/source-accounts/{id}/deactivate'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PATCH, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Restore Accounts
   * @param projectId [ID of the project](/global-settings/projects) whose restore accounts you want to retrieve. 
   */
  public async listRestoreAccounts(projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("AccountsApi", "listRestoreAccounts", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/restore-accounts'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class AccountsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to activateAwsOrganization
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async activateAwsOrganizationWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ActivateAwsOrganizationResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ActivateAwsOrganizationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ActivateAwsOrganizationResponse", ""
      ) as ActivateAwsOrganizationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ActivateAwsOrganizationResponse = await response.body.json() as ActivateAwsOrganizationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to activateSourceAccount
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async activateSourceAccountWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ActivateSourceAccountResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ActivateSourceAccountResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ActivateSourceAccountResponse", ""
      ) as ActivateSourceAccountResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ActivateSourceAccountResponse = await response.body.json() as ActivateSourceAccountResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to connectAwsOrganization
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async connectAwsOrganizationWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ConnectAwsOrganizationResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ConnectAwsOrganizationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ConnectAwsOrganizationResponse", ""
      ) as ConnectAwsOrganizationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("409", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Aws organization already exists in Eon.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ConnectAwsOrganizationResponse = await response.body.json() as ConnectAwsOrganizationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to connectSourceAccount
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async connectSourceAccountWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ConnectSourceAccountResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ConnectSourceAccountResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ConnectSourceAccountResponse", ""
      ) as ConnectSourceAccountResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("409", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Source account already exists in Eon.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ConnectSourceAccountResponse = await response.body.json() as ConnectSourceAccountResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to deactivateAwsOrganization
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async deactivateAwsOrganizationWithHttpInfo(response: ResponseContext): Promise<HttpInfo<DeactivateAwsOrganizationResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: DeactivateAwsOrganizationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "DeactivateAwsOrganizationResponse", ""
      ) as DeactivateAwsOrganizationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: DeactivateAwsOrganizationResponse = await response.body.json() as DeactivateAwsOrganizationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to deactivateSourceAccount
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async deactivateSourceAccountWithHttpInfo(response: ResponseContext): Promise<HttpInfo<DeactivateSourceAccountResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: DeactivateSourceAccountResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "DeactivateSourceAccountResponse", ""
      ) as DeactivateSourceAccountResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: DeactivateSourceAccountResponse = await response.body.json() as DeactivateSourceAccountResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listRestoreAccounts
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listRestoreAccountsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListRestoreAccountsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListRestoreAccountsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListRestoreAccountsResponse", ""
      ) as ListRestoreAccountsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListRestoreAccountsResponse = await response.body.json() as ListRestoreAccountsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
