import type { Theme } from '@mui/material';

const menu = (): Theme['components'] => ({
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        marginBlockStart: theme.spacing(0.5),
        boxShadow: 'var(--mui-customShadows-lg)',
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingBlock: theme.spacing(2),
        color: 'var(--mui-palette-text-primary)',
        '&:hover': {
          backgroundColor: 'var(--mui-palette-primary-lightOpacity)',
        },
        '&.Mui-selected:not(:has(input))': {
          color: 'var(--mui-palette-primary-dark)',
          backgroundColor: 'var(--mui-palette-primary-main)',
          '& .MuiListItemIcon-root': {
            color: 'var(--mui-palette-primary-dark)',
          },
          '&& *': {
            color: 'var(--mui-palette-primary-dark)',
          },
          '&:hover': {
            backgroundColor: 'var(--mui-palette-primary-main)',
          },
        },
        '&.Mui-disabled': {
          color: 'var(--mui-palette-text-disabled)',
          opacity: 1,
        },
      }),
    },
  },
});

export default menu;
