import type { Configuration, NotificationPolicy } from '@repo/api-gw-sdk';
import { NotificationsApi } from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/notifications';

export const notifications = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const notificationsApi = new NotificationsApi(config);

  return {
    list: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters: Condition | undefined
    ) =>
      httpClient.execute(
        [currentProjectId, pagination, sorting, columnFilters],
        () =>
          notificationsApi.listNotifications(
            pagination.pageIndex,
            pagination.pageSize,
            `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
            currentProjectId,
            columnFilters ? toJsonText(columnFilters) : undefined
          )
      ),
    policies: {
      list: () =>
        httpClient.execute([baseUrl, 'policies', currentProjectId], () =>
          notificationsApi.listNotificationPolicies(currentProjectId)
        ),
      create: (notificationPolicy: NotificationPolicy) =>
        notificationsApi.createNotificationPolicy(
          currentProjectId,
          notificationPolicy
        ),
      update: (notificationPolicy: NotificationPolicy) =>
        notificationsApi.updateNotificationPolicy(
          notificationPolicy.id,
          currentProjectId,
          notificationPolicy
        ),
      delete: (id: string) =>
        notificationsApi.deleteNotificationPolicy(id, currentProjectId),
    },
  };
};
