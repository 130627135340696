import type { Role } from '@repo/api-gw-sdk';

import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceName,
  resourceType,
  sourceRegion,
  subnets,
  tags,
  vpc,
  resourceId,
} from '@/components/queryBuilder/properties';
import type { PermissionDescriptor } from '@/contexts/permissions';
import type { FilterProperty } from '@/types/advanceFilter';

export const dataAccessConditionSupportedProperties: FilterProperty[][] = [
  [resourceType, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets],
  [resourceName, resourceId, tags],
];

const isConditionalPermission = (
  dataAccessRolesFlag: boolean,
  permission: PermissionDescriptor
) => !!(dataAccessRolesFlag && permission.isConditional);

export const getAssignedDataAccessRule = (
  dataAccessRolesFlag: boolean,
  permission: PermissionDescriptor,
  role: Role
) =>
  (isConditionalPermission(dataAccessRolesFlag, permission) &&
    Object.values(role.dataAccessRules || {}).find((rule) =>
      (permission.conditionalScopesOverrides || permission.scopes).every(
        (scope) => rule.scopes.includes(scope)
      )
    )) ||
  undefined;
