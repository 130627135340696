import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import type { Column } from '@tanstack/react-table';

import { useViewParameters } from '@/contexts/navigation';
import {
  createCombineCondition,
  isValueCondition,
  StringOperator,
  type Condition,
  type FilterProperty,
  type ValueCondition,
} from '@/types/advanceFilter';

import { getProperties } from '../queryBuilder/propertiesUtils';

export const FiltersBar = ({
  columns,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
}) => {
  const { query, setParams } = useViewParameters();

  if (!query) {
    return null;
  }

  const properties = columns.flatMap((x) => getProperties(x));
  const allConditions = collectValueConditions(query).reduce<
    { condition: ValueCondition; value: string; property: FilterProperty }[]
  >((acc, condition) => {
    condition.value.forEach((value) => {
      const property = properties.find((x) => x.name === condition.property);

      if (property) {
        acc.push({ condition, value, property });
      }
    });
    return acc;
  }, []);

  const visibleConditions = allConditions.filter(
    ({ condition, property }) => !property.filter?.shouldHideBadge?.(condition)
  );

  if (!visibleConditions.length) {
    return null;
  }

  return (
    <Stack
      width='70%'
      padding='0 12px 24px 40px'
      direction={'row'}
      flexWrap={'wrap'}
      gap={'8px'}
      data-testid='filters-bar'
    >
      {visibleConditions.map(({ condition, value, property }) => {
        const Badge = property.filter?.Badge;

        return (
          <Tooltip
            key={condition.property + value}
            title={property?.displayName || ''}
          >
            <Stack
              direction={'row'}
              className='py-[4px] px-[12px] inline-flex rounded-[4px]'
              sx={{ border: '1px solid #272727' }}
              alignItems={'center'}
            >
              <Typography
                component={'span'}
                className='font-semibold cursor-default text-ellipsis overflow-hidden	whitespace-nowrap	max-w-[200px]'
              >
                {Badge && <Badge conditionValue={value} />}
                {!Badge && value}
              </Typography>
              <i
                className='material-symbols-close-rounded text-[22px] ml-[4px] cursor-pointer'
                onClick={() => {
                  const updatedCondition = condition.value.filter(
                    (x) => x !== value
                  );

                  setParams((state) => ({
                    ...state,
                    query: replaceValueCondition(
                      query,
                      condition,
                      updatedCondition.length
                        ? { ...condition, value: updatedCondition }
                        : undefined
                    ),
                  }));
                }}
              />
            </Stack>
          </Tooltip>
        );
      })}
      <Tooltip title='Clear all'>
        <IconButton
          onClick={() =>
            setParams((state) => ({
              ...state,
              query: createCombineCondition(
                allConditions
                  .map((x) => x.condition)
                  .filter((x) => x.operator === StringOperator.NotIn)
              ),
            }))
          }
        >
          <i className='material-symbols-filter-alt-off-outline-rounded text-textPrimary h-[20px] w-[20px]'></i>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

function replaceValueCondition(
  filters: Condition,
  oldCondition: Condition,
  newCondition: Condition | undefined
) {
  if (isValueCondition(filters)) {
    return filters;
  }

  return {
    ...filters,
    conditions: filters.conditions.reduce<Condition[]>((agg, val) => {
      if (val === oldCondition) {
        if (newCondition) {
          agg.push(newCondition);
        }
      } else {
        agg.push(replaceValueCondition(val, oldCondition, newCondition));
      }

      return agg;
    }, []),
  };
}

function collectValueConditions(condition: Condition): ValueCondition[] {
  return isValueCondition(condition)
    ? [condition]
    : condition.conditions.flatMap(collectValueConditions);
}
