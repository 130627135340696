/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ApiCredentialsProperties {
  /**
  * The ID of the api credential
  */
  'id': string;
  /**
  * The client ID of the api credential
  */
  'clientId': string;
  /**
  * The given name of the api credential
  */
  'givenName': string;
  /**
  * The projects the api credentials has access to
  */
  'projects': Array<string>;
  /**
  * The role ID of the api credential
  */
  'roleId': string;
  /**
  * The date the api credentials expires
  */
  'expiresAt'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "clientId",
      "baseName": "clientId",
      "type": "string",
      "format": ""
    },
    {
      "name": "givenName",
      "baseName": "givenName",
      "type": "string",
      "format": ""
    },
    {
      "name": "projects",
      "baseName": "projects",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "roleId",
      "baseName": "roleId",
      "type": "string",
      "format": ""
    },
    {
      "name": "expiresAt",
      "baseName": "expiresAt",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ApiCredentialsProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

