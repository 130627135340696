/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AzureResourceGroup {
  /**
  * The name of the resource group
  */
  'name': string;
  /**
  * The location of the resource group
  */
  'location': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "location",
      "baseName": "location",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureResourceGroup.attributeTypeMap;
  }

  public constructor() {
  }
}

