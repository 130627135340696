/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ResourceTag {
  /**
  * The tag\'s key.
  */
  'key': string;
  /**
  * The tag\'s value.
  */
  'value'?: string | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "key",
      "baseName": "key",
      "type": "string",
      "format": ""
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ResourceTag.attributeTypeMap;
  }

  public constructor() {
  }
}

