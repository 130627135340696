'use client';

import type { InventoryResource, Role } from '@repo/api-gw-sdk';
import { createContext, type PropsWithChildren } from 'react';

import { rolesComparer } from '@/configs/roles';
import { useDAL } from '@/data/dal';

import { permissions, type PermissionDescriptor } from './permissions';
import { useUser } from './useUser';

const isAuthorized = (scope: string, roleScopes: string[]) => {
  const [permission, action] = scope.split(':');
  const optionalScopes = [
    scope,
    `${permission}:all`,
    `admin:${action}`,
    'admin:all',
  ];
  return optionalScopes.some((x) => roleScopes.includes(x));
};

export const RolesContext = createContext<{
  roles: Role[];
  rolesMap: Record<string, Role>;
  permissions: Record<string, PermissionDescriptor>;
  isAuthorized: (scope: string, roleOverride?: Role) => boolean;
  isAuthorizedResource: (
    scope: string,
    resource: InventoryResource | undefined
  ) => boolean;

  refreshRoles: () => void;
} | null>(null);

export const RolesProvider = ({ children }: PropsWithChildren) => {
  const dal = useDAL();

  const { user } = useUser();

  const { body: rolesBody, mutate: reloadRoles } = dal.roles.list();

  const roles = (rolesBody?.roles ?? []).sort(rolesComparer);
  const rolesMap = Object.assign(
    {},
    ...roles.map((role) => ({ [role.id]: role }))
  );

  return (
    <RolesContext.Provider
      value={{
        roles,
        rolesMap,
        permissions,
        isAuthorized: (scope, roleOverride) =>
          isAuthorized(
            scope,
            roleOverride?.scopes || user?.selectedRole.scopes || []
          ),
        isAuthorizedResource: (scope, resource) => {
          if (!resource) {
            return false;
          }

          if (!resource.metadata?.disallowedScopes) {
            return isAuthorized(scope, user?.selectedRole.scopes || []);
          }

          if (resource.metadata.disallowedScopes.includes(scope)) {
            return false;
          }

          return isAuthorized(scope, user?.selectedRole.scopes || []);
        },
        refreshRoles: () => void reloadRoles(),
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
