import { Stack, TextField } from '@mui/material';
import React, { useState } from 'react';

import { dayjs } from '@/utils/dayjs';

import {
  FragmentSelector,
  type DurationFragment,
} from './MaximumRetentionEditor';

interface RetentionSelectorProps {
  initialRetention?: number;
  initialFragment?: DurationFragment;
  onChange: (retentionInDays: number) => void;
}

export default function RetentionSelector(props: RetentionSelectorProps) {
  const [selectedFragment, setSelectedFragment] = useState<DurationFragment>(
    props.initialFragment || 'days'
  );
  const [selectedRetention, setSelectedRetention] = useState(
    props.initialRetention || 7
  );

  const calcInDays = (retention: number, fragment: DurationFragment) => {
    return dayjs.duration({ [fragment]: retention }).asDays();
  };

  const onFragmentChange = (fragment: DurationFragment) => {
    setSelectedFragment(fragment);
    props.onChange(calcInDays(selectedRetention, fragment));
  };

  const onRetentionChange = (retention: number) => {
    setSelectedRetention(retention);
    props.onChange(calcInDays(retention, selectedFragment));
  };

  return (
    <Stack direction='row' alignItems='center' gap='12px'>
      <TextField
        data-testid='retention-input'
        className='w-[76px]'
        type='number'
        inputProps={{
          min: 1,
          step: 1,
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        value={selectedRetention}
        onChange={(e) => onRetentionChange(+e.target.value)}
      />
      <FragmentSelector
        fragment={selectedFragment}
        onChange={onFragmentChange}
      />
    </Stack>
  );
}
