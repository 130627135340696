/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { CloudAccountConfigurationAwsConfigInner } from '../models/CloudAccountConfigurationAwsConfigInner';

export class CloudAccountConfiguration {
  'cloudAccountId': string;
  'awsConfig': Array<CloudAccountConfigurationAwsConfigInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "cloudAccountId",
      "baseName": "cloudAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "awsConfig",
      "baseName": "awsConfig",
      "type": "Array<CloudAccountConfigurationAwsConfigInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CloudAccountConfiguration.attributeTypeMap;
  }

  public constructor() {
  }
}

