/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AmiBlockDevice } from '../models/AmiBlockDevice';

export class Ami {
  /**
  * The AMI ID
  */
  'id': string;
  /**
  * The AMI name
  */
  'name': string;
  /**
  * The AMI description
  */
  'description': string;
  /**
  * The AMI tags
  */
  'tags': { [key: string]: string; };
  /**
  * The AMI block devices
  */
  'blockDevices': Array<AmiBlockDevice>;
  /**
  * The AMI root device name
  */
  'rootDeviceName': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "blockDevices",
      "baseName": "blockDevices",
      "type": "Array<AmiBlockDevice>",
      "format": ""
    },
    {
      "name": "rootDeviceName",
      "baseName": "rootDeviceName",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return Ami.attributeTypeMap;
  }

  public constructor() {
  }
}

