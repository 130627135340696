/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { QueryDBRestoreDestination } from '../models/QueryDBRestoreDestination';

export class QueryDBRequest {
  /**
  * Name of the database to query.
  */
  'databaseName': string;
  /**
  * SQL query to run against the database. Support is limited to Athena [`SELECT` queries](https://docs.aws.amazon.com/athena/latest/ug/select.html). 
  */
  'query': string;
  /**
  * Eon-assigned ID of the [restore account](./list-restore-accounts).
  */
  'restoreAccountId': string;
  'destination': QueryDBRestoreDestination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "databaseName",
      "baseName": "databaseName",
      "type": "string",
      "format": ""
    },
    {
      "name": "query",
      "baseName": "query",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "destination",
      "baseName": "destination",
      "type": "QueryDBRestoreDestination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return QueryDBRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

