import { Divider, Radio, Stack, Typography } from '@mui/material';
import {
  type SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
  Provider,
  type Snapshot,
  type SnapshotPropertiesVolumePropertiesInner,
  type InventoryResource,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import { Icon } from '@/components/shared/icon';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotActionsGroup } from './snapshotActionsGroup';
import type { RestoreActionType } from './vmSnapshotRow';

interface SnapshotDetailsBodyProps {
  resource: InventoryResource;
  snapshot: Snapshot;
  vault?: BackupVault;
  onRestore: (
    volumes: (
      | SnapshotPropertiesAzurePropertiesDiskPropertiesInner
      | SnapshotPropertiesVolumePropertiesInner
    )[],
    actionType: RestoreActionType
  ) => void;
  onConvertSnapshot?: (
    volumes: (
      | SnapshotPropertiesAzurePropertiesDiskPropertiesInner
      | SnapshotPropertiesVolumePropertiesInner
    )[],
    actionType: RestoreActionType
  ) => void;
}

export const SnapshotDetailsBody = (props: SnapshotDetailsBodyProps) => {
  const [fullInstanceSelected, setFullInstanceSelected] = useState(true);
  const volumes =
    (props.resource.cloudProvider === Provider.Aws
      ? props.snapshot?.resourceProperties?.volumeProperties
      : props.resource.cloudProvider === Provider.Azure
        ? props.snapshot?.resourceProperties?.azureProperties?.diskProperties
        : []) || [];

  const [selectedVolume, setSelectedVolume] = useState(volumes[0] || null);
  const { convertImage: convertImageEnabled } = useFeatureFlags();

  const onRestore = () => {
    if (fullInstanceSelected) {
      props.onRestore(volumes, 'full-instance');
      return;
    }

    if (selectedVolume) {
      props.onRestore([selectedVolume], 'volume');
    }
  };

  const onConvertSnapshot = () => {
    if (fullInstanceSelected) {
      props.onConvertSnapshot?.(volumes, 'convert-image');
      return;
    }

    if (selectedVolume) {
      props.onConvertSnapshot?.([selectedVolume], 'convert-snapshot');
    }
  };

  return (
    <Stack width='100%' height='100%' direction='column'>
      <Stack
        width='100%'
        direction='row'
        gap='40px'
        marginBottom='24px'
        marginLeft='36px'
      >
        <Stack direction='column' gap='16px'>
          <Typography marginBottom='8px' variant='body2'>
            What do you want to restore?
          </Typography>
          <Stack
            className='cursor-pointer'
            direction='row'
            alignItems='center'
            gap='12px'
            onClick={() => setFullInstanceSelected(true)}
          >
            <Radio
              data-testid='full-instance-radio'
              size='small'
              checked={fullInstanceSelected}
            />
            <Typography variant='body2'>Instance</Typography>
          </Stack>
          <Stack
            className='cursor-pointer'
            direction='row'
            alignItems='center'
            gap='12px'
            onClick={() => setFullInstanceSelected(false)}
          >
            <Radio
              data-testid='specific-vol-radio'
              size='small'
              checked={!fullInstanceSelected}
            />
            <Typography variant='body2'>
              {`Specific ${getVolumeName(props.resource.cloudProvider)}`}
            </Typography>
          </Stack>
        </Stack>
        <Divider orientation='vertical' flexItem />
        <Stack direction='column' gap='16px'>
          <Typography marginBottom='8px' variant='body2'>
            {fullInstanceSelected
              ? `These ${getVolumeName(props.resource.cloudProvider)}s will be restored`
              : `Choose a ${getVolumeName(props.resource.cloudProvider)} to restore`}
          </Typography>
          {volumes.map((volume) => {
            const id = 'volumeId' in volume ? volume.volumeId : volume.diskId;
            const name =
              'volumeName' in volume ? volume.volumeName : volume.diskName;

            return (
              <Stack
                key={id}
                className='cursor-pointer'
                direction='row'
                alignItems='center'
                gap='12px'
                onClick={() => setSelectedVolume(volume)}
              >
                {fullInstanceSelected ? (
                  <Icon
                    data-testid='vol-check-icon'
                    className='p-[2px]'
                    iconClass='material-symbols-check w-[20px] h-[20px]'
                  />
                ) : (
                  <Radio
                    data-testid='vol-selection-radio'
                    size='small'
                    checked={
                      id ===
                      ('volumeId' in selectedVolume
                        ? selectedVolume.volumeId
                        : selectedVolume.diskId)
                    }
                  />
                )}
                <Typography variant='body2'>{name}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <SnapshotActionsGroup
        snapshot={props.snapshot}
        vault={props.vault}
        onRestore={onRestore}
        resource={props.resource}
        isVisible
        convertSnapshot={
          !props.onConvertSnapshot ||
          (fullInstanceSelected && !convertImageEnabled)
            ? undefined
            : {
                title: fullInstanceSelected
                  ? 'Convert to AMI'
                  : 'Convert to EBS Snapshot',
                onConvertSnapshot,
              }
        }
      />
    </Stack>
  );
};

const getVolumeName = (provider: Provider) => {
  switch (provider) {
    case Provider.Azure:
      return 'disk';
    default:
      return 'volume';
  }
};
