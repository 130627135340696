import { Dialog, Stack } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

import Loading from '@/app/(account)/loading';

import { PanelWrapper, type PanelWrapperProps } from '../panels/panelWrapper';

interface MainDialogProps extends PanelWrapperProps {
  isOpen: boolean;
  isLoading: boolean;
}

export default function MainDialog(props: PropsWithChildren<MainDialogProps>) {
  const { isLoading, isOpen, header, footer } = props;

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          width: '40%',
          maxWidth: '840px',
          minWidth: '600px',
          height: 'max-content',
          minHeight: '300px',
          maxHeight: '90vh',
          borderRadius: '16px',
        },
      }}
    >
      <PanelWrapper
        rounded
        header={header ? { ...header } : undefined}
        footer={footer ? { ...footer } : undefined}
      >
        <Stack className='h-full py-[24px]'>
          {isLoading && <Loading />}
          {!isLoading && <>{props.children}</>}
        </Stack>
      </PanelWrapper>
    </Dialog>
  );
}
