/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { S3RestoreDestinationS3Bucket } from '../models/S3RestoreDestinationS3Bucket';

/**
* S3 bucket.
*/
export class S3RestoreDestination {
  's3Bucket': S3RestoreDestinationS3Bucket;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "s3Bucket",
      "baseName": "s3Bucket",
      "type": "S3RestoreDestinationS3Bucket",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3RestoreDestination.attributeTypeMap;
  }

  public constructor() {
  }
}

