import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ListNotificationPoliciesResponse } from '../models/ListNotificationPoliciesResponse';
import { ListNotificationsResponse } from '../models/ListNotificationsResponse';
import { NotificationPolicy } from '../models/NotificationPolicy';

/**
 * no description
 */
export class NotificationsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Create Notification Policy
   * @param projectId The project ID
   * @param notificationPolicy 
   */
  public async createNotificationPolicy(projectId: string, notificationPolicy: NotificationPolicy, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("NotificationsApi", "createNotificationPolicy", "projectId");
    }


    // verify required parameter 'notificationPolicy' is not null or undefined
    if (notificationPolicy === null || notificationPolicy === undefined) {
      throw new RequiredError("NotificationsApi", "createNotificationPolicy", "notificationPolicy");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/notification-policies'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(notificationPolicy, "NotificationPolicy", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Delete Notification Policy
   * @param id ID of the policy to delete
   * @param projectId The project ID
   */
  public async deleteNotificationPolicy(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("NotificationsApi", "deleteNotificationPolicy", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("NotificationsApi", "deleteNotificationPolicy", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/notification-policies/{id}'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.DELETE, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Notification Policies
   * @param projectId The project ID
   */
  public async listNotificationPolicies(projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("NotificationsApi", "listNotificationPolicies", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/notification-policies'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Notifications
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param projectId The project ID
   * @param filter The filter to apply
   */
  public async listNotifications(pageIndex: number, pageSize: number, sort: string, projectId: string, filter?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'pageIndex' is not null or undefined
    if (pageIndex === null || pageIndex === undefined) {
      throw new RequiredError("NotificationsApi", "listNotifications", "pageIndex");
    }


    // verify required parameter 'pageSize' is not null or undefined
    if (pageSize === null || pageSize === undefined) {
      throw new RequiredError("NotificationsApi", "listNotifications", "pageSize");
    }


    // verify required parameter 'sort' is not null or undefined
    if (sort === null || sort === undefined) {
      throw new RequiredError("NotificationsApi", "listNotifications", "sort");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("NotificationsApi", "listNotifications", "projectId");
    }



    // Path Params
    const localVarPath = '/v1/projects/{projectId}/notifications'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageIndex !== undefined) {
      requestContext.setQueryParam("pageIndex", ObjectSerializer.serialize(pageIndex, "number", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }

    // Query Params
    if (sort !== undefined) {
      requestContext.setQueryParam("sort", ObjectSerializer.serialize(sort, "string", ""));
    }

    // Query Params
    if (filter !== undefined) {
      requestContext.setQueryParam("filter", ObjectSerializer.serialize(filter, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Update Notification Policy
   * @param id ID of the notification policy to update
   * @param projectId The project ID
   * @param notificationPolicy 
   */
  public async updateNotificationPolicy(id: string, projectId: string, notificationPolicy: NotificationPolicy, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("NotificationsApi", "updateNotificationPolicy", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("NotificationsApi", "updateNotificationPolicy", "projectId");
    }


    // verify required parameter 'notificationPolicy' is not null or undefined
    if (notificationPolicy === null || notificationPolicy === undefined) {
      throw new RequiredError("NotificationsApi", "updateNotificationPolicy", "notificationPolicy");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/notification-policies/{id}'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.PUT, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(notificationPolicy, "NotificationPolicy", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class NotificationsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to createNotificationPolicy
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async createNotificationPolicyWithHttpInfo(response: ResponseContext): Promise<HttpInfo<NotificationPolicy >> {
    if (isCodeInRange("201", response.httpStatusCode)) {
      const body: NotificationPolicy = ObjectSerializer.deserialize(
        await response.body.json(),
        "NotificationPolicy", ""
      ) as NotificationPolicy;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("400", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Invalid request", undefined, response.headers, response);
    }
    if (isCodeInRange("409", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Notification policy already exists", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: NotificationPolicy = await response.body.json() as NotificationPolicy;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to deleteNotificationPolicy
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async deleteNotificationPolicyWithHttpInfo(response: ResponseContext): Promise<HttpInfo<void >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, undefined);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Notification policy not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: void = await response.body.json() as void;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listNotificationPolicies
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listNotificationPoliciesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListNotificationPoliciesResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListNotificationPoliciesResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListNotificationPoliciesResponse", ""
      ) as ListNotificationPoliciesResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListNotificationPoliciesResponse = await response.body.json() as ListNotificationPoliciesResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listNotifications
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listNotificationsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListNotificationsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListNotificationsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListNotificationsResponse", ""
      ) as ListNotificationsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListNotificationsResponse = await response.body.json() as ListNotificationsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to updateNotificationPolicy
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async updateNotificationPolicyWithHttpInfo(response: ResponseContext): Promise<HttpInfo<NotificationPolicy >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: NotificationPolicy = ObjectSerializer.deserialize(
        await response.body.json(),
        "NotificationPolicy", ""
      ) as NotificationPolicy;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Notification policy wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: NotificationPolicy = await response.body.json() as NotificationPolicy;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
