import { Stack } from '@mui/material';
import type {
  SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
  SnapshotPropertiesVolumePropertiesInner,
  BackupVault,
  InventoryResource,
  Snapshot,
} from '@repo/api-gw-sdk';
import { ResourceType, Provider } from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import { isDateValid } from '@/utils/dateTime';

import { SnapshotDrawerHeader } from './snapshotDrawerHeader';
import { SnapshotRow } from './snapshotRow';
import { VMSnapshotRow, type RestoreActionType } from './vmSnapshotRow';

import { Panels } from '../../panels';
import type { PanelTypes } from '../../panelsMap';

interface RestoreSnapshotBarProps {
  vaults: BackupVault[];
  snapshots: Snapshot[];
  selectedDate?: Date;
  entity: InventoryResource;
  onClose: () => void;
}

const RestorePanels: Partial<
  Record<
    ResourceType,
    'RestoreS3Wizard' | 'RestoreRDSWizard' | 'RestoreMongoAtlasWizard'
  >
> = {
  [ResourceType.AwsS3]: Panels.RestoreS3Wizard,
  [ResourceType.AzureStorageAccount]: Panels.RestoreS3Wizard,
  [ResourceType.AwsRds]: Panels.RestoreRDSWizard,
  [ResourceType.AtlasMongodbCluster]: Panels.RestoreMongoAtlasWizard,
};

export const RestoreSnapshotDrawer = (props: RestoreSnapshotBarProps) => {
  const { vaults, snapshots, selectedDate, entity, onClose } = props;
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const [selectedVault, setSelectedVault] = useState<BackupVault | undefined>();
  const [filteredVaults, setFilteredVaults] = useState<BackupVault[]>([]);
  const [selectedDateSnapshots, setSelectedDateSnapshots] = useState<
    Snapshot[]
  >([]);
  const [selectedSnapshot, setSelectedSnapshot] = React.useState<string>('');

  const restorePanel = RestorePanels[entity.resourceType];

  useEffect(() => {
    const dateSnapshots = selectedDate
      ? (snapshots ?? [])
          .filter((e) => {
            const date = isDateValid(e.pointInTime)
              ? e.pointInTime
              : e.createTime;

            return date.toDateString() === selectedDate?.toDateString();
          })
          .sort((a, b) => b.createTime.getTime() - a.createTime.getTime())
      : [];
    setSelectedDateSnapshots(dateSnapshots);
    setSelectedSnapshot(dateSnapshots[0]?.id || '');
  }, [selectedDate, snapshots]);

  useEffect(() => {
    const filtered = vaults?.filter((v) => {
      const vaultSnapshots = selectedDateSnapshots.filter(
        (s) => s.vaultId === v.id
      );
      return vaultSnapshots.length > 0;
    });
    setFilteredVaults(filtered);
    setSelectedVault(filtered?.[0]);
  }, [vaults, selectedDateSnapshots]);

  const snapshotsByVault = selectedDateSnapshots.filter(
    (snap) => snap.vaultId === selectedVault?.id
  );

  if (snapshotsByVault.length <= 0 || !selectedVault) {
    return null;
  }

  return (
    <Stack
      data-testid='restore-snapshot-drawer'
      className='w-full'
      direction='column'
      boxShadow='0px 10px 30px 0px rgba(0, 0, 0, 0.2)'
    >
      <Stack className='w-full' direction='column'>
        <SnapshotDrawerHeader
          selectedDate={selectedDate}
          vaults={filteredVaults}
          setSelectedVault={setSelectedVault}
          onClose={onClose}
        />
        <Stack direction='column' alignItems='center' width='100%'>
          {snapshotsByVault.map((snap) => {
            if (restorePanel) {
              return (
                <SnapshotRow
                  key={snap.id}
                  snap={snap}
                  vault={selectedVault}
                  isSelected={selectedSnapshot === snap.id}
                  onSelect={() => setSelectedSnapshot(snap.id)}
                  resource={entity}
                  snapshotSelection={snapshotsByVault.length > 1}
                  onRestore={() => {
                    setComponent({
                      panel: restorePanel,
                      props: {
                        resource: entity,
                        snapshot: snap,
                      },
                    });
                  }}
                />
              );
            }

            if (
              entity.resourceType === ResourceType.AwsEc2 ||
              entity.resourceType === ResourceType.AzureVirtualMachine
            ) {
              return (
                <VMSnapshotRow
                  resource={entity}
                  key={snap.id}
                  isSelected={selectedSnapshot === snap.id}
                  snap={snap}
                  vault={selectedVault}
                  onSelect={() =>
                    setSelectedSnapshot(
                      selectedSnapshot === snap.id ? '' : snap.id
                    )
                  }
                  snapshotSelection={snapshotsByVault.length > 1}
                  onRestore={(vols, actionType) => {
                    if (vols.length === 0) {
                      return;
                    }

                    if (entity.cloudProvider === Provider.Aws) {
                      return onAWSRestore(
                        vols.filter((x) => 'availabilityZone' in x),
                        actionType,
                        setComponent,
                        snap,
                        entity
                      );
                    }

                    if (entity.cloudProvider === Provider.Azure) {
                      return onAzureRestore(
                        vols.filter((x) => 'diskTier' in x),
                        actionType,
                        setComponent,
                        snap,
                        entity
                      );
                    }
                  }}
                />
              );
            }
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

const onAWSRestore = (
  vols: SnapshotPropertiesVolumePropertiesInner[],
  actionType: RestoreActionType,
  setComponent: (component: PanelTypes) => void,
  snap: Snapshot,
  entity: InventoryResource
) => {
  switch (actionType) {
    case 'full-instance':
      setComponent({
        panel: Panels.RestoreEc2InstanceWizard,
        props: {
          snapshotId: snap.id,
          resourceId: entity.id,
          volumes: vols,
          sourceRegion: vols[0].region,
          resourceProperties: snap.resourceProperties,
        },
      });
      break;
    case 'convert-image':
      setComponent({
        panel: Panels.ConvertToAMIWizard,
        props: {
          snapshotId: snap.id,
          resourceId: entity.id,
          volumes: vols,
          sourceRegion: vols[0].region,
          resourceProperties: snap.resourceProperties,
        },
      });
      break;
    case 'volume':
    case 'convert-snapshot':
      setComponent({
        panel: Panels.RestoreVolumeWizard,
        props: {
          snapshotId: snap.id,
          instanceId: entity.id,
          providerVolumeId: vols[0].volumeName,
          volumeRegion: vols[0].region,
          actionType,
        },
      });
  }
};

const onAzureRestore = (
  vols: SnapshotPropertiesAzurePropertiesDiskPropertiesInner[],
  actionType: RestoreActionType,
  setComponent: (component: PanelTypes) => void,
  snap: Snapshot,
  entity: InventoryResource
) => {
  switch (actionType) {
    case 'full-instance':
      setComponent({
        panel: Panels.RestoreAzureVMWizard,
        props: {
          snapshot: snap,
          resource: entity,
        },
      });
      break;
    case 'volume':
      setComponent({
        panel: Panels.RestoreAzureDiskWizard,
        props: {
          snapshot: snap,
          resource: entity,
          disk: vols[0],
        },
      });
  }
};
