import { Box } from '@mui/material';

import { useWorkspace } from '@/contexts/useWorkspace';

import { PanelWrapper } from '../panels/panelWrapper';

export const FiltersPanelWrapper = ({
  title,
  subtitle,
  onBack,
  children,
}: React.PropsWithChildren<{
  title: string;
  subtitle: string | undefined;
  onBack?: () => void;
}>) => {
  const { leftPanel } = useWorkspace();
  const { setIsOpen } = leftPanel;
  const closePanel = () => setIsOpen(false);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'var(--mui-palette-background-paper)'
            : 'rgb(255,255,255)',
        borderRadius: '16px',
        margin: '40px',
        height: 'calc(100% - 80px)',
      })}
    >
      <PanelWrapper
        header={{
          title,
          subtitle,
          onClose: closePanel,
          onBack,
          alignTitles: true,
          hideBorder: true,
        }}
      >
        {children}
      </PanelWrapper>
    </Box>
  );
};
