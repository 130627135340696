/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotPropertiesAzureProperties } from '../models/SnapshotPropertiesAzureProperties';
import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';

/**
* Details about the backed-up resource at the time the snapshot was taken.
*/
export class SnapshotProperties {
  /**
  * Instance type.
  */
  'instanceType'?: string;
  /**
  * ID of the subnet the instance is in.
  */
  'subnetId'?: string;
  /**
  * IDs of security groups associated with the resource.
  */
  'securityGroupIds'?: Array<string>;
  /**
  * The resource\'s instance profile.
  */
  'instanceProfileName'?: string;
  /**
  * Resource tags as key-value pairs. 
  */
  'tags'?: { [key: string]: string; } | null;
  'azureProperties'?: SnapshotPropertiesAzureProperties;
  'volumeProperties'?: Array<SnapshotPropertiesVolumePropertiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "instanceType",
      "baseName": "instanceType",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetId",
      "baseName": "subnetId",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroupIds",
      "baseName": "securityGroupIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "instanceProfileName",
      "baseName": "instanceProfileName",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "azureProperties",
      "baseName": "azureProperties",
      "type": "SnapshotPropertiesAzureProperties",
      "format": ""
    },
    {
      "name": "volumeProperties",
      "baseName": "volumeProperties",
      "type": "Array<SnapshotPropertiesVolumePropertiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

