import type { InventoryResource } from '@repo/api-gw-sdk';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { useState } from 'react';

import {
  accountId,
  backupRegions,
  id,
  lastBackup,
  resourceId,
  sourceRegion,
  subnets,
  tags,
  vaults,
  backupStorageSize,
  vpc,
  dateDiscovered,
  createdTime,
  resourceGroupName,
  cloudProvider,
} from '@/components/queryBuilder/properties';
import { Table } from '@/components/table';
import { useColumns } from '@/components/table/hooks';
import { useDAL } from '@/data/dal';
import { type Condition } from '@/types/advanceFilter';

import InventoryMultiInstanceMenu from './InventoryMultiInstanceMenu';
import { inventoryListTableColumns } from './inventoryListTableColumns';

interface InventoryListTableProps {
  openFilters?: boolean;
  showFiltersBar: boolean;
  enableColumnSelection: boolean;
  enableFilters: boolean;
  sorting: SortingState;
  selectedItems?: InventoryResource[];
  pagination: PaginationState;
  query?: Condition;
  disableSelection?: boolean;
  onSortingChange: (sortField: string, desc: boolean) => void;
  onPaginationChange: (value: PaginationState) => void;
  onRowSelectionChange?: (
    entities: InventoryResource[],
    onRowContentChange?: () => Promise<void>
  ) => void;
}

export const InventoryListTable = (props: InventoryListTableProps) => {
  const dal = useDAL();
  const [selectedEntities, setSelectedEntities] = useState<InventoryResource[]>(
    []
  );

  const {
    body,
    isLoading,
    mutate: mutateAllInventory,
  } = dal.inventory.list(props.pagination, props.sorting[0], props.query);

  const columns = useColumns<InventoryResource>(inventoryListTableColumns);

  const onRowSelectionChange = (
    entities: InventoryResource[],
    onRowContentChange?: () => Promise<void>
  ) => {
    setSelectedEntities(entities);
    props.onRowSelectionChange?.(entities, onRowContentChange);
  };

  return (
    <Table
      enableFilters={props.enableFilters}
      entityLabel='resources'
      disableSelection={props.disableSelection}
      openFilters={props.openFilters}
      showFiltersBar={props.showFiltersBar}
      data={body?.resources || []}
      testId='inventory-table'
      isMultiSelect={true}
      multiSelectActionButton={
        <InventoryMultiInstanceMenu entities={selectedEntities} />
      }
      columns={columns}
      hiddenColumns={[
        id.name,
        tags.name,
        resourceGroupName.name,
        accountId.name,
        resourceId.name,
        vpc.name,
        subnets.name,
        lastBackup.name,
        sourceRegion.name,
        backupRegions.name,
        vaults.name,
        backupStorageSize.name,
        dateDiscovered.name,
        createdTime.name,
        cloudProvider.name,
      ]}
      stickyHeader
      // pinnedColumns={[backupStatus.name, resourceName.name]}
      sorting={props.sorting}
      isLoading={isLoading}
      onSortingChanged={(state) =>
        props.onSortingChange(state[0].id, state[0].desc)
      }
      onRowSelectionChange={onRowSelectionChange}
      onRowContentChange={async () => {
        await mutateAllInventory();
      }}
      pagination={{
        ...props.pagination,
        pageSizesOptions: [25, 50, 100],
        recordsCount: body?.pagination.recordsCount,
        onChange: props.onPaginationChange,
      }}
      enableColumnSelection={props.enableColumnSelection}
      selectedItems={props.selectedItems}
    />
  );
};

interface InventoryListProps {
  query?: Condition;
  disableSelection?: boolean;
  onRowSelectionChange?: (entities: InventoryResource[]) => void;
}

export const InventoryList = (props: InventoryListProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: id.name, desc: false },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  return (
    <InventoryListTable
      query={props.query}
      disableSelection={props.disableSelection}
      enableColumnSelection
      enableFilters={false}
      showFiltersBar={false}
      sorting={sorting}
      pagination={pagination}
      onPaginationChange={setPagination}
      onSortingChange={(id, desc) => setSorting([{ id, desc }])}
    />
  );
};
