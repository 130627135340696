import {
  type Condition,
  JobsApi,
  type Configuration,
  CreateBackupJobApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort } from '@tanstack/react-table';

import type { HttpClient } from './httpClient';

const baseUrl = '/jobs'; // amir - need to change?

export const jobs = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const backupJobsApi = new JobsApi(config);
  const createBackupApi = new CreateBackupJobApi(config);
  const restoreJobsApi = new JobsApi(config);
  return {
    backup: {
      create: (resourceId: string, vaultId: string, retentionDays: number) => {
        return createBackupApi.createBackupJob(currentProjectId, {
          resourceId: resourceId,
          retentionDays: retentionDays,
          vaultId: vaultId,
        });
      },
      list: (
        pageIndex: number,
        pageSize: number,
        sorting: ColumnSort,
        columnFilters: Condition | undefined
      ) =>
        httpClient.execute(
          [
            baseUrl,
            '/backup',
            pageIndex,
            pageSize,
            sorting,
            columnFilters,
            currentProjectId,
          ],
          () =>
            backupJobsApi.listBackupJobsInternal(
              currentProjectId,
              pageIndex,
              pageSize,
              `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
              columnFilters ? JSON.stringify(columnFilters) : undefined
            )
        ),
    },
    restore: {
      list: (
        pageIndex: number,
        pageSize: number,
        sorting: ColumnSort,
        columnFilters: Condition | undefined
      ) =>
        httpClient.execute(
          [
            baseUrl,
            '/restore',
            pageIndex,
            pageSize,
            sorting,
            columnFilters,
            currentProjectId,
          ],
          () =>
            restoreJobsApi.listRestoreJobsInternal(
              currentProjectId,
              pageIndex,
              pageSize,
              `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
              columnFilters ? JSON.stringify(columnFilters) : undefined
            )
        ),
    },
  };
};
