import { IconButton, Stack, Tooltip } from '@mui/material';
import {
  type InventoryResource,
  SnapshotStatus,
  type Snapshot,
} from '@repo/api-gw-sdk';

import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useRoles } from '@/contexts/useRoles';
import type { BackupVault } from '@/data/vaults/backupVault';

import { BackingUpTooltip } from './backingUpTooltip';

interface SnapshotActionsGroupProps {
  snapshot: Snapshot;
  vault: BackupVault | undefined;
  isVisible: boolean;
  volume?: string;
  onRestore: () => void;
  convertSnapshot?: {
    title: string;
    onConvertSnapshot: () => void;
  };
  resource: InventoryResource;
}

export const SnapshotActionsGroup = (props: SnapshotActionsGroupProps) => {
  const { snapshot, isVisible, resource, convertSnapshot, onRestore } = props;
  const { isAuthorizedResource } = useRoles();
  const { showPendingSnapshots } = useFeatureFlags();

  return (
    <Stack direction='row' alignItems='center' className='flex justify-end'>
      {showPendingSnapshots && snapshot.status === SnapshotStatus.Pending && (
        <BackingUpTooltip />
      )}
      {snapshot.status === SnapshotStatus.Completed && isVisible && (
        <>
          {convertSnapshot && (
            <Tooltip title={convertSnapshot.title}>
              <div>
                <IconButton
                  data-testid='convert-button'
                  disabled={
                    !isAuthorizedResource('create:restore_resource', resource)
                  }
                  onClick={convertSnapshot.onConvertSnapshot}
                  className={'mr-[10px]'}
                >
                  <i className='material-symbols-repeat-rounded text-[20px] text-primary' />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <ButtonWithIcon
            text='Restore'
            disabled={
              !isAuthorizedResource('create:restore_resource', resource)
            }
            variant='outlined'
            onClick={onRestore}
            iconClass='material-symbols-settings-backup-restore-rounded'
          />
        </>
      )}
    </Stack>
  );
};
