/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class S3RestoreDestinationS3Bucket {
  /**
  * Name of an existing bucket to restore query results to. Must be in the same region as the snapshot\'s vault. 
  */
  'bucketName': string;
  /**
  * Prefix to prepend to the restore path. If omitted, query results are restored to the bucket root. 
  */
  'prefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "bucketName",
      "baseName": "bucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "prefix",
      "baseName": "prefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3RestoreDestinationS3Bucket.attributeTypeMap;
  }

  public constructor() {
  }
}

