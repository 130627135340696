import { Box, Container, Divider, IconButton, Typography } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { ActionsBar, ActionsToolBar } from '@/components/shared/actionsBar';
import { AuthorizationTooltip } from '@/components/tooltips/authorizationTooltip';
import {
  InventoryResourceActionsLevels,
  type ResourceAction,
} from '@/data/inventory/actions';
import { isDateValid } from '@/utils/dateTime';
import { DATE_TIME_FORMAT, dayjs } from '@/utils/dayjs';

interface InstanceActionsBarProps {
  actions: Partial<Record<InventoryResourceActionsLevels, ResourceAction[]>>;
  entity: InventoryResource;
}

export default function InstanceActionsBar(props: InstanceActionsBarProps) {
  const { entity, actions } = props;

  let lastBackup = entity.newestSnapshotTime;
  if (!isDateValid(lastBackup)) {
    lastBackup = undefined;
  }

  const primaryActions = actions[InventoryResourceActionsLevels.MainPrimary];
  const secondaryActions =
    actions[InventoryResourceActionsLevels.MainSecondary];

  return (
    <ActionsBar
      position='static'
      className='mb-0 px-[40px] py-[16px]'
      sx={{
        borderTop: '1px solid var(--border-color)',
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <ActionsToolBar className='flex justify-between px-[0px] min-h-0'>
          <Typography>
            {isDateValid(lastBackup)
              ? `Last backed up on ${dayjs.utc(lastBackup).format(DATE_TIME_FORMAT)} UTC`
              : 'The last backup date is unknown'}
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {secondaryActions?.map((action, i) => (
              <Fragment key={action.title || action.tooltip}>
                <AuthorizationTooltip
                  isAuthorized={action.isAuthorized}
                  title={action.tooltip}
                >
                  <div className='my-auto align-middle'>
                    <IconButton
                      data-testid={action.testId}
                      onClick={() => {
                        action.execute(props.entity);
                      }}
                      size='small'
                      color='primary'
                      disabled={action.disabled?.()}
                    >
                      <i className={action.icon} />
                    </IconButton>
                  </div>
                </AuthorizationTooltip>
                {secondaryActions.length !== i + 1 && (
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    className='mx-[14px] my-auto h-[24px]'
                  />
                )}
              </Fragment>
            ))}
            {primaryActions?.map((action, i) => (
              <Fragment key={action.title || action.tooltip}>
                {i === 0 && (
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    className='mx-[14px] my-auto h-[24px]'
                  />
                )}
                <AuthorizationTooltip isAuthorized={action.isAuthorized}>
                  <span className='mr-[12px] last:mr-[0px]'>
                    <ButtonWithIcon
                      data-testid={action.testId}
                      onClick={() => action.execute(entity)}
                      variant={action.title ? 'outlined' : 'text'}
                      disabled={action.disabled?.()}
                      text={action.title ?? ''}
                      iconClass={action.icon}
                    />
                  </span>
                </AuthorizationTooltip>
              </Fragment>
            ))}
          </Box>
        </ActionsToolBar>
      </Container>
    </ActionsBar>
  );
}
