import {
  JobStatus,
  DataClass,
  BackupStatus,
  Provider,
  Environment,
  ResourceType,
} from '@repo/api-gw-sdk';
import type { StaticImageData } from 'next/image';

import { backupStatusActions, type ActionsToLevel } from './actions';

import ActiveDirectory from '../../../public/images/apps/active-directory.png';
import ActiveMQ from '../../../public/images/apps/activemq.png';
import ApacheHTTPServer from '../../../public/images/apps/apache-http.png';
import Spark from '../../../public/images/apps/apache-spark.png';
import ApacheTomcat from '../../../public/images/apps/apache-tomcat.png';
import Cassandra from '../../../public/images/apps/cassandra.png';
import DataBricks from '../../../public/images/apps/databricks.png';
import DynamoDB from '../../../public/images/apps/dynamodb.png';
import Elasticsearch from '../../../public/images/apps/elasticsearch.png';
import Grafana from '../../../public/images/apps/grafana.png';
import Hadoop from '../../../public/images/apps/hadoop.png';
import IIS from '../../../public/images/apps/iis.png';
import Jenkins from '../../../public/images/apps/jenkins.png';
import Jira from '../../../public/images/apps/jira.png';
import ApacheKafka from '../../../public/images/apps/kafka.png';
import Kibana from '../../../public/images/apps/kibana.png';
import Exchange from '../../../public/images/apps/microsoft-exchange-server.png';
import MongoDB from '../../../public/images/apps/mongodb.png';
import MySQL from '../../../public/images/apps/mysql.png';
import Nginx from '../../../public/images/apps/nginx.png';
import OracleDatabaseServer from '../../../public/images/apps/oracle.png';
import PostgreSQL from '../../../public/images/apps/postgres.png';
import Redis from '../../../public/images/apps/redis.png';
import SAPApplicationServer from '../../../public/images/apps/sap-application-server.png';
import SQLServer from '../../../public/images/apps/sql-server.png';
import Tableau from '../../../public/images/apps/tableau.png';
import Unknown from '../../../public/images/apps/unknown.png';
import AWSLogo from '../../../public/images/providers/aws.png';
import AzureLogo from '../../../public/images/providers/azure.png';
import GCPLogo from '../../../public/images/providers/gcp.png';
import UnknownLogo from '../../../public/images/providers/unknown.png';

export const ApplicationsLogos: Record<string, StaticImageData> = {
  Elasticsearch,
  Cassandra,
  MongoDB,
  MySQL,
  'Oracle Database Server': OracleDatabaseServer,
  PostgreSQL,
  Hadoop,
  ActiveMQ,
  Redis,
  'Apache Kafka': ApacheKafka,
  'Apache HTTP Server': ApacheHTTPServer,
  'Apache Tomcat': ApacheTomcat,
  DynamoDB,
  Nginx,
  Tableau,
  Grafana,
  Jenkins,
  Kibana,
  Jira,
  DataBricks,
  'SAP Hanna': SAPApplicationServer,
  'SAP Application Server': SAPApplicationServer,
  'Active Directory': ActiveDirectory,
  'Internet Information Services (IIS)': IIS,
  'Microsoft Exchange Server': Exchange,
  'Microsoft Active Directory Domain Controller': ActiveDirectory,
  Unknown,
  Spark,
  'Microsoft SQL Server': SQLServer,
  MSSQLServer: SQLServer,
};

export const ApplicationsLogosWithAliases: Record<string, StaticImageData> = {
  ...ApplicationsLogos,
  ...Object.fromEntries(
    Object.entries(ApplicationsLogos).map(([key, val]) => [
      key.toLowerCase(),
      val,
    ])
  ),
  'Oracle Database': OracleDatabaseServer,
  WebLogic: OracleDatabaseServer,
  Mongo: MongoDB,
  mongo: MongoDB,
  postgres: PostgreSQL,
  'aurora-mysql': MySQL,
  'aurora-postgres': PostgreSQL,
  Tomcat: ApacheTomcat,
  IIS,
  'Internet Information Services (IIS)': IIS,
  'SQL Server': SQLServer,
  'sqlserver-se': SQLServer,
  'sqlserver-ee': SQLServer,
  'sqlserver-ex': SQLServer,
  'sqlserver-web': SQLServer,
};

export const DatabaseEngineAliases: Record<string, string> = {
  'aurora-mysql': 'Aurora MySQL',
  'aurora-postgres': 'Aurora PostgreSQL',
  'sqlserver-se': 'SQL Server Standard Edition',
  'sqlserver-ee': 'SQL Server Enterprise Edition',
  'sqlserver-ex': 'SQL Server Express Edition',
  'sqlserver-web': 'SQL Server Web Edition',
};

const dataClasses: Record<DataClass, { title: string; description: string }> = {
  [DataClass.Pii]: {
    title: 'Personal Identifiable Information',
    description:
      'Personally identifiable information (PII) is any data that can be used to identify a person. Some examples are name, email, physical address, login information, and IP addresses.',
  },
  [DataClass.Phi]: {
    title: 'Protected Health Information',
    description:
      'Personal health information (PHI) refers to a person’s medical history and health records. Some examples are doctor’s notes, diagnoses, and family medical details.',
  },
  [DataClass.Fi]: {
    title: 'Financial Information',
    description:
      'Financial information (FI) is a subset of personally identifiable information (PII) that represents a person’s financial data. Some examples are credit card and bank information, and crypto currency addresses.',
  },
  [DataClass.DataClassUnspecified]: {
    title: 'Unspecified',
    description:
      'The data class is not specified, so it might contain sensitive information.',
  },
};

const cloudProviders: Record<
  Provider,
  { logo: StaticImageData; displayName: string }
> = {
  [Provider.Aws]: {
    displayName: 'AWS',
    logo: AWSLogo,
  },
  [Provider.Azure]: {
    displayName: 'Azure',
    logo: AzureLogo,
  },
  [Provider.Gcp]: {
    displayName: 'GCP',
    logo: GCPLogo,
  },
  [Provider.MongoAtlas]: {
    displayName: 'Mongo Atlas',
    logo: MongoDB,
  },
  [Provider.ProviderUnspecified]: {
    displayName: 'Provider Unspecified',
    logo: UnknownLogo,
  },
};

const resourceTypes: Record<
  ResourceType,
  { displayName: string; provider: Provider }
> = {
  // GCP
  [ResourceType.AzureCloudSql]: {
    displayName: 'CloudSql',
    provider: Provider.Gcp,
  },
  [ResourceType.GcpComputeEngine]: {
    displayName: 'GCE',
    provider: Provider.Gcp,
  },

  // AWS
  [ResourceType.AwsEc2]: {
    displayName: 'EC2',
    provider: Provider.Aws,
  },
  [ResourceType.AwsEfs]: {
    displayName: 'EFS',
    provider: Provider.Aws,
  },
  [ResourceType.AwsRds]: {
    displayName: 'RDS',
    provider: Provider.Aws,
  },
  [ResourceType.AwsS3]: {
    displayName: 'S3',
    provider: Provider.Aws,
  },

  // ATLAS
  [ResourceType.AtlasMongodbCluster]: {
    displayName: 'Atlas Cluster',
    provider: Provider.MongoAtlas,
  },

  // AZURE
  [ResourceType.AzureStorageAccount]: {
    displayName: 'Storage Account',
    provider: Provider.Azure,
  },
  [ResourceType.AzureDisk]: {
    displayName: 'Disk',
    provider: Provider.Azure,
  },
  [ResourceType.AzureVirtualMachine]: {
    displayName: 'Virtual Machine',
    provider: Provider.Azure,
  },
  [ResourceType.AwsFileShare]: {
    displayName: 'File Share',
    provider: Provider.Azure,
  },
  [ResourceType.AzureSqlServer]: {
    displayName: 'SQL Server',
    provider: Provider.Azure,
  },
  [ResourceType.AzureSqlDatabase]: {
    displayName: 'SQL Database',
    provider: Provider.Azure,
  },
  [ResourceType.AzureMysql]: {
    displayName: 'MySQL',
    provider: Provider.Azure,
  },
  [ResourceType.AzurePostgresql]: {
    displayName: 'PostgreSQL',
    provider: Provider.Azure,
  },
  [ResourceType.ResourceTypeUnspecified]: {
    displayName: 'ResourceTypeUnspecified',
    provider: Provider.ProviderUnspecified,
  },
};

const backupStatuses: Record<
  BackupStatus,
  {
    icon: string;
    title: string;
    iconColor: string;
    backgroundColor: string;
    description: string;
    fullDescription: string;
    recommendation?: string;
    actions: ActionsToLevel;
  }
> = {
  [BackupStatus.Protected]: {
    icon: 'material-symbols-check-circle-outline-rounded',
    title: 'Protected',
    iconColor: 'var(--mui-palette-success-main)',
    backgroundColor: 'rgba(145,255,210,0.2)',
    description:
      'The resource is continuously backed up by Eon and there are no control violations.',
    fullDescription:
      'This resource continuously backed up by Eon with no active control violations.',
    actions: backupStatusActions.PROTECTED,
  },
  [BackupStatus.ViolationsDetected]: {
    icon: 'material-symbols-error-outline-rounded',
    title: 'Violations detected',
    iconColor: 'linear-gradient(180deg, #F87A21 0%, #FB956E 100%)',
    backgroundColor:
      'linear-gradient(180deg, rgba(255, 202, 186, 0.20) 0%, rgba(255, 225, 164, 0.20) 100%)',
    description:
      'Resources that violate a backup control, so they’re not compliant.',
    recommendation:
      'We recommend updating your backup policies to fix the violations.',
    fullDescription:
      "This resource violates a backup control, so it's not compliant. We recommend updating your backup policies to fix the violations.",
    actions: backupStatusActions.VIOLATIONS_DETECTED,
  },
  [BackupStatus.NotBackedUp]: {
    icon: 'material-symbols-disc-full-outline-rounded',
    title: 'Not backed up',
    iconColor: 'var(--mui-palette-error-main)',
    backgroundColor: 'rgba(240,68,101,0.2)',
    description:
      'No backup policies apply to these resources, and no one has excluded them from backups.',
    recommendation:
      'We recommend updating your backup policies to include the resources.',
    fullDescription:
      'There are no backup policies applied to this resource, and it is not manually excluded from backup. We recommend updating your backup policies to include this resource.',
    actions: backupStatusActions.NOT_BACKED_UP,
  },
  [BackupStatus.AllViolationsMuted]: {
    icon: 'material-symbols-motion-photos-paused-outline-rounded',
    title: 'All violations muted',
    iconColor: 'var(--mui-palette-success-main)',
    backgroundColor: 'rgba(145,255,210,0.2)',
    description:
      'Resources that violate at least one backup control, but someone muted all notifications.',
    fullDescription:
      'This resource violates at least one backup control, but someone muted all violations.',
    actions: backupStatusActions.ALL_VIOLATIONS_MUTED,
  },
  [BackupStatus.ExcludedFromBackup]: {
    icon: 'material-symbols-hide-source-outline-rounded',
    title: 'Excluded from backup',
    iconColor: '#b2bec1',
    backgroundColor: 'rgba(178,190,193,0.2)',
    description:
      'A team member asked Eon not to back up these resources. They won’t trigger any alerts for backup issues.',
    fullDescription:
      'A team member asked Eon not to back up this resource. It won’t trigger any alerts for backup issues.',
    actions: backupStatusActions.EXCLUDED_FROM_BACKUP,
  },
  [BackupStatus.GenericBackups]: {
    icon: 'ic-outline-brightness-1',
    title: 'Backed up by others',
    iconColor: 'var(--mui-palette-warning-main)',
    backgroundColor: 'rgba(255,198,71,0.2)',
    description:
      'Resources that are backed up, but not by Eon, so you won’t be able to explore the resource files and databases, or restore granular data.',
    recommendation:
      'We recommend updating your policies to back up these resources with Eon.',
    fullDescription:
      "This resource backed up, but not by Eon. Eon can't be used to restore data. We recommend updating your policies to back up with Eon.",
    actions: backupStatusActions.GENERIC_BACKUPS,
  },
  [BackupStatus.InitialClassification]: {
    icon: 'material-symbols-avg-pace-rounded',
    title: 'Initial classification',
    iconColor: '#008edd',
    backgroundColor: 'rgba(0,142,221,0.2)',
    description:
      'Resources that were recently discovered and are being scanned by Eon for the first time. Their statuses will be updated after the scan is done.',
    fullDescription:
      'This resource recently discovered and currently being scanned by Eon for the first time. The status will be updated after the scan is done.',
    actions: backupStatusActions.INITIAL_CLASSIFICATION,
  },
  [BackupStatus.Terminated]: {
    icon: 'material-symbols-do-not-disturb-on-outline-rounded',
    title: 'Terminated',
    iconColor: '#b2bec1',
    backgroundColor: 'rgba(178,190,193,0.2)',
    description:
      'Resources that were terminated in your source account. They still have Eon snapshots you can explore and restore.',
    fullDescription:
      'This resource was terminated in your source account. It still has Eon snapshots you can explore and restore.',
    actions: backupStatusActions.TERMINATED,
  },
  [BackupStatus.Disconnected]: {
    icon: 'material-symbols-error-med-outline-rounded',
    title: 'Disconnected',
    iconColor: '#b2bec1',
    backgroundColor: 'rgba(178,190,193,0.2)',
    description: `Resources from a source account that's no longer connected to Eon. They still have Eon snapshots and you can explore and restore.`,
    fullDescription: `This resource's source account was disconnected from Eon. It still has Eon snapshots you can explore and restore.`,
    actions: backupStatusActions.DISCONNECTED,
  },
  [BackupStatus.BackupPostureStatusUnspecified]: {
    icon: '',
    title: '',
    iconColor: '',
    backgroundColor: '',
    description: '',
    fullDescription: '',
    actions: backupStatusActions.BACKUP_POSTURE_STATUS_UNSPECIFIED,
  },
  [BackupStatus.Unsupported]: {
    icon: '',
    title: '',
    iconColor: '',
    backgroundColor: '',
    description: '',
    fullDescription: '',
    actions: backupStatusActions.UNSUPPORTED,
  },
};

export const ControlsSeverity: Record<
  string,
  { color: string; title: string }
> = {
  high: {
    title: 'High',
    color: 'var(--mui-palette-error-main)',
  },
  medium: {
    title: 'Medium',
    color: '#FF8D4C',
  },
  low: {
    title: 'Low',
    color: 'var(--mui-palette-warning-main)',
  },
  muted: {
    title: 'Muted',
    color: '#cfd9dc',
  },
};

const environments: Record<
  Environment,
  { title: string; description: string }
> = {
  [Environment.Prod]: {
    title: 'Production',
    description:
      'The resource is part of a customer-facing or external-facing production environment.',
  },
  [Environment.ProdInternal]: {
    title: 'Internal production',
    description:
      'The resource is part of an internal-only production environment, such as an EC2 instance running Jira Enterprise.',
  },
  [Environment.Stage]: {
    title: 'Non-production',
    description: `The resource isn't part of a production environment—for example, staging or dev.`,
  },
  [Environment.EnvironmentUnspecified]: {
    title: 'Unspecified',
    description:
      'The resource is part of an environment that is not specified.',
  },
};

const jobStatuses: Record<JobStatus, { title: string; color: string }> = {
  [JobStatus.JOB_COMPLETED]: {
    title: 'Completed',
    color: 'var(--mui-palette-success-main)',
  },
  [JobStatus.JOB_FAILED]: {
    title: 'Failed',
    color: 'var(--mui-palette-error-main)',
  },
  [JobStatus.JOB_PARTIAL]: {
    title: 'Partial',
    color: 'var(--mui-palette-secondary-main)',
  },
  [JobStatus.JOB_PENDING]: {
    title: 'Pending',
    color: 'var(--mui-palette-warning-main)',
  },
  [JobStatus.JOB_RUNNING]: {
    title: 'Running',
    color: 'var(--mui-palette-info-main)',
  },
  [JobStatus.JOB_UNSPECIFIED]: {
    title: 'Unspecified',
    color: 'var(--mui-palette-secondary-main)',
  },
};

export const PiiStrings: Record<string, string> = {
  ADDRESS: 'Address',
  NAME: 'Name',
  AGE: 'Age',
  DATE_OF_BIRTH: 'Date of Birth',
  GENDER: 'Gender',
  USERNAME: 'Username',
  PASSWORD: 'Password',

  PHONE_NUMBER: 'Phone Number',
  EMAIL_ADDRESS: 'Email Address',
  IP_ADDRESS: 'IP Address',
  MAC_ADDRESS: 'MAC Address',

  US_ZIP_CODE: 'US ZIP Code',
  US_SOCIAL_SECURITY_NUMBER: 'US Social Security Number',
  US_PASSPORT_NUMBER: 'US Passport Number',
  US_DRIVER_LICENSE: "US Driver's License",
  US_ITIN: 'US Individual Taxpayer Identification Number',
  US_VEHICLE_IDENTIFICATION_NUMBER: 'US Vehicle Identification Number',
  US_MEDICARE_BENEFICIARY_ID_NUMBER: 'US Medicare Beneficiary ID Number',
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US Employer Identification Number',

  US_PTIN: 'US Preparer Tax Identification Number',

  CA_PASSPORT_NUMBER: 'Canadian Passport Number',
  CA_DRIVER_LICENSE: "Canadian Driver's License",
  CA_SOCIAL_INSURANCE_NUMBER: 'Canadian Social Insurance Number',
  CA_HEALTH_SERVICE_NUMBER: 'Canadian Health Service Number',

  CREDIT_DEBIT_CARD_NUMBER: 'Credit/Debit Card Number',
  CRYPTO_CURRENCY_ADDRESS: 'Cryptocurrency Address',
  IBAN_CODE: 'IBAN Code',
  SWIFT_CODE: 'SWIFT Code',
  BANK_ACCOUNT_NUMBER: 'Bank Account Number',

  US_BANK_ROUTING_MICR: 'US Bank Routing Number (MICR)',
};

export const PiiStringTypes: Record<string, string> = {
  ADDRESS: DataClass.Pii,
  NAME: DataClass.Pii,
  AGE: DataClass.Pii,
  DATE_OF_BIRTH: DataClass.Pii,
  GENDER: DataClass.Pii,
  USERNAME: DataClass.Pii,
  PASSWORD: DataClass.Pii,

  PHONE_NUMBER: DataClass.Pii,
  EMAIL_ADDRESS: DataClass.Pii,
  IP_ADDRESS: DataClass.Pii,
  MAC_ADDRESS: DataClass.Pii,

  US_ZIP_CODE: DataClass.Pii,
  US_SOCIAL_SECURITY_NUMBER: DataClass.Pii,
  US_PASSPORT_NUMBER: DataClass.Pii,
  US_DRIVER_LICENSE: DataClass.Pii,
  US_ITIN: DataClass.Pii,
  US_VEHICLE_IDENTIFICATION_NUMBER: DataClass.Pii,
  US_MEDICARE_BENEFICIARY_ID_NUMBER: DataClass.Phi,
  US_EMPLOYER_IDENTIFICATION_NUMBER: DataClass.Pii,

  US_PTIN: DataClass.Pii,

  CA_PASSPORT_NUMBER: DataClass.Pii,
  CA_DRIVER_LICENSE: DataClass.Pii,
  CA_SOCIAL_INSURANCE_NUMBER: DataClass.Pii,
  CA_HEALTH_SERVICE_NUMBER: DataClass.Phi,

  CREDIT_DEBIT_CARD_NUMBER: DataClass.Fi,
  CRYPTO_CURRENCY_ADDRESS: DataClass.Fi,
  IBAN_CODE: DataClass.Fi,
  SWIFT_CODE: DataClass.Fi,
  BANK_ACCOUNT_NUMBER: DataClass.Fi,

  US_BANK_ROUTING_MICR: DataClass.Fi,
};

export const DataClasses = Object.fromEntries(Object.entries(dataClasses));
export const Environments = Object.fromEntries(
  Object.entries(environments).filter(
    ([key]) => key !== Environment.EnvironmentUnspecified.toString()
  )
);
export const BackupStatuses = Object.fromEntries(
  Object.entries(backupStatuses)
);
export const CloudProviders = Object.fromEntries(
  Object.entries(cloudProviders)
);
export const ResourceTypes = Object.fromEntries(Object.entries(resourceTypes));
export const JobStatuses = Object.fromEntries(Object.entries(jobStatuses));
