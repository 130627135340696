import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import type { Column } from '@tanstack/react-table';

import { useViewParameters } from '@/contexts/navigation';
import { useWorkspace } from '@/contexts/useWorkspace';
import {
  createCombineCondition,
  isCombineCondition,
  isValueCondition,
  StringOperator,
  type CombineCondition,
  type FilterProperty,
} from '@/types/advanceFilter';

import { FiltersPanelWrapper } from './filtersPanelWrapper';

import { Panels } from '../panels/panels';
import { getProperties } from '../queryBuilder/propertiesUtils';
import { PropertyGroups } from '../queryBuilder/propertyGroups';

export interface FiltersPanelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
  expandedPropertyName?: string;
}

export const FiltersPanel = ({
  columns,
  expandedPropertyName,
}: FiltersPanelProps) => {
  const { leftPanel } = useWorkspace();
  const { query, setParams } = useViewParameters();

  const combineCondition = isCombineCondition(query)
    ? query
    : createCombineCondition();

  const groupedColumns = columns
    .filter((x) => x.getCanFilter())
    .reduce<Record<string, Column<unknown>[]>>((acc, column) => {
      getProperties(column).forEach((prop) => {
        acc[prop.group] = acc[prop.group] || [];
        if (!acc[prop.group].includes(column)) {
          acc[prop.group].push(column);
        }
      });
      return acc;
    }, {});

  const renderFiltersPanel = (expandedPropertyName?: string) => {
    leftPanel.setComponent({
      panel: Panels.Filters,
      props: {
        columns,
        expandedPropertyName,
      },
    });
  };
  return (
    <FiltersPanelWrapper
      title='Filter Resources'
      subtitle='Use filters to explore resources and manage your backups'
    >
      {[''].concat(Object.keys(PropertyGroups)).map((group) => {
        const columnsList = groupedColumns[group];
        if (!columnsList?.length) {
          return;
        }

        const title = PropertyGroups[group]?.title;
        return (
          <Box key={group}>
            {title && (
              <Typography
                variant='subtitle1'
                className='px-[24px] py-[8px] my-[12px]'
                sx={{
                  backgroundColor: 'var(--mui-palette-background-tableHeader)',
                  textTransform: 'uppercase',
                }}
              >
                {title}
              </Typography>
            )}
            {columnsList.map((column) => {
              const properties = getProperties(column);
              const propertyWithComponent = properties.find(
                (property) => property?.filter?.Component
              );
              const openFullSize = propertyWithComponent?.filter?.openFullSize;
              if (!propertyWithComponent) {
                return null;
              }

              return (
                <Accordion
                  disableGutters
                  key={column.id}
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  defaultExpanded={expandedPropertyName === column.id}
                >
                  <AccordionSummary
                    data-testid={`filter-accordion-${column.id}`}
                    className='px-[24px] py-[12px] font-semibold'
                    expandIcon={
                      <i className='material-symbols-arrow-right-rounded' />
                    }
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiAccordionSummary-root': {
                        backgroundColor: 'transparent',
                      },
                      '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                      },
                    }}
                    onClick={
                      openFullSize &&
                      (() =>
                        leftPanel.setComponent({
                          panel: Panels.FiltersDrillDown,
                          props: {
                            title: openFullSize.title,
                            subtitle: openFullSize.subtitle,
                            Component: ({ filter }) => (
                              <FilterComponent
                                property={propertyWithComponent}
                                renderFiltersPanel={renderFiltersPanel}
                                filter={filter}
                                onChange={(newFilter) =>
                                  setParams((state) => ({
                                    ...state,
                                    query: newFilter,
                                  }))
                                }
                              />
                            ),
                            onBack: () => renderFiltersPanel(),
                          },
                        }))
                    }
                  >
                    <Stack
                      className='w-full'
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <span>{`${column.columnDef.header}`}</span>
                      {combineCondition.conditions.some(
                        (x) =>
                          isValueCondition(x) &&
                          properties.some(
                            (property) =>
                              x.property === property?.name &&
                              x.operator !== StringOperator.NotIn
                          )
                      ) && (
                        <Box
                          className='rounded inline-block mr-[16px] h-[8px] w-[8px]'
                          sx={{
                            backgroundColor: 'currentColor',
                          }}
                        />
                      )}
                    </Stack>
                  </AccordionSummary>
                  {!openFullSize && (
                    <AccordionDetails className='py-[12px] px-[24px]'>
                      <FilterComponent
                        property={propertyWithComponent}
                        filter={combineCondition}
                        renderFiltersPanel={renderFiltersPanel}
                        onChange={(newFilter) =>
                          setParams((state) => ({
                            ...state,
                            query: newFilter,
                          }))
                        }
                      />
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
          </Box>
        );
      })}
    </FiltersPanelWrapper>
  );
};

function FilterComponent({
  filter,
  property,
  onChange,
  renderFiltersPanel,
}: {
  filter: CombineCondition;
  property: FilterProperty | undefined;
  onChange: (filter: CombineCondition) => void;
  renderFiltersPanel: (expandedPropertyName?: string) => void;
}) {
  if (!property) {
    return null;
  }

  const Component = property.filter?.Component;

  return (
    Component && (
      <Component
        property={property}
        conditions={filter.conditions}
        data-testid={`filter-${property.name}`}
        renderFiltersPanel={renderFiltersPanel}
        onChange={(conditions) => {
          const newFilter = {
            ...filter,
            conditions,
          };

          onChange(newFilter);
        }}
      />
    )
  );
}
