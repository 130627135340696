import type { NotificationPolicy } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  upsertNotificationPolicyFlow,
  type UpsertNotificationPolicyState,
} from './upsertNotificationPolicyFlow';

interface UpsertNotificationPolicyPanelProps {
  entity: NotificationPolicy;
  onSave: (notificationPolicy: NotificationPolicy) => void;
  onAbort: () => void;
}

export function UpsertNotificationPolicyPanel(
  props: UpsertNotificationPolicyPanelProps
) {
  const save = (state: UpsertNotificationPolicyState) => {
    props.onSave(state);
    return true;
  };

  return (
    <Wizard<UpsertNotificationPolicyState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={
        props.entity.id
          ? upsertNotificationPolicyFlow.next!
          : [upsertNotificationPolicyFlow]
      }
      initialState={{
        ...props.entity,
      }}
      title={
        props.entity.id
          ? `Edit a Notification policy`
          : `Create a Notification policy`
      }
    />
  );
}
