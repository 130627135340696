import {
  UpdateAccountUIPreferencesApi,
  type Account,
  type Preferences,
  GetAccountUIPreferencesApi,
  GetViewerUIPreferencesApi,
  UpdateViewerUIPreferencesApi,
  type Configuration,
} from '@repo/api-gw-sdk';

import type { RestoreGenericStorageProps } from '@/components/restore/bucketSelection';

import type { HttpClient } from './httpClient';

import type BackupVaultPreferences from '../vaults/backupVaultPrefrences';

interface ClientPreferences<K extends PreferencesKey> extends Preferences {
  value?: PreferencesMap[K];
}

export const preferences = (httpClient: HttpClient, config: Configuration) => {
  let version = 0;
  const getAccountUIPreferencesApi = new GetAccountUIPreferencesApi(config);
  const updateAccountUIPreferencesApi = new UpdateAccountUIPreferencesApi(
    config
  );
  const getViewerUIPreferencesApi = new GetViewerUIPreferencesApi(config);
  const updateViewerUIPreferencesApi = new UpdateViewerUIPreferencesApi(config);

  return {
    getUserPref: <K extends keyof PreferencesMap>(key: K) =>
      httpClient.execute(
        ['get-user-pref', key, version],
        (): Promise<ClientPreferences<K>> =>
          getViewerUIPreferencesApi
            .getViewerUIPreferences(key)
            .catch((x) => x.code !== 404 && console.error(x)) as Promise<
            ClientPreferences<K>
          >
      ),
    getAccountPref: <K extends keyof PreferencesMap>(key: K) =>
      httpClient.execute(
        ['get-account-pref', key, version],
        (): Promise<ClientPreferences<K>> =>
          getAccountUIPreferencesApi
            .getAccountUIPreferences(key)
            .catch((x) => x.code !== 404 && console.error(x)) as Promise<
            ClientPreferences<K>
          >
      ),
    updateAccountPref: <K extends PreferencesKey>(
      key: K,
      value: PreferencesMap[K]
    ) => {
      version++;
      return updateAccountUIPreferencesApi.updateAccountUIPreferences(
        key,
        value
      );
    },
    updateUserPref: <K extends PreferencesKey>(
      key: K,
      value: PreferencesMap[K]
    ) => {
      version++;
      return updateViewerUIPreferencesApi.updateViewerUIPreferences(key, value);
    },
  };
};

export enum PreferencesKey {
  BackupVault = 'backup-vaults',
  CurrentProject = 'current-project',
  RestoreBucket = 'restore-bucket',
  RestoreStorageAccount = 'restore-storage-account',
  QueryTarget = 'query-target',
  Preferences = 'ui-preferences',
}

export interface PreferencesMap {
  [PreferencesKey.BackupVault]: BackupVaultPreferences;
  [PreferencesKey.CurrentProject]: { projectId: string };
  [PreferencesKey.RestoreBucket]: { bucketName: string };
  [PreferencesKey.RestoreStorageAccount]: {
    accountName: string;
    container: string;
    resourceGroup: string;
  };
  [PreferencesKey.QueryTarget]: Record<
    string,
    {
      account: Account;
      bucket: RestoreGenericStorageProps;
    }
  >;
  [PreferencesKey.Preferences]: { theme: 'dark' | 'light' };
}
