import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import type { VolumeSettings } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import {
  convertFromBytes,
  convertToBytes,
  fileSizeFormatter,
} from '@/utils/fileSizeFormatter';

import { Loader } from '../layout/loading';

export const volumePresets: Record<
  string,
  {
    displayName: string;
    minSize: number;
    maxSize: number;
    minIOPS?: number;
    maxIOPS?: number;
    minThroughput?: number;
    maxThroughput?: number;
  }
> = {
  gp3: {
    displayName: 'General Purpose SSD (gp3)',
    minSize: 1,
    maxSize: 16384,
    minIOPS: 3000,
    maxIOPS: 16000,
    minThroughput: 125,
    maxThroughput: 1000,
  },
  // IOPS is auto-calculated according to size, but it's not editable
  // Throughput is not applicable
  gp2: {
    displayName: 'General Purpose SSD (gp2)',
    minSize: 1,
    maxSize: 16384,
  },
  io1: {
    // IOPS max value changes according to size
    // Throughput is not applicable
    displayName: 'Provisioned IOPS SSD (io1)',
    minSize: 4,
    maxSize: 16384,
    minIOPS: 100,
    maxIOPS: 400,
  },
  io2: {
    // IOPS max value changes according to size
    // Throughput is not applicable
    displayName: 'Provisioned IOPS SSD (io2)',
    minSize: 4,
    maxSize: 65536,
    minIOPS: 100,
    maxIOPS: 400,
  },
  sc1: {
    // IOPS is not applicable
    // Throughput is auto-calculated according to size, but it's not editable
    displayName: 'Cold HDD (sc1)',
    minSize: 125,
    maxSize: 16384,
  },
  st1: {
    // IOPS is not applicable
    // Throughput is auto-calculated according to size, but it's not editable
    displayName: 'Throughput Optimized HDD (st1)',
    minSize: 125,
    maxSize: 16384,
  },
  standard: {
    // IOPS is not applicable
    // Throughput is not applicable
    displayName: 'Magnetic (standard)',
    minSize: 1,
    maxSize: 1024,
  },
};

interface Props {
  initialSettings?: VolumeSettings;
  settings?: VolumeSettings;
  onChange: (params: VolumeSettings) => void;
  keepOriginalSettings?: boolean;
  setKeepOriginalSettings: (value: boolean) => void;
}

export const VolumeSettingsSection = ({
  initialSettings,
  onChange,
  settings: _settings,
  keepOriginalSettings,
  setKeepOriginalSettings,
}: Props) => {
  if (!initialSettings) {
    return <Loader />;
  }
  const settings = _settings || initialSettings;
  const preset = volumePresets[settings.type] || volumePresets.gp3;

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography className='font-semibold' variant='body1'>
          Keep original settings
        </Typography>
        <Switch
          size='small'
          checked={keepOriginalSettings}
          onChange={() => {
            setKeepOriginalSettings(!keepOriginalSettings);
          }}
        />
      </Box>
      {keepOriginalSettings && (
        <Box className='mt-[10px]'>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Type
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {volumePresets[initialSettings.type].displayName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Size
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {fileSizeFormatter(initialSettings.sizeBytes)}
                </TableCell>
              </TableRow>
              {initialSettings.iops && (
                <TableRow>
                  <TableCell
                    className='border-0 px-0'
                    sx={{ color: 'var(--mui-palette-text-disabled)' }}
                  >
                    IOPS
                  </TableCell>
                  <TableCell
                    className='border-0 px-0'
                    sx={{ color: 'var(--mui-palette-text-disabled)' }}
                  >
                    {initialSettings.iops}
                  </TableCell>
                </TableRow>
              )}
              {initialSettings.throughput && (
                <TableRow>
                  <TableCell
                    className='border-0 px-0'
                    sx={{ color: 'var(--mui-palette-text-disabled)' }}
                  >
                    Throughput
                  </TableCell>
                  <TableCell
                    className='border-0 px-0'
                    sx={{ color: 'var(--mui-palette-text-disabled)' }}
                  >{`${initialSettings.throughput} MB/s`}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      )}
      {!keepOriginalSettings && (
        <Box className='mt-[20px] w-1/2 pr-[18px]'>
          <FormControl size='small' className='w-full mb-[24px]'>
            <FormLabel>Volume Type</FormLabel>
            <Select
              value={settings.type}
              onChange={(e) => {
                const newType = e.target.value;
                const newPreset = volumePresets[newType];

                onChange({
                  type: newType,
                  sizeBytes: settings.sizeBytes,
                  iops:
                    newPreset.minIOPS && newPreset.maxIOPS
                      ? getValueInRange(
                          settings.iops || initialSettings.iops,
                          newPreset.minIOPS,
                          newPreset.maxIOPS
                        )
                      : undefined,
                  throughput:
                    newPreset.minThroughput && newPreset.maxThroughput
                      ? getValueInRange(
                          settings.throughput || initialSettings.throughput,
                          newPreset.minThroughput,
                          newPreset.maxThroughput
                        )
                      : undefined,
                });
              }}
            >
              {Object.entries(volumePresets).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mb-[24px]'>
            <SliderWithInput
              id='size'
              title='Size (GB)'
              value={convertFromBytes(settings.sizeBytes, 'GB')}
              minValue={convertFromBytes(initialSettings.sizeBytes, 'GB')}
              maxValue={preset.maxSize}
              onChange={(value) =>
                onChange({
                  ...settings,
                  sizeBytes: convertToBytes(value, 'GB'),
                })
              }
            />
          </FormControl>
          {preset.minIOPS && preset.maxIOPS && (
            <FormControl size='small' className='w-full mb-[24px]'>
              <SliderWithInput
                id='iops'
                title='IOPS'
                value={settings.iops}
                minValue={preset.minIOPS}
                maxValue={preset.maxIOPS}
                onChange={(value) => onChange({ ...settings, iops: value })}
              />
            </FormControl>
          )}
          {preset.minThroughput && preset.maxThroughput && (
            <FormControl size='small' className='w-full mb-[24px]'>
              <SliderWithInput
                id='throughput'
                title='Throughput (MB/s)'
                value={settings.throughput}
                minValue={preset.minThroughput}
                maxValue={preset.maxThroughput}
                onChange={(value) =>
                  onChange({ ...settings, throughput: value })
                }
              />
            </FormControl>
          )}
        </Box>
      )}
    </Box>
  );
};

const getValueInRange = (
  value: number | string | undefined,
  min: number,
  max: number
) => {
  let v = +(value || '');
  v = isNaN(v) ? min : v;
  return Math.min(Math.max(v, min), max);
};

const SliderWithInput = ({
  id,
  title,
  value,
  minValue,
  maxValue,
  onChange,
}: {
  id: string;
  title: string;
  value: number | undefined;
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
}) => {
  const safeValue = getValueInRange(value, minValue, maxValue);
  const [textValue, setTextValue] = useState(`${safeValue}`);

  useEffect(() => {
    setTextValue(`${safeValue}`);
  }, [safeValue]);

  return (
    <FormControl size='small' className='w-full'>
      <FormLabel id={id}>{title}</FormLabel>
      <Box className='flex'>
        <Slider
          size='small'
          className='flex-grow mr-[24px]'
          min={minValue}
          max={maxValue}
          value={safeValue}
          onChange={(e, newValue) => onChange(newValue as number)}
          aria-labelledby={id}
          marks={[
            { value: minValue, label: minValue.toString() },
            {
              value: maxValue,
              label: (
                <span
                  style={{
                    transform: 'translateX(-100%)',
                    position: 'absolute',
                  }}
                >
                  {maxValue.toString()}
                </span>
              ),
            },
          ]}
        />
        <TextField
          sx={{ width: '120px' }}
          className='mt-[12px]'
          value={textValue}
          size='small'
          onChange={(event) => setTextValue(event.target.value)}
          onBlur={() => {
            const newValue = getValueInRange(textValue, minValue, maxValue);
            onChange(newValue);
            setTextValue(`${newValue}`);
          }}
          inputProps={{
            min: minValue,
            max: maxValue,
            type: 'tel',
            'aria-labelledby': id,
          }}
        />
      </Box>
    </FormControl>
  );
};
