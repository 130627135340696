/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DataClass } from '../models/DataClass';
import { Environment } from '../models/Environment';
import { Provider } from '../models/Provider';
import { ResourceType } from '../models/ResourceType';

export class DBResourceMetadata {
  /**
  * The ID of the resource
  */
  'resourceId'?: string;
  /**
  * The name of the resource
  */
  'resourceName'?: string;
  'resourceType'?: ResourceType;
  /**
  * The group of the resource
  */
  'resourceGroup'?: string;
  'cloudProvider'?: Provider;
  'environment'?: Environment;
  /**
  * The tags associated with the resource
  */
  'tags'?: { [key: string]: string; };
  /**
  * The apps associated with the resource
  */
  'apps'?: Array<string>;
  /**
  * Data classes.
  */
  'dataClassification'?: Array<DataClass>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "environment",
      "baseName": "environment",
      "type": "Environment",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "apps",
      "baseName": "apps",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "dataClassification",
      "baseName": "dataClassification",
      "type": "Array<DataClass>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DBResourceMetadata.attributeTypeMap;
  }

  public constructor() {
  }
}



