import type {
  RestoreVolumeToEbsSnapshotRequest,
  Configuration,
  ConvertResourceSnapshotsRequest,
  ConvertOtherSnapshotsOnDemandRequest,
} from '@repo/api-gw-sdk';
import {
  ConvertOtherSnapshotsOnDemandApi,
  ConvertResourceSnapshotsApi,
  SnapshotsApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const convert = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const snapshotsApi = new SnapshotsApi(config);
  const convertResourceSnapshotsAPI = new ConvertResourceSnapshotsApi(config);
  const convertOtherSnapshotsOnDemandApi = new ConvertOtherSnapshotsOnDemandApi(
    config
  );

  return {
    volume: (
      resourceId: string,
      volumeId: string,
      payload: RestoreVolumeToEbsSnapshotRequest
    ) =>
      snapshotsApi.restoreToEbsSnapshot(
        currentProjectId,
        resourceId,
        volumeId,
        payload
      ),
    resourceSnapshots: (
      resourceId: string,
      convertResourceSnapshotsRequest: ConvertResourceSnapshotsRequest
    ) =>
      convertResourceSnapshotsAPI.convertResourceSnapshots(
        currentProjectId,
        resourceId,
        convertResourceSnapshotsRequest
      ),
    otherSnapshotOnDemand: (
      resourceId: string,
      convertOtherSnapshotsOnDemandRequest: ConvertOtherSnapshotsOnDemandRequest
    ) =>
      convertOtherSnapshotsOnDemandApi.convertOtherSnapshotsOnDemand(
        currentProjectId,
        resourceId,
        convertOtherSnapshotsOnDemandRequest
      ),
  };
};
