/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotPropertiesAzurePropertiesDiskPropertiesInner } from '../models/SnapshotPropertiesAzurePropertiesDiskPropertiesInner';

export class SnapshotPropertiesAzureProperties {
  /**
  * The name of the resource
  */
  'name'?: string;
  /**
  * The size of the virtual machine
  */
  'vmSize'?: string;
  'diskProperties'?: Array<SnapshotPropertiesAzurePropertiesDiskPropertiesInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "vmSize",
      "baseName": "vmSize",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskProperties",
      "baseName": "diskProperties",
      "type": "Array<SnapshotPropertiesAzurePropertiesDiskPropertiesInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotPropertiesAzureProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

