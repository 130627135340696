import type { BackupPolicy } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  EditBackupPolicyFlow,
  type BackupPolicyEditState,
} from './BackupPolicyCreationFlow';

interface BackupPolicyPanelProps {
  entity: BackupPolicy;
  onSave: (policy: BackupPolicy) => void;
  onAbort: () => void;
}

export default function BackupPolicyPanel(props: BackupPolicyPanelProps) {
  const save = (state: BackupPolicyEditState) => {
    props.onSave({
      ...props.entity,
      name: state.name,
      condition: state.condition,
      schedules: state.schedules ?? [],
      include: state.include ?? [],
      exclude: state.exclude ?? [],
    });

    return true;
  };

  return (
    <Wizard<BackupPolicyEditState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={
        props.entity.id ? EditBackupPolicyFlow.next! : [EditBackupPolicyFlow]
      }
      initialState={{
        id: props.entity.id,
        name: props.entity.name,
        condition: props.entity.condition,
        schedules: props.entity.schedules,
        include: props.entity.include,
        exclude: props.entity.exclude,
      }}
      title={props.entity.id ? 'Edit Backup Policy' : 'Create Backup Policy'}
      stepperLabels={['Condition', 'Resource list', 'Frequency and retention']}
    />
  );
}
