import type { InventoryResource } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';

import { RestartAutoClassificationPanel } from './resumeAutoClassificationPanel';

export function RestartAutomaticDataClassClassification({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const dal = useDAL();

  const onSave = () => {
    void Promise.all(
      entities.map((entity) =>
        dal.inventory.dataClasses.deleteOverride(entity.id).then(() => {
          entity.classifications = entity.classifications || {};
          entity.classifications.dataClassesDetails =
            entity.classifications.dataClassesDetails || {};
          entity.classifications.dataClassesDetails.isOverridden = false;
        })
      )
    ).finally(onClose);
  };
  if (!open) {
    return null;
  }

  return (
    <RestartAutoClassificationPanel
      open={open}
      onClose={onClose}
      onSave={onSave}
      title='Resume auto-classification for data classes'
      description='Data classes will be automatically detected, and your overridden data classes will be removed. This could change the applied backup policies and controls.'
    />
  );
}
