/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreJobsFilterConditions } from '../models/RestoreJobsFilterConditions';
import { SortRestoreJobBy } from '../models/SortRestoreJobBy';

export class ListRestoreJobsRequest {
  'filters'?: RestoreJobsFilterConditions;
  'sorts'?: Array<SortRestoreJobBy>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "filters",
      "baseName": "filters",
      "type": "RestoreJobsFilterConditions",
      "format": ""
    },
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "Array<SortRestoreJobBy>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListRestoreJobsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

