import { Box } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { BackupStatus } from '@repo/api-gw-sdk';

import type {
  ResourceAction,
  InventoryResourceActionsLevels,
} from '@/data/inventory/actions';

import { BackupStatusCard } from './backupStatusCard';
import { ViolationsDetails } from './violationsDetails';

interface BackupStatusProps {
  backupStatus: BackupStatus;
  onEntityChange?: () => Promise<void>;
  resourceId: string;
  actions: Partial<Record<InventoryResourceActionsLevels, ResourceAction[]>>;
  entity: InventoryResource;
}

export default function BackupStatusComponent(props: BackupStatusProps) {
  const { backupStatus, onEntityChange, resourceId, actions, entity } = props;
  const shouldShowViolations =
    backupStatus === BackupStatus.ViolationsDetected ||
    backupStatus === BackupStatus.AllViolationsMuted;

  return (
    <Box className='px-[40px]'>
      <BackupStatusCard
        backupStatus={backupStatus}
        actions={actions}
        entity={entity}
      />
      {!!shouldShowViolations && (
        <ViolationsDetails
          resourceId={resourceId}
          onEntityChange={onEntityChange}
          entity={entity}
        />
      )}
    </Box>
  );
}
