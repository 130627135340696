import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { Condition, DataAccessRule, Role } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionMenuItemType } from '@/@core/components/option-menu/types';

import { dataAccessConditionSupportedProperties } from './utils';

import { Transition } from '../animations/transitions';
import { ConditionEditor } from '../conditions/ConditionEditor';
import { Icon } from '../shared/icon';

export const CustomRoleConditionEditorDialog = ({
  open,
  onSave,
  onCancel,
  dataAccessRule,
  dataAccessRules,
}: {
  dataAccessRule: DataAccessRule | undefined;
  dataAccessRules: Role['dataAccessRules'];
  open: boolean;
  onCancel: () => void;
  onSave: (name: string, condition: Condition, isInclusive: boolean) => void;
}) => {
  const [name, setName] = useState('');
  const [isInclusive, setInclusive] = useState(false);
  const [condition, setCondition] = useState<Condition | undefined>(undefined);

  useEffect(() => {
    setName(dataAccessRule?.name || '');
    setInclusive(dataAccessRule ? dataAccessRule.isInclusive : false);
    setCondition(
      dataAccessRule ? structuredClone(dataAccessRule.condition) : undefined
    );
  }, [open, dataAccessRule]);

  const saveEnabled =
    name &&
    condition &&
    (dataAccessRule
      ? dataAccessRule.name === name
        ? true
        : !dataAccessRules?.[name]
      : !dataAccessRules?.[name]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '100%',
          height: '85%',
          margin: 0,
          alignSelf: 'end',
        },
      }}
    >
      <Stack className='h-full'>
        <Stack
          gap='12px'
          className='py-[16px] px-[24px] overflow-y-auto'
          flexGrow={1}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography className='font-semibold'>
              Create Access Condition
            </Typography>
            <Icon
              onClick={onCancel}
              iconClass='material-symbols-close-rounded'
            />
          </Stack>
          <Stack
            direction='row'
            className='mt-[24px] mx-[16px]'
            flexGrow={1}
            gap='40px'
          >
            <Stack flexGrow={1} gap='24px'>
              <Stack direction='row' alignItems='center' gap='8px'>
                <InclusiveOptionsMenu
                  isInclusive={isInclusive}
                  setInclusive={setInclusive}
                />
                <Typography variant='body1'>
                  access to resources that meet these conditions:
                </Typography>
              </Stack>

              <ConditionEditor
                options={{
                  supportGrouping: true,
                  supportedProperties: dataAccessConditionSupportedProperties,
                }}
                condition={condition}
                onChange={(condition) => setCondition(condition)}
              />
            </Stack>
            <Divider orientation='vertical' flexItem />
            <Stack className='w-[380px]'>
              <FormControl size='small'>
                <FormLabel>Name</FormLabel>
                <TextField
                  placeholder='Example: No production with PII'
                  className='my-[12px]'
                  size='small'
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='justify-end items-center px-[40px] py-[16px]'
          sx={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)' }}
        >
          <Button variant='outlined' className='mr-[12px]' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={!saveEnabled}
            onClick={() => onSave(name, condition!, isInclusive)}
          >
            {dataAccessRule ? 'Save Changes' : 'Create Condition'}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

const optionsBuilder = (selectedIndex: number): OptionMenuItemType[] =>
  [
    { text: 'Block', testId: 'custom-role-block' },
    { text: 'Allow', testId: 'custom-role-allow' },
  ].map((x, index) => ({
    menuItemProps: {
      sx: (theme) => ({
        ...(selectedIndex === index
          ? {
              backgroundColor: 'var(--mui-palette-primary-main)',
              color: 'var(--mui-palette-background-default)',
            }
          : {}),

        '&:hover': {
          backgroundColor:
            selectedIndex === index
              ? 'var(--mui-palette-primary-main)'
              : theme.palette.mode === 'dark'
                ? '#1A2027'
                : '#E7F0F3',
        },
      }),
    },
    text: (
      <Typography className='pr-[8px]' color='currentColor'>
        {x.text}
      </Typography>
    ),
    testId: x.testId,
  }));

const InclusiveOptionsMenu = ({
  isInclusive,
  setInclusive,
}: {
  isInclusive: boolean;
  setInclusive: (value: boolean) => void;
}) => {
  const options = optionsBuilder(isInclusive ? 1 : 0);
  return (
    <OptionMenu
      iconButtonProps={{
        className: 'p-0',
        sx: {
          '&:hover': {
            backgroundColor: isInclusive
              ? 'rgba(145,255,210,0.2) !important'
              : 'rgba(240,68,101,0.2) !important',
          },
        },
      }}
      icon={
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          className='w-[73px] p-[4px] pl-[12px] rounded-[4px]'
          color={
            isInclusive
              ? 'var(--mui-palette-success-main)'
              : 'var(--mui-palette-error-main)'
          }
          sx={{
            backgroundColor: isInclusive
              ? 'rgba(145,255,210,0.2)'
              : 'rgba(240,68,101,0.2)',
          }}
        >
          <Typography variant='body1' color='currentColor'>
            {isInclusive ? 'Allow' : 'Block'}
          </Typography>
          <i className='material-symbols-arrow-drop-down-rounded text-xl' />
        </Stack>
      }
      options={options}
      onOptionSelected={(option) => {
        if (typeof option !== 'string') {
          const chosenIndex = options.indexOf(option);
          setInclusive(chosenIndex === 1);
        }
      }}
    />
  );
};
