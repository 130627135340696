import { NotificationStatus, NotificationType } from '@repo/api-gw-sdk';

const notificationTypes: Record<NotificationType, { title: string }> = {
  [NotificationType.NotificationTypeUnknown]: {
    title: 'Unknown',
  },
  [NotificationType.BackupJobFailed]: {
    title: 'Backup Job Failed',
  },
  [NotificationType.RestoreJobFailed]: {
    title: 'Restore Job Failed',
  },
  [NotificationType.RestoreJobSucceeded]: {
    title: 'Restore Job Succeeded',
  },
  [NotificationType.BackupPolicyApplied]: {
    title: 'Backup Policy Applied',
  },
  [NotificationType.BackupPolicyUnapplied]: {
    title: 'Backup Policy Detached',
  },
  [NotificationType.BackupPolicyCreated]: {
    title: 'Backup Policy Created',
  },
  [NotificationType.BackupPolicyUpdated]: {
    title: 'Backup Policy Updated',
  },
  [NotificationType.BackupPolicyDeleted]: {
    title: 'Backup Policy Deleted',
  },
  [NotificationType.BackupVaultCreated]: {
    title: 'Backup Vault Created',
  },
  [NotificationType.BackupVaultDeleted]: {
    title: 'Backup Vault Deleted',
  },
  [NotificationType.CloudAccountCreated]: {
    title: 'Cloud Account Created',
  },
  [NotificationType.CloudAccountUpdated]: {
    title: 'Cloud Account Updated',
  },
  [NotificationType.CloudAccountDeleted]: {
    title: 'Cloud Account Deleted',
  },
};

export const NotificationTypes = Object.fromEntries(
  Object.entries(notificationTypes)
);

const notificationStatuses: Record<NotificationStatus, { title: string }> = {
  [NotificationStatus.Statussent]: {
    title: 'Sent',
  },
  [NotificationStatus.Statusfailed]: {
    title: 'Failed',
  },
  [NotificationStatus.Statuspending]: {
    title: 'Pending',
  },
};

export const NotificationStatuses = Object.fromEntries(
  Object.entries(notificationStatuses)
);

export const supportedNotificationTypes = [
  NotificationType.RestoreJobSucceeded,
  NotificationType.RestoreJobFailed,
  NotificationType.BackupPolicyApplied,
  NotificationType.BackupPolicyUnapplied,
  NotificationType.BackupPolicyCreated,
  NotificationType.BackupPolicyUpdated,
  NotificationType.BackupPolicyDeleted,
  NotificationType.BackupVaultCreated,
  NotificationType.CloudAccountCreated,
  NotificationType.CloudAccountDeleted,
];
