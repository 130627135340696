/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { JobExecutionDetails } from '../models/JobExecutionDetails';

export class BackupJob {
  'jobExecutionDetails': JobExecutionDetails;
  /**
  * The ID of the backup policy
  */
  'backupPolicyId'?: string;
  /**
  * The name of the backup policy
  */
  'backupPolicyName'?: string;
  /**
  * The IDs of the cloud snapshots which the backup job used - relevant when backing up from a cloud snapshot
  */
  'cloudSnapshotIds'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "jobExecutionDetails",
      "baseName": "jobExecutionDetails",
      "type": "JobExecutionDetails",
      "format": ""
    },
    {
      "name": "backupPolicyId",
      "baseName": "backupPolicyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "backupPolicyName",
      "baseName": "backupPolicyName",
      "type": "string",
      "format": ""
    },
    {
      "name": "cloudSnapshotIds",
      "baseName": "cloudSnapshotIds",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return BackupJob.attributeTypeMap;
  }

  public constructor() {
  }
}

