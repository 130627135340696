import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { deserializeSearchParams, serialize } from './serialization';

const historyEvents = ['popstate'];

export const useBrowserHistory = ({
  params,
  setParams,
}: {
  params: Record<string, unknown>;
  setParams: (state: Record<string, unknown>) => void;
}) => {
  const pathname = usePathname();
  const firstRender = useRef(true);

  useEffect(function () {
    const onBrowserHistoryChange = () => {
      const newParams = deserializeSearchParams(
        new URLSearchParams(window.location.search)
      );
      setParams(newParams);
    };
    const abortController = new AbortController();
    historyEvents.forEach((event) =>
      window.addEventListener(event, onBrowserHistoryChange, {
        signal: abortController.signal,
      })
    );

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newUrl = Object.keys(params).length
      ? `${pathname}?${new URLSearchParams(serialize(params)).toString()}`
      : pathname;

    if (newUrl !== window.location.pathname + window.location.search) {
      if (firstRender.current) {
        // when setting the history for the first time, we are replacing the current url
        // so we won't have another back history due to adding the default params.
        window.history.replaceState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      } else {
        window.history.pushState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      }
    }
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, params]);
};
