/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupPolicyAppliesNotificationArguments } from '../models/BackupPolicyAppliesNotificationArguments';
import { BackupPolicyNotificationArguments } from '../models/BackupPolicyNotificationArguments';
import { CloudAccountNotificationArguments } from '../models/CloudAccountNotificationArguments';
import { NotificationArgumentsRestoreJobFailed } from '../models/NotificationArgumentsRestoreJobFailed';
import { NotificationArgumentsRestoreJobSucceeded } from '../models/NotificationArgumentsRestoreJobSucceeded';
import { VaultNotificationArguments } from '../models/VaultNotificationArguments';

export class NotificationArguments {
  'restoreJobFailed'?: NotificationArgumentsRestoreJobFailed;
  'restoreJobSucceeded'?: NotificationArgumentsRestoreJobSucceeded;
  'cloudAccountCreated'?: CloudAccountNotificationArguments;
  'cloudAccountUpdated'?: CloudAccountNotificationArguments;
  'cloudAccountDeleted'?: CloudAccountNotificationArguments;
  'backupPolicyApplied'?: BackupPolicyAppliesNotificationArguments;
  'backupPolicyUnApplied'?: BackupPolicyAppliesNotificationArguments;
  'backupPolicyCreated'?: BackupPolicyNotificationArguments;
  'backupPolicyUpdated'?: BackupPolicyNotificationArguments;
  'backupPolicyDeleted'?: BackupPolicyNotificationArguments;
  'backupVaultCreated'?: VaultNotificationArguments;
  'backupVaultDeleted'?: VaultNotificationArguments;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreJobFailed",
      "baseName": "restoreJobFailed",
      "type": "NotificationArgumentsRestoreJobFailed",
      "format": ""
    },
    {
      "name": "restoreJobSucceeded",
      "baseName": "restoreJobSucceeded",
      "type": "NotificationArgumentsRestoreJobSucceeded",
      "format": ""
    },
    {
      "name": "cloudAccountCreated",
      "baseName": "cloudAccountCreated",
      "type": "CloudAccountNotificationArguments",
      "format": ""
    },
    {
      "name": "cloudAccountUpdated",
      "baseName": "cloudAccountUpdated",
      "type": "CloudAccountNotificationArguments",
      "format": ""
    },
    {
      "name": "cloudAccountDeleted",
      "baseName": "cloudAccountDeleted",
      "type": "CloudAccountNotificationArguments",
      "format": ""
    },
    {
      "name": "backupPolicyApplied",
      "baseName": "backupPolicyApplied",
      "type": "BackupPolicyAppliesNotificationArguments",
      "format": ""
    },
    {
      "name": "backupPolicyUnApplied",
      "baseName": "backupPolicyUnApplied",
      "type": "BackupPolicyAppliesNotificationArguments",
      "format": ""
    },
    {
      "name": "backupPolicyCreated",
      "baseName": "backupPolicyCreated",
      "type": "BackupPolicyNotificationArguments",
      "format": ""
    },
    {
      "name": "backupPolicyUpdated",
      "baseName": "backupPolicyUpdated",
      "type": "BackupPolicyNotificationArguments",
      "format": ""
    },
    {
      "name": "backupPolicyDeleted",
      "baseName": "backupPolicyDeleted",
      "type": "BackupPolicyNotificationArguments",
      "format": ""
    },
    {
      "name": "backupVaultCreated",
      "baseName": "backupVaultCreated",
      "type": "VaultNotificationArguments",
      "format": ""
    },
    {
      "name": "backupVaultDeleted",
      "baseName": "backupVaultDeleted",
      "type": "VaultNotificationArguments",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationArguments.attributeTypeMap;
  }

  public constructor() {
  }
}

