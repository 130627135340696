import { CardContent, Stack } from '@mui/material';
import { useState } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark } from './shared';
import { SingleAzureDiskConfig } from './singleAzureDiskConfig';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreAzureVMState } from '../restoreAzureVMWizard';

export const AzureVmDiskConfiguration = (
  props: StepProps<RestoreAzureVMState>
) => {
  const dal = useDAL();
  const [error, setError] = useState<string | undefined>();

  const startRestore = () => {
    // TODO: Add validation against azure that configuration is correct and valid
    setError(undefined);
    const tags = {
      ...(props.wizardState.keepOriginalTags
        ? props.wizardState.initialTags
        : props.wizardState.tags),
      'eon:restore': 'true',
    };
    const diskSettings = props.wizardState.disksState.map((disk) => ({
      diskId: disk.diskSettings.diskId,
      diskName: disk.diskSettings.diskName,
      diskType: disk.diskSettings.diskType,
      diskTier: disk.diskSettings.diskTier,
      diskSizeBytes: disk.diskSettings.diskSize,
      diskHyperVGeneration: disk.diskSettings.diskHyperVGeneration,
      restoreAccountId: props.wizardState.restoreAccount!.id,
      location: props.wizardState.location!,
      resourceGroup: props.wizardState.resourceGroup!,
      tags: {
        ...(props.wizardState.keepOriginalTags ? disk.initialTags : disk.tags),
        'eon:restore': 'true',
      },
    }));

    void dal.restore.azureVm
      .restore(props.wizardState.resourceId, props.wizardState.snapshotId, {
        restoreAccountId: props.wizardState.restoreAccount!.id,
        location: props.wizardState.location!,
        vmName: props.wizardState.vmName ?? '',
        resourceGroup: props.wizardState.resourceGroup!,
        networkInterface: props.wizardState.networkInterface ?? '',
        vmSize: props.wizardState.vmSize ?? '',
        tags,
        // TODO: Implement UI for these fields using azureVMTypes and azureDiskTypes
        diskSettings,
      })
      .then(() => {
        props.abort();
        props.onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore vm: ${e.message}`);
      });

    props.abort();
    props.onSuccess('Restore job has started!');
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={
        error ? (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        ) : (
          <EonTagRemark />
        )
      }
      stepperLabels={props.stepperLabels}
      stepperIndex={2}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={() =>
        props.wizardState.vmName !== undefined &&
        props.wizardState.resourceGroup !== undefined &&
        props.wizardState.location !== undefined &&
        props.wizardState.networkInterface !== undefined &&
        props.wizardState.vmSize !== undefined
      }
      onNextClick={startRestore}
    >
      <CardContent className='p-0'>
        {props.wizardState.disksState.map((disk) => {
          const initialDiskSettings = props.wizardState.initialDiskStates.find(
            (d) => disk.diskSettings.diskId === d.diskSettings.diskId
          );
          if (
            !initialDiskSettings ||
            !props.wizardState.restoreAccount ||
            !props.wizardState.resourceGroup ||
            !props.wizardState.location
          ) {
            return null;
          }
          return (
            <SingleAzureDiskConfig
              key={disk.diskSettings.diskId}
              diskId={disk.diskSettings.diskId}
              accountID={props.wizardState.restoreAccount.id}
              location={props.wizardState.location}
              initialSettings={initialDiskSettings}
              showTags={disk.keepOriginalTags}
              diskSettings={disk}
              setWizardState={props.setWizardState}
            />
          );
        })}
      </CardContent>
    </StepContainer>
  );
};
