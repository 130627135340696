import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ListBackupJobsInternal200Response } from '../models/ListBackupJobsInternal200Response';
import { ListBackupJobsRequest } from '../models/ListBackupJobsRequest';
import { ListBackupJobsResponse } from '../models/ListBackupJobsResponse';
import { ListRestoreJobsInternal200Response } from '../models/ListRestoreJobsInternal200Response';
import { ListRestoreJobsRequest } from '../models/ListRestoreJobsRequest';
import { ListRestoreJobsResponse } from '../models/ListRestoreJobsResponse';

/**
 * no description
 */
export class JobsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * List Backup Jobs
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listBackupJobsRequest The request body for filtering jobs
   */
  public async listBackupJobs(projectId: string, pageToken?: string, pageSize?: number, listBackupJobsRequest?: ListBackupJobsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listBackupJobs", "projectId");
    }





    // Path Params
    const localVarPath = '/v1/projects/{projectId}/backup-jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listBackupJobsRequest, "ListBackupJobsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Backup Jobs Internal
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listBackupJobsInternal(projectId: string, pageIndex: number, pageSize: number, sort?: string, filter?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listBackupJobsInternal", "projectId");
    }


    // verify required parameter 'pageIndex' is not null or undefined
    if (pageIndex === null || pageIndex === undefined) {
      throw new RequiredError("JobsApi", "listBackupJobsInternal", "pageIndex");
    }


    // verify required parameter 'pageSize' is not null or undefined
    if (pageSize === null || pageSize === undefined) {
      throw new RequiredError("JobsApi", "listBackupJobsInternal", "pageSize");
    }




    // Path Params
    const localVarPath = '/projects/{projectId}/backup-jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageIndex !== undefined) {
      requestContext.setQueryParam("pageIndex", ObjectSerializer.serialize(pageIndex, "number", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }

    // Query Params
    if (sort !== undefined) {
      requestContext.setQueryParam("sort", ObjectSerializer.serialize(sort, "string", ""));
    }

    // Query Params
    if (filter !== undefined) {
      requestContext.setQueryParam("filter", ObjectSerializer.serialize(filter, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Restore Jobs
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listRestoreJobsRequest The request body for filtering jobs
   */
  public async listRestoreJobs(projectId: string, pageToken?: string, pageSize?: number, listRestoreJobsRequest?: ListRestoreJobsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listRestoreJobs", "projectId");
    }





    // Path Params
    const localVarPath = '/v1/projects/{projectId}/restore-jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listRestoreJobsRequest, "ListRestoreJobsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Restore Jobs Internal
   * @param projectId The project ID
   * @param pageIndex The page index
   * @param pageSize The numbers of items per page
   * @param sort The sort order, field:direction
   * @param filter The filter to apply
   */
  public async listRestoreJobsInternal(projectId: string, pageIndex: number, pageSize: number, sort?: string, filter?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listRestoreJobsInternal", "projectId");
    }


    // verify required parameter 'pageIndex' is not null or undefined
    if (pageIndex === null || pageIndex === undefined) {
      throw new RequiredError("JobsApi", "listRestoreJobsInternal", "pageIndex");
    }


    // verify required parameter 'pageSize' is not null or undefined
    if (pageSize === null || pageSize === undefined) {
      throw new RequiredError("JobsApi", "listRestoreJobsInternal", "pageSize");
    }




    // Path Params
    const localVarPath = '/projects/{projectId}/restore-jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageIndex !== undefined) {
      requestContext.setQueryParam("pageIndex", ObjectSerializer.serialize(pageIndex, "number", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }

    // Query Params
    if (sort !== undefined) {
      requestContext.setQueryParam("sort", ObjectSerializer.serialize(sort, "string", ""));
    }

    // Query Params
    if (filter !== undefined) {
      requestContext.setQueryParam("filter", ObjectSerializer.serialize(filter, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Scan Jobs
   * @param projectId The project ID
   * @param pageToken The token for the next page
   * @param pageSize The numbers of items per page
   * @param listBackupJobsRequest The request body for filtering jobs
   */
  public async listScanJobs(projectId: string, pageToken?: string, pageSize?: number, listBackupJobsRequest?: ListBackupJobsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("JobsApi", "listScanJobs", "projectId");
    }





    // Path Params
    const localVarPath = '/projects/{projectId}/list-scan-jobs'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listBackupJobsRequest, "ListBackupJobsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class JobsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listBackupJobs
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listBackupJobsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListBackupJobsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListBackupJobsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListBackupJobsResponse", ""
      ) as ListBackupJobsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListBackupJobsResponse = await response.body.json() as ListBackupJobsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listBackupJobsInternal
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listBackupJobsInternalWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListBackupJobsInternal200Response >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListBackupJobsInternal200Response = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListBackupJobsInternal200Response", ""
      ) as ListBackupJobsInternal200Response;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListBackupJobsInternal200Response = await response.body.json() as ListBackupJobsInternal200Response;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listRestoreJobs
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listRestoreJobsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListRestoreJobsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListRestoreJobsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListRestoreJobsResponse", ""
      ) as ListRestoreJobsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListRestoreJobsResponse = await response.body.json() as ListRestoreJobsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listRestoreJobsInternal
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listRestoreJobsInternalWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListRestoreJobsInternal200Response >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListRestoreJobsInternal200Response = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListRestoreJobsInternal200Response", ""
      ) as ListRestoreJobsInternal200Response;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListRestoreJobsInternal200Response = await response.body.json() as ListRestoreJobsInternal200Response;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listScanJobs
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listScanJobsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListBackupJobsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListBackupJobsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListBackupJobsResponse", ""
      ) as ListBackupJobsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListBackupJobsResponse = await response.body.json() as ListBackupJobsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
