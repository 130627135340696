import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

import { RoleAccordionBody } from '@/app/(account)/global-settings/shared/scopeAccordion';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import type { UpsertApiCredentialsState } from './upsertApiCredentialsFlow';

import { RenameEntityName } from '../wizard/renameEntityName';

const STUB_PROJECT = 'STUB_PROJECT';

export const ApiCredentialsScopesSelection = (
  props: StepProps<UpsertApiCredentialsState>
) => {
  const dal = useDAL();
  const { body: projectsBody } = dal.projects.list();

  return (
    <>
      <StepContainer
        navigationComponent={
          <RenameEntityName
            label='Credentials name'
            name={props.wizardState.givenName}
            onChange={(givenName) =>
              props.setWizardState((state) => ({ ...state, givenName }))
            }
          />
        }
        hideStepper
        onBackClick={props.back}
        canGoNext={() =>
          !!props.wizardState.roleId && props.wizardState.projects.length > 0
        }
        nextButtonText={
          props.wizardState.clientId ? 'Save Changes' : 'Create Credentials'
        }
        onNextClick={() => {
          if (props.wizardState.clientId) {
            void dal.apiCredentials
              .update(props.wizardState.clientId, props.wizardState)
              .then(props.finish);
          } else {
            void dal.apiCredentials.create(props.wizardState).then((x) => {
              props.setWizardState((state) => ({
                ...state,
                clientId: x.apiCredentialsProperties.clientId,
                clientSecret: x.clientSecret,
              }));
              props.setNextStep(props.currentStep.next?.[0]);
            });
          }
        }}
      >
        <Stack
          className='px-[60px]'
          gap='24px'
          color={'var(--mui-palette-text-primary)'}
        >
          <Typography className='font-semibold'>Projects</Typography>
          <Typography className='max-w-[580px]'>
            Choose the projects you want the credentials to access.
          </Typography>
          <Stack direction='row' gap='24px'>
            {projectsBody?.projects.map((project) => (
              <FormControlLabel
                className='m-0'
                key={project.id}
                control={
                  <Checkbox
                    checked={props.wizardState.projects.includes(project.id)}
                    onChange={(event, checked) =>
                      props.setWizardState((state) => ({
                        ...state,
                        projects: checked
                          ? [...state.projects, project.id]
                          : state.projects.filter((x) => x !== project.id),
                      }))
                    }
                  />
                }
                label={
                  <Typography className='ml-[4px]'>{project.name}</Typography>
                }
              />
            ))}
          </Stack>
          <Divider sx={{ mx: '-100px', my: '12px' }} />
          <Typography className='font-semibold cursor-default'>
            Credentials role
          </Typography>
          <Typography className='max-w-[580px] mb-[12px]'>{`When code uses your API credentials to call the Eon API, it will have this role's permissions. Keep your account secure by choosing a role with just the permissions needed.`}</Typography>
          <RoleAccordionBody
            roleToProjectsMappings={
              props.wizardState.roleId
                ? {
                    [props.wizardState.roleId]: {
                      ids: [STUB_PROJECT],
                    },
                  }
                : {}
            }
            onChange={(roleToProjectsMappings) =>
              props.setWizardState((state) => ({
                ...state,
                roleId: Object.keys(roleToProjectsMappings)[0],
              }))
            }
            projectId={STUB_PROJECT}
            singleSelect
          />
        </Stack>
      </StepContainer>
    </>
  );
};
