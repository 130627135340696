import { Stack } from '@mui/material';
import type { Provider } from '@repo/api-gw-sdk';
import React from 'react';

import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useWorkspace } from '@/contexts/useWorkspace';
import { CloudProviders } from '@/data/inventory/data';

import { Panels } from '../panels/panels';
import { OtherSnapshotsMultipleDots } from '../snapshots/otherSnapshotsMultipleDots';

interface ConvertToEonSnapshotsBarProps {
  numOfOtherSnapshots: number;
  cloudProvider: Provider;
  resourceId: string;
}

export default function ConvertToEonSnapshotsBar(
  props: ConvertToEonSnapshotsBarProps
) {
  const { rightPanel } = useWorkspace();
  const { numOfOtherSnapshots, cloudProvider, resourceId } = props;
  const { convertToEonSnapshot } = useFeatureFlags();

  const closeRightPanel = () => {
    rightPanel.setIsOpen(false);
  };

  return (
    convertToEonSnapshot && (
      <Stack
        alignItems='center'
        justifyContent='space-between'
        direction='row'
        paddingX='40px'
        paddingY='12px'
        className='bg-[var(--mui-palette-warning-lightOpacity)] shadow-[0px_10px_30px_0px_rgba(0,0,0,0.20)]'
      >
        <Stack direction='row' alignItems='center' gap='12px'>
          <OtherSnapshotsMultipleDots />
          {`${numOfOtherSnapshots} ${CloudProviders[cloudProvider]?.displayName} snapshots in the source account can be converted.`}
        </Stack>
        <ButtonWithIcon
          variant='outlined'
          iconClass='material-symbols-join-right-rounded'
          text={'Convert to Eon'}
          onClick={() => {
            rightPanel.setComponent({
              panel: Panels.ConvertToMultipleEonSnapshots,
              props: {
                onClose: closeRightPanel,
                back: () =>
                  rightPanel.setComponent({
                    panel: Panels.InventoryInstance,
                    props: { onClose: closeRightPanel, id: resourceId },
                  }),
              },
              // props: {
              //   entity: {
              //     id: '',
              //     givenName: '',
              //     clientId: '',
              //     roleId: '',
              //     projects: [projectsBody.projects[0].id],
              //   },
              //   onSave: onSave,
              //   onAbort: closePanel,
              // },
            });
            rightPanel.setIsOpen(true);
          }}
        />
      </Stack>
    )
  );
}
