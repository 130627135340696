import { Stack } from '@mui/material';
import type { RestoreFilesRequestDestination } from '@repo/api-gw-sdk';
import { Provider, ResourceType } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { Loader } from '@/components/layout/loading';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { StepContainer } from '../../wizard/StepContainer';
import { BucketSelectionStep } from '../bucketSelectionStep';
import type { RestoreS3State } from '../restoreS3Wizard';
import { StorageAccountSelectionStep } from '../storageAccountSelection';

export const S3Configuration = (props: StepProps<RestoreS3State>) => {
  const {
    wizardState: stepState,
    stepperLabels,
    abort,
    back,
    onSuccess,
  } = props;
  const {
    S3,
    StorageAccount,
    restoreMode,
    prefix,
    restoreAccount,
    snapshotId,
    resourceId,
  } = stepState;
  const dal = useDAL();

  const [error, setError] = useState<string | undefined>();
  const { body } = dal.cloudAccounts.restore.list();

  if (!body) {
    return <Loader />;
  }

  const restoringToAws = restoreAccount?.cloudProvider === Provider.Aws;
  const restoringToAzure = restoreAccount?.cloudProvider === Provider.Azure;

  const onNextClick = () => {
    setError(undefined);
    const override = restoreMode !== 'select';
    const destination: RestoreFilesRequestDestination =
      {} as unknown as RestoreFilesRequestDestination;

    if (restoringToAws) {
      const bucketName = (override ? S3.BucketOverride : S3.Bucket) ?? '';
      // for saving the bucket name between different restores
      void dal.preferences.updateUserPref(PreferencesKey.RestoreBucket, {
        bucketName,
      });
      destination.s3Bucket = { bucketName, prefix };
    } else if (restoringToAzure) {
      const accountName =
        (override
          ? StorageAccount.AccountNameOverride
          : StorageAccount.AccountName) ?? '';
      const resourceGroup =
        (override
          ? StorageAccount.ResourceGroupOverride
          : StorageAccount.ResourceGroup) ?? '';
      const container =
        (override
          ? StorageAccount.ContainerOverride
          : StorageAccount.Container) ?? '';
      void dal.preferences.updateUserPref(
        PreferencesKey.RestoreStorageAccount,
        { accountName, container, resourceGroup }
      );
      destination.storageAccount = {
        name: accountName,
        container,
        resourceGroup,
        prefix,
      };
    }

    void dal.restore.s3Buckets
      .restore(resourceId, snapshotId, {
        restoreAccountId: restoreAccount!.id,
        destination,
      })
      .then(() => {
        abort();
        onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore bucket: ${e.message}`);
      });
  };

  const allowUsingSourceContainer =
    props.wizardState.resourceType === ResourceType.AzureStorageAccount;

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={back}
      canGoNext={() => {
        if (restoringToAws) {
          return Boolean(
            restoreMode === 'select' ? S3.Bucket : S3.BucketOverride
          );
        } else if (restoringToAzure) {
          return Boolean(
            restoreMode === 'select'
              ? StorageAccount.AccountName &&
                  (StorageAccount.Container || allowUsingSourceContainer)
              : StorageAccount.AccountNameOverride &&
                  StorageAccount.ContainerOverride
          );
        }
        return false;
      }}
      navigationComponent={
        error && (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        )
      }
      onNextClick={onNextClick}
    >
      {restoringToAws && <BucketSelectionStep {...props} />}
      {restoringToAzure && (
        <StorageAccountSelectionStep
          {...props}
          allowUsingSourceContainer={allowUsingSourceContainer}
        />
      )}
    </StepContainer>
  );
};
