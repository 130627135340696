export const PropertyGroups: Record<string, { title: string }> = {
  primary: {
    title: 'Properties',
  },
  classifications: {
    title: 'Classifications',
  },
  'source-location': {
    title: 'Source location',
  },
  'job-details': {
    title: 'Job details',
  },
  'resource-details': {
    title: 'Resource details',
  },
  'restore-details': {
    title: 'Restore details',
  },
  'backup-details': {
    title: 'Backup details',
  },
};
