import { Stack, Typography } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';

import { Icon } from '@/components/shared/icon';
import VaultsTabs from '@/components/vaults/vaultTabs';
import { DATE_FORMAT, dayjs } from '@/utils/dayjs';

interface SnapshotBarHeaderProps {
  selectedDate?: Date;
  setSelectedVault: (vault: BackupVault) => void;
  vaults: BackupVault[];
  onClose: () => void;
}

export const SnapshotDrawerHeader = (props: SnapshotBarHeaderProps) => {
  const { selectedDate, vaults, setSelectedVault, onClose } = props;

  return (
    <Stack direction='column'>
      <Stack
        direction='row'
        paddingTop='16px'
        paddingX='24px'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography className='font-semibold'>
          {selectedDate ? dayjs.utc(selectedDate).format(DATE_FORMAT) : ''}
        </Typography>
        <Icon
          data-testid='snapshots-restore-bar-close-button'
          onClick={onClose}
          iconClass='material-symbols-close-rounded'
        />
      </Stack>
      <VaultsTabs onVaultChange={setSelectedVault} vaults={vaults} />
    </Stack>
  );
};
