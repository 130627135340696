/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DailySnapshotData {
  /**
  * The date the snapshots creation was initiated
  */
  'startedAt'?: Date;
  /**
  * The number of snapshots created on the date
  */
  'count'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "count",
      "baseName": "count",
      "type": "number",
      "format": "int64"
    }  ];

  static getAttributeTypeMap() {
    return DailySnapshotData.attributeTypeMap;
  }

  public constructor() {
  }
}

