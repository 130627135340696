/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreAzureDiskInput } from '../models/RestoreAzureDiskInput';

export class RestoreAzureVMInput {
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region to restore the vm to
  */
  'location': string;
  /**
  * Name of the resource group to restore the vm to
  */
  'resourceGroup': string;
  /**
  * The name of the VM to restore
  */
  'vmName': string;
  /**
  * The size of the VM to restore
  */
  'vmSize': string;
  /**
  * The Name of the network interface to use
  */
  'networkInterface': string;
  /**
  * Optional tags to apply to the output instance
  */
  'tags'?: { [key: string]: string; };
  'diskSettings': Array<RestoreAzureDiskInput>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "location",
      "baseName": "location",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "vmName",
      "baseName": "vmName",
      "type": "string",
      "format": ""
    },
    {
      "name": "vmSize",
      "baseName": "vmSize",
      "type": "string",
      "format": ""
    },
    {
      "name": "networkInterface",
      "baseName": "networkInterface",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "diskSettings",
      "baseName": "diskSettings",
      "type": "Array<RestoreAzureDiskInput>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreAzureVMInput.attributeTypeMap;
  }

  public constructor() {
  }
}

