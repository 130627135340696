import { TextField, Select, IconButton, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';

export enum ResourceOperation {
  Include = 'Include',
  Exclude = 'Exclude',
}

interface BackupPolicyManualRuleEditorProps {
  onFinish: (operation: ResourceOperation, resource: string) => void;
  onAbort: () => void;
}

export default function BackupPolicyManualRuleEditor(
  props: BackupPolicyManualRuleEditorProps
) {
  const [operation, setOperation] = useState<ResourceOperation>(
    ResourceOperation.Include
  );
  const [resource, setResource] = useState<string>('');
  return (
    <Stack direction='row' alignItems='center' gap='12px'>
      <Select
        value={operation}
        onChange={(e) => setOperation(e.target.value as ResourceOperation)}
      >
        <MenuItem value={ResourceOperation.Include}>Include</MenuItem>
        <MenuItem value={ResourceOperation.Exclude}>Exclude</MenuItem>
      </Select>
      <TextField
        size='small'
        label='Resource id'
        value={resource}
        onChange={(e) => setResource(e.target.value)}
      />
      <IconButton onClick={() => props.onFinish(operation, resource)}>
        <i className='material-symbols-check' />
      </IconButton>
      <IconButton onClick={props.onAbort}>
        <i className='material-symbols-close-rounded' />
      </IconButton>
    </Stack>
  );
}
