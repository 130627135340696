import { Stack, Typography, Tabs, Tab } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';
import { useState, type SyntheticEvent } from 'react';

import VaultTag from './vaultTag';

interface VaultsTabsProps<T extends string | BackupVault> {
  vaults: T[];
  onVaultChange: (vault: T) => void;
}

export default function VaultsTabs<T extends string | BackupVault>(
  props: VaultsTabsProps<T>
) {
  const { vaults, onVaultChange } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChange = (event: SyntheticEvent, i: number) => {
    onVaultChange(vaults[i]);
    setSelectedIndex(i);
  };

  return (
    <Stack direction='row' alignItems='center' margin={'16px 40px 0'}>
      <Typography marginRight='12px' variant='body1'>
        Restore from
      </Typography>
      <Stack direction='row' alignItems='center' minHeight='50px'>
        {vaults.length === 1 ? (
          <VaultTag vault={vaults[0]} />
        ) : (
          <Tabs
            onChange={onChange}
            value={selectedIndex}
            className='min-h-[50px] border-b-0'
            sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
          >
            {vaults.map((vault) => (
              <Tab
                icon={<VaultTag vault={vault} />}
                key={typeof vault === 'string' ? vault : vault.id}
                className='text-[14px] font-normal px-[12px]'
                disableRipple
              />
            ))}
          </Tabs>
        )}
      </Stack>
    </Stack>
  );
}
