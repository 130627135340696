/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ListAzureSKUs200ResponseSkusInner } from '../models/ListAzureSKUs200ResponseSkusInner';

export class ListAzureSKUs200Response {
  /**
  * List of available SKUs
  */
  'skus': Array<ListAzureSKUs200ResponseSkusInner>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "skus",
      "baseName": "skus",
      "type": "Array<ListAzureSKUs200ResponseSkusInner>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAzureSKUs200Response.attributeTypeMap;
  }

  public constructor() {
  }
}

