/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Information about the storage account where you want to restore the files. If you don\'t specify a target, the files are restored to the original storage account. 
*/
export class StorageAccountRestoreTargetStorageAccount {
  /**
  * Name of the storage account to restore the files to.
  */
  'name': string;
  /**
  * Name of the resource group that contains the storage account.
  */
  'resourceGroup'?: string;
  /**
  * Name of the container in the storage account to restore the files to.
  */
  'container': string;
  /**
  * Prefix to add to the restore path. If you don\'t specify a prefix, the files are restored to their respective folders in the original file tree, starting from the root of the container. 
  */
  'prefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "container",
      "baseName": "container",
      "type": "string",
      "format": ""
    },
    {
      "name": "prefix",
      "baseName": "prefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return StorageAccountRestoreTargetStorageAccount.attributeTypeMap;
  }

  public constructor() {
  }
}

