/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { S3RestoreTarget } from '../models/S3RestoreTarget';
import { S3RestoreTargetS3Bucket } from '../models/S3RestoreTargetS3Bucket';
import { StorageAccountRestoreTarget } from '../models/StorageAccountRestoreTarget';
import { StorageAccountRestoreTargetStorageAccount } from '../models/StorageAccountRestoreTargetStorageAccount';

export class RestoreFilesRequestDestination {
  's3Bucket': S3RestoreTargetS3Bucket;
  'storageAccount': StorageAccountRestoreTargetStorageAccount;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "s3Bucket",
      "baseName": "s3Bucket",
      "type": "S3RestoreTargetS3Bucket",
      "format": ""
    },
    {
      "name": "storageAccount",
      "baseName": "storageAccount",
      "type": "StorageAccountRestoreTargetStorageAccount",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreFilesRequestDestination.attributeTypeMap;
  }

  public constructor() {
  }
}

