/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AzureConnectAccountRequest {
  /**
  * The account type - restore \\ source
  */
  'accountType': AzureConnectAccountRequestAccountTypeEnum;
  /**
  * The tenant ID
  */
  'tenantId': string;
  /**
  * The subscription ID
  */
  'subscriptionId': string;
  /**
  * The application ID
  */
  'applicationId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "accountType",
      "baseName": "accountType",
      "type": "AzureConnectAccountRequestAccountTypeEnum",
      "format": ""
    },
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "subscriptionId",
      "baseName": "subscriptionId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "applicationId",
      "baseName": "applicationId",
      "type": "string",
      "format": "uuid"
    }  ];

  static getAttributeTypeMap() {
    return AzureConnectAccountRequest.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AzureConnectAccountRequestAccountTypeEnum {
  Restore = 'Restore',
  Source = 'Source'
}

