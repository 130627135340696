/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DBSnapshotType } from '../models/DBSnapshotType';

export class FileSnapshot {
  /**
  * The ID of the snapshot
  */
  'snapshotId': string;
  /**
  * The date the snapshot was generated
  */
  'generatedOn': Date;
  /**
  * The date the snapshot expires
  */
  'expiresOn': Date;
  /**
  * The size of the file at the time of the snapshot
  */
  'size': number;
  /**
  * The date the file was last modified
  */
  'modifiedOn': Date;
  /**
  * The user who last modified the file
  */
  'modifiedBy': string;
  /**
  * The mount point of the file
  */
  'mount': string;
  /**
  * The inode of the file
  */
  'inode': number;
  /**
  * The etag of the file
  */
  'etag': string;
  'snapshotType': DBSnapshotType;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "generatedOn",
      "baseName": "generatedOn",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "expiresOn",
      "baseName": "expiresOn",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "size",
      "baseName": "size",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "modifiedOn",
      "baseName": "modifiedOn",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "modifiedBy",
      "baseName": "modifiedBy",
      "type": "string",
      "format": ""
    },
    {
      "name": "mount",
      "baseName": "mount",
      "type": "string",
      "format": ""
    },
    {
      "name": "inode",
      "baseName": "inode",
      "type": "number",
      "format": ""
    },
    {
      "name": "etag",
      "baseName": "etag",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshotType",
      "baseName": "snapshotType",
      "type": "DBSnapshotType",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return FileSnapshot.attributeTypeMap;
  }

  public constructor() {
  }
}



