import { Box, Divider, IconButton, Stack, Tooltip } from '@mui/material';

import { ExtendedIconButton } from '@/components/shared/extendedIconButton';

import { ClassificationChip } from './classificationChip';

interface AutoClassificationProps {
  textOn?: string;
  isAutoClassificationOn: boolean;
  restartDetectionClicked: (e: React.MouseEvent<HTMLElement>) => void;
  editOverridesClicked: (e: React.MouseEvent<HTMLElement>) => void;
  setEditorOpen: (e: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
  disabled?: boolean;
}

export function AutoClassification(props: AutoClassificationProps) {
  const { isAutoClassificationOn } = props;
  const title = isAutoClassificationOn
    ? (props.textOn ?? 'Data classes were detected. Here are few samples:')
    : 'Edited manually';

  return (
    <Stack
      className='mb-4'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      data-testid={props.testId ?? 'auto-classification-buttons'}
    >
      <Box className='font-light'>{title}</Box>
      <Stack direction='row' alignItems='center'>
        <AutoClassificationButtons {...props} />
      </Stack>
    </Stack>
  );
}

function AutoClassificationButtons(props: AutoClassificationProps) {
  if (!props.isAutoClassificationOn) {
    return (
      <>
        <ClassificationChip status='off' label={'Auto Classification is OFF'} />
        <IconButton
          onClick={(e) => props.restartDetectionClicked(e)}
          sx={{
            color: 'var(--mui-palette-primary-main)',
            marginLeft: '12px',
            width: '34px',
            height: '34px',
          }}
        >
          <Tooltip title='Resume'>
            <i className='material-symbols-motion-play-outline-rounded text-xl'></i>
          </Tooltip>
        </IconButton>
        <Divider
          sx={{ height: '16px', marginRight: '24px', marginLeft: '24px' }}
          orientation='vertical'
        />
        <IconButton
          onClick={(e) => props.editOverridesClicked(e)}
          sx={{
            color: 'var(--mui-palette-primary-main)',
            width: '34px',
            height: '34px',
          }}
        >
          <Tooltip title='Edit overrides'>
            <i className='material-symbols-stylus-outline'></i>
          </Tooltip>
        </IconButton>
      </>
    );
  } else {
    return (
      <>
        <ClassificationChip status='on' label='Auto Classification is ON' />
        <ExtendedIconButton
          tooltip='Pause & override'
          disabled={props.disabled}
          onClick={(e) => props.setEditorOpen(e)}
          sx={{
            color: 'var(--mui-palette-primary-main)',
            marginLeft: '12px',
            width: '34px',
            height: '34px',
          }}
        >
          <i className='mdi-stop-circle-outline'></i>
        </ExtendedIconButton>
      </>
    );
  }
}
