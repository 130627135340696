import {
  Button,
  FormControlLabel,
  OutlinedInput,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { NotificationPolicyTypeEnum } from '@repo/api-gw-sdk';
import { useCallback } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useEnvironment } from '@/contexts/useEnvironment';

import type { UpsertNotificationPolicyState } from './upsertNotificationPolicyFlow';

export const NotificationPolicyNameSelection = (
  props: StepProps<UpsertNotificationPolicyState>
) => {
  const { isDev, isDemo } = useEnvironment();

  const next = () => {
    props.setNextStep(props.currentStep.next?.[0]);
  };

  const validateParams = useCallback(
    (name: string): boolean => {
      return name?.length >= 4 && !!props.wizardState.type;
    },
    [props.wizardState.type]
  );

  return (
    <Stack
      sx={{ height: '100%' }}
      alignItems='center'
      justifyContent='center'
      gap='24px'
    >
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Notification policy name
      </Typography>
      <OutlinedInput
        autoFocus
        sx={{
          width: '655px',
          padding: '8px',
        }}
        placeholder='Example: Slack integration'
        onChange={(e) =>
          props.setWizardState((state) => ({
            ...state,
            name: e.target.value,
          }))
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter' && validateParams(props.wizardState.name)) {
            next();
          }
        }}
        value={props.wizardState.name}
        endAdornment={
          <Button
            sx={{ width: '200px' }}
            variant='contained'
            disabled={!validateParams(props.wizardState.name)}
            onClick={next}
          >
            Next
            <i className='material-symbols-arrow-right-alt ml-[8px]' />
          </Button>
        }
      />
      <Stack direction='row'>
        {Object.values(NotificationPolicyTypeEnum)
          .filter(
            (x) =>
              isDemo ||
              isDev ||
              x === NotificationPolicyTypeEnum.Slack ||
              x === NotificationPolicyTypeEnum.Sns
          )
          .map((x) => (
            <div key={x}>
              <FormControlLabel
                title={x}
                control={
                  <Radio
                    disableRipple
                    sx={{ padding: '10px 10px 10px 30px' }}
                    size='small'
                    checked={x === props.wizardState.type}
                    onChange={() =>
                      props.setWizardState((state) => ({
                        ...state,
                        type: x,
                      }))
                    }
                  />
                }
                label={x}
              />
            </div>
          ))}
      </Stack>
    </Stack>
  );
};
