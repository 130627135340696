/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class RestoreAzureDiskInput {
  /**
  * The ID of the disk to restore
  */
  'diskId': string;
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region to restore the vm to
  */
  'location': string;
  /**
  * Name of the resource group to restore the vm to
  */
  'resourceGroup': string;
  /**
  * The name of the disk to restore
  */
  'diskName': string;
  /**
  * The type of the disk to restore
  */
  'diskType': string;
  /**
  * The tier of the disk to restore
  */
  'diskTier': string;
  /**
  * The Hyper-V generation of the disk to restore
  */
  'diskHyperVGeneration'?: string;
  /**
  * The size of the disk to restore in bytes
  */
  'diskSizeBytes'?: number;
  /**
  * Optional tags to apply to the output instance
  */
  'tags'?: { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "diskId",
      "baseName": "diskId",
      "type": "string",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "location",
      "baseName": "location",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroup",
      "baseName": "resourceGroup",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskName",
      "baseName": "diskName",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskType",
      "baseName": "diskType",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskTier",
      "baseName": "diskTier",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskHyperVGeneration",
      "baseName": "diskHyperVGeneration",
      "type": "string",
      "format": ""
    },
    {
      "name": "diskSizeBytes",
      "baseName": "diskSizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreAzureDiskInput.attributeTypeMap;
  }

  public constructor() {
  }
}

