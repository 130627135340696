import {
  Box,
  Typography,
  TablePagination,
  Divider,
  Checkbox,
  Stack,
  IconButton,
  Tooltip,
  type IconButtonProps,
  type Theme,
} from '@mui/material';
import type { Column, RowSelectionInstance } from '@tanstack/react-table';
import classnames from 'classnames';
import React, { forwardRef, useRef } from 'react';

import { ActionsBar, ActionsToolBar } from './actionsBar';
import PaginationTitle from './paginationTitle';

import { ColumnSelector } from '../table/columnsPopup';
import FilterButton from '../table/filterButton';

interface PaginationBarProps<T extends object> {
  dataLength: number;
  isLoading: boolean;
  entityLabel: string;
  selection: {
    multiSelect?: {
      getIsAllRecordsSelected: RowSelectionInstance<T>['getIsAllRowsSelected'];
      getIsSomeRecordsSelected: RowSelectionInstance<T>['getIsSomeRowsSelected'];
      getToggleAllRecordsSelectedHandler: RowSelectionInstance<T>['getToggleAllRowsSelectedHandler'];
      ActionButton?: React.ReactNode;
    };
    selectedRecordsCount?: number;
    isSelectable: boolean;
  };
  pagination?: {
    pageIndex: number;
    pageSize: number;
    pageSizesOptions: readonly (number | { value: number; label: string })[];
    recordsCount?: number;
    recordsLimit?: number;
  };
  onRowsPerPageChange: (pageIndex: number, pageSize: number) => void;
  onPageChange: (page: number) => void;
  filtering?: {
    enableColumnSelection: boolean;
    openFilters?: boolean;
    enableFilters: boolean;
    getAllColumns: () => Column<T>[];
  };
}

export default function PaginationBar<T extends object>(
  props: PaginationBarProps<T>
) {
  const {
    pagination,
    filtering,
    selection,
    onPageChange,
    onRowsPerPageChange,
    entityLabel,
    isLoading,
    dataLength,
  } = props;

  const { multiSelect } = selection;
  const ActionButton = multiSelect?.ActionButton || null;
  const showFilteringActions =
    filtering?.enableColumnSelection || filtering?.enableFilters;

  const recordsCount = useRef(pagination?.recordsCount || dataLength);
  if (typeof pagination?.recordsCount === 'number') {
    if (recordsCount.current !== pagination.recordsCount) {
      recordsCount.current = pagination.recordsCount;
    }
  } else {
    if (recordsCount.current !== dataLength) {
      recordsCount.current = dataLength;
    }
  }

  const areMultiSelected = (selection.selectedRecordsCount ?? 0) > 1;

  return (
    <ActionsBar
      position='static'
      className={classnames('mb-0', 'py-[8px]', 'pl-[28px]', {
        'pr-[28px]': showFilteringActions,
        'pr-[16px]': !showFilteringActions,
      })}
    >
      <ActionsToolBar disableGutters className='pr-[12px]'>
        <Box
          className={classnames('h-full pr-[12px]', {
            'rounded-[4px]': areMultiSelected,
            'shadow-[0px_10px_30px_0px_rgba(0,0,0,0.10)]': areMultiSelected,
            'bg-[var(--mui-palette-background-paper)]': areMultiSelected,
          })}
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            height: '24px',
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            height='52px'
            width='100%'
          >
            <div>
              {!!multiSelect && (
                <Checkbox
                  data-testid={'pagination-select-all'}
                  className={classnames('ml-[12px]')}
                  checked={multiSelect.getIsAllRecordsSelected()}
                  indeterminate={multiSelect.getIsSomeRecordsSelected()}
                  onChange={multiSelect.getToggleAllRecordsSelectedHandler()}
                />
              )}
              <PaginationTitle
                entityLabel={entityLabel}
                selection={{
                  ...selection,
                  isAllSelected: multiSelect?.getIsAllRecordsSelected(),
                }}
                isLoading={isLoading}
                recordsCount={recordsCount.current}
              />
            </div>
            {areMultiSelected && ActionButton}
          </Stack>
        </Box>
        <>
          {pagination && !areMultiSelected && (
            <TablePagination
              rowsPerPageOptions={pagination.pageSizesOptions}
              rowsPerPage={pagination.pageSize}
              component='div'
              disabled={isLoading}
              count={recordsCount.current}
              page={pagination.pageIndex}
              onPageChange={(_, page) => {
                onPageChange?.(page);
              }}
              onRowsPerPageChange={(e) => {
                const pageSize = parseInt(e.target.value);
                const pagesCount = Math.ceil(recordsCount.current / pageSize);
                const pageIndex = Math.min(
                  pagination.pageIndex,
                  pagesCount - 1
                );
                onRowsPerPageChange(pageIndex, pageSize);
              }}
              slotProps={{
                select: {
                  sx: {
                    borderRadius: '4px',
                    marginTop: '2px',
                    marginRight: '17px',
                    color: 'var(--mui-palette-text-primary)',

                    '&:hover': {
                      backgroundColor: '#E7F0F3',
                    },
                  },
                  inputProps: {
                    'aria-label': 'Items per page',

                    sx: {
                      '&:focus': {
                        backgroundColor: 'transparent',
                      },
                    },
                  },
                  IconComponent: () => (
                    <i className='material-symbols-arrow-drop-down-rounded' />
                  ),

                  MenuProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        borderRadius: 0,

                        '&:hover': {
                          backgroundColor: '#E7F0F3',
                        },
                      },
                    },
                  },
                },
                actions: {
                  previousButton: IconButtonPropsFactory('Previous page'),
                  nextButton: IconButtonPropsFactory('Next page'),
                },
              }}
              labelDisplayedRows={({ from, to, count }) => (
                <span className='flex items-center'>
                  <Divider
                    component='span'
                    orientation='vertical'
                    className='mr-[25px] h-[22px] inline-flex'
                  />
                  {count === -1 ? (
                    <Typography component='span'>{`Page ${pagination.pageIndex + 1}`}</Typography>
                  ) : (
                    <Typography component='span'>{`${from}–${to} of ${count === pagination.recordsLimit ? `more than ${count}` : count}`}</Typography>
                  )}
                </span>
              )}
              labelRowsPerPage={
                <Typography component={'span'}>{'Items per page'}</Typography>
              }
            />
          )}
          {showFilteringActions && (
            <>
              <Divider
                orientation='vertical'
                flexItem
                className='my-[15px] mr-[20px]'
              />
              {filtering.enableFilters && (
                <FilterButton
                  columns={filtering.getAllColumns()}
                  openFilters={filtering.openFilters}
                />
              )}
              {filtering.enableColumnSelection && (
                <ColumnSelector columns={filtering.getAllColumns()} />
              )}
            </>
          )}
        </>
      </ActionsToolBar>
    </ActionsBar>
  );
}

const IconButtonPropsFactory = (tooltip: string) => {
  const Button = forwardRef<HTMLButtonElement>(
    ({ title, ...props }: IconButtonProps, ref) => (
      <Tooltip title={tooltip}>
        <IconButton
          {...props}
          ref={ref}
          data-testid={`${tooltip.toLowerCase()}-button`}
          disableRipple
        />
      </Tooltip>
    )
  );

  return {
    component: Button,
    disableRipple: true,

    sx: (theme: Theme) => ({
      borderRadius: '4px',
      padding: '6px',

      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#E7F0F3',
      },

      svg: {
        width: '20px',
        height: '20px',
      },
    }),
  } satisfies IconButtonProps;
};
