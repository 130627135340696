import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

export const RenameEntityName = ({
  name,
  label,
  onChange,
}: {
  label: string;
  name: string;
  onChange: (name: string) => void;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState(name);

  return (
    <Stack direction='row' alignItems='center' gap='4px'>
      <Typography className='mr-[20px]'>{label}</Typography>
      {!editMode && (
        <>
          <Typography className='font-semibold'>{name}</Typography>
          <IconButton
            onClick={() => setEditMode(true)}
            data-testid='rename-entity-name-edit-btn'
          >
            <i
              className={
                'material-symbols-stylus-outline w-[20px] h-[20px] text-textPrimary'
              }
            />
          </IconButton>
        </>
      )}
      {editMode && (
        <>
          <TextField
            inputProps={{ 'data-testid': 'rename-entity-name-input' }}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <IconButton
            data-testid='rename-entity-name-save-btn'
            disabled={!validateName(newName)}
            onClick={() => {
              setEditMode(false);
              onChange(newName);
            }}
          >
            <i
              className={
                'material-symbols-check w-[20px] h-[20px] text-textPrimary'
              }
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditMode(false);
              setNewName(name);
            }}
          >
            <i
              className={
                'material-symbols-close-rounded w-[20px] h-[20px] text-textPrimary'
              }
            />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export const validateName = (name = '') => name.trim().length >= 4;
