import {
  Stack,
  Box,
  Table,
  TableBody,
  Tooltip,
  TableRow,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { DataClass } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import { Roboto_Mono } from 'next/font/google';

import { Loader } from '@/components/layout/loading';
import { Icon } from '@/components/shared/icon';
import { BorderlessTableCell } from '@/components/table/components';
import { useRoles } from '@/contexts/useRoles';
import { useRouting } from '@/contexts/useRouting';
import { useDAL } from '@/data/dal';
import { DataClasses, PiiStringTypes, PiiStrings } from '@/data/inventory/data';

import { AutoClassification } from './autoClassifcationButtons';

function trimLocation(location: string): string {
  // Trims the location to 45 characters, puts ellipsis in the middle
  if (location.length <= 45) {
    return location;
  }
  return `${location.slice(0, 20)}...${location.slice(-25)}`;
}

const roboto = Roboto_Mono({
  weight: '600',
  subsets: ['latin'],
});

function FileLink({
  entity,
  dataLocation,
  isWindowsMachine,
}: {
  entity: InventoryResource;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLocation: any;
  isWindowsMachine: boolean;
}) {
  const { isAuthorizedResource } = useRoles();
  const routing = useRouting();
  if (typeof dataLocation?.location === 'string') {
    let location = dataLocation.location;
    if (isWindowsMachine) {
      if (location.startsWith('/')) {
        location = location.substring(1);
      }

      location = location.replace(/\//g, '\\');
    }

    // Get directory name
    const sep = isWindowsMachine ? '\\' : '/';
    const dirName = location.substring(0, location.lastIndexOf(sep));

    return (
      <Typography
        data-testid='file-link'
        className={classNames([
          'font-semibold',
          {
            'cursor-pointer': isAuthorizedResource('read:files', entity),
          },
        ])}
        onClick={() => {
          if (isAuthorizedResource('read:files', entity)) {
            routing.push(
              `/explorer?resourceId=${encodeURIComponent(entity.id)}&path=${encodeURIComponent(dirName)}&file=${encodeURIComponent(location)}`
            );
          }
        }}
      >
        {trimLocation(location) ?? 'Unknown'}
      </Typography>
    );
  }
  if (
    entity.databaseProperties?.engine === 'mongo' &&
    dataLocation?.database &&
    dataLocation?.table &&
    dataLocation?.column
  ) {
    return (
      <>
        <Tooltip title='Database Name'>
          <span>[{dataLocation?.database}]</span>
        </Tooltip>
        .
        <Tooltip title='Collection Name'>
          <span>[{dataLocation?.table}]</span>
        </Tooltip>
        .
        <Tooltip title='Field Name'>
          <span>[{dataLocation?.column}]</span>
        </Tooltip>
      </>
    );
  }
  if (dataLocation?.database && dataLocation?.table && dataLocation?.column) {
    return (
      <>
        <Tooltip title='Database Name'>
          <span>[{dataLocation?.database}]</span>
        </Tooltip>
        .
        <Tooltip title='Table Name'>
          <span>[{dataLocation?.table}]</span>
        </Tooltip>
        .
        <Tooltip title='Column Name'>
          <span>[{dataLocation?.column}]</span>
        </Tooltip>
      </>
    ); // TODO (DB Explorer)
  }
  return <></>;
}

export function DataClassViewer({
  entity,
  setDataClassOpen: setDataClassEditorOpen,
  setRestartDetectionModalOpen,
}: {
  entity: InventoryResource;
  setDataClassOpen: (e: React.MouseEvent<HTMLElement>) => void;
  setRestartDetectionModalOpen: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const { isAuthorizedResource } = useRoles();
  const dal = useDAL();

  const { body } = dal.inventory.dataClasses.list(entity.id);

  if (!body) {
    return <Loader />;
  }
  const examples = body.dataClassificationEntities;

  if (entity.classifications?.dataClassesDetails?.isOverridden) {
    return (
      <>
        {' '}
        <AutoClassification
          testId='dataclass-auto-classification-buttons-overridden'
          textOn={''}
          isAutoClassificationOn={false}
          restartDetectionClicked={(e) => setRestartDetectionModalOpen(e)}
          editOverridesClicked={(e) => setDataClassEditorOpen(e)}
          setEditorOpen={(e) => setDataClassEditorOpen(e)}
          disabled={!isAuthorizedResource('update:data_classification', entity)}
        />
        {!!entity.classifications.dataClassesDetails.dataClasses?.length &&
          Object.values(DataClass)
            .filter((x) =>
              entity.classifications!.dataClassesDetails!.dataClasses!.includes(
                x
              )
            )
            .map((x) => (
              <Stack
                key={x}
                direction='row'
                sx={{ height: '48px' }}
                alignItems='center'
              >
                <Box className='mr-6'>
                  <Icon
                    iconClass='material-symbols-check'
                    sx={{ height: '24px' }}
                  />
                </Box>
                <Tooltip title={DataClasses[x].description}>
                  <Box>{x}</Box>
                </Tooltip>
              </Stack>
            ))}
      </>
    );
  }

  const textOn = examples.length
    ? 'Data classes were detected. Here are few samples:'
    : 'No data classes were detected';

  const isWindowsMachine = examples.some((x) =>
    ['/C:/', '/D:/', '/E:/', 'C:/', 'D:/', 'E:/'].some((prefix) =>
      x.dataLocation?.location?.toUpperCase()?.startsWith(prefix)
    )
  );

  return (
    <>
      <AutoClassification
        textOn={textOn}
        testId='dataclass-auto-classification-buttons-auto'
        isAutoClassificationOn={true}
        restartDetectionClicked={(e) => setRestartDetectionModalOpen(e)}
        editOverridesClicked={(e) => setDataClassEditorOpen(e)}
        setEditorOpen={(e) => setDataClassEditorOpen(e)}
        disabled={!isAuthorizedResource('update:data_classification', entity)}
      />
      {!!examples.length && (
        <Table>
          <TableBody>
            {examples?.map((x) => (
              <TableRow key={String(x.id)} sx={{ height: '56px' }}>
                <Tooltip
                  title={DataClasses[PiiStringTypes[x.entityType]].description}
                >
                  <BorderlessTableCell className='font-semibold w-2/12'>
                    {PiiStringTypes[x.entityType] ?? '??'}
                  </BorderlessTableCell>
                </Tooltip>
                <BorderlessTableCell className='w-4/12'>
                  {PiiStrings[x.entityType] ?? 'Unknown'}
                </BorderlessTableCell>
                <BorderlessTableCell className={roboto.className}>
                  <FileLink
                    entity={entity}
                    dataLocation={x.dataLocation}
                    isWindowsMachine={isWindowsMachine}
                  />
                </BorderlessTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
