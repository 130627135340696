import {
  Box,
  Button,
  capitalize,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import type { BackupVault, BackupSchedule } from '@repo/api-gw-sdk';
import type { Duration } from 'dayjs/plugin/duration';
import { useEffect, useMemo, useRef, useState } from 'react';

import OptionMenu from '@core/components/option-menu';
import type {
  OptionMenuItemType,
  OptionType,
} from '@core/components/option-menu/types';

import {
  Schedule,
  ScheduleContainer,
  ScheduleSelect,
} from '@/components/schedule/schedule';
import { BackupVaultSelectionDialog } from '@/components/vaults/backupVaultSelectionDialog';
import VaultTag from '@/components/vaults/vaultTag';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { dayjs } from '@/utils/dayjs';

import {
  DurationSelector,
  type DurationFragment,
} from '../controls/MaximumRetentionEditor';
import { frequencyMapping } from '../controls/MinimumRetentionEditor';

interface BackupPolicyScheduleProps {
  schedule: BackupSchedule;
  deleteAllowed?: boolean;
  onUpdate: (schedule: BackupSchedule) => void;
  onDuplicate: (schedule: BackupSchedule) => void;
  onDelete: (schedule: BackupSchedule) => void;
}

export function BackupPolicySchedule(props: BackupPolicyScheduleProps) {
  const { data: vaults, loading } = useBackupVaults();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [vault, setVault] = useState<BackupVault | undefined>(undefined);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedFrequency, setFrequency] = useState(
    frequencyMapping.find(
      ({ windows }) => windows === props.schedule.windows
    ) || frequencyMapping[0]
  );

  useEffect(() => {
    if (!loading && vaults) {
      setVault(vaults.find((v) => v.id === props.schedule.vaultId));
    }
  }, [vaults, loading, props.schedule.vaultId]);

  const duration: Duration = useMemo(
    () =>
      dayjs.duration({
        days: props.schedule.backupRetention || 1,
      }),
    [props.schedule.backupRetention]
  );

  const updateWindow = (windows: string) => {
    props.onUpdate({
      ...props.schedule,
      windows,
    });
    setFrequency(
      frequencyMapping.find(({ windows: _windows }) => _windows === windows) ||
        frequencyMapping[0]
    );
  };

  const updateRetention = (retention: number) => {
    const backupRetention = Math.floor(
      dayjs.duration({ [selectedFrequency.unit]: retention }).asDays()
    );

    props.onUpdate({
      ...props.schedule,
      backupRetention,
    });
  };

  const retentionByFragment = Math.round(
    duration[`as${capitalize(selectedFrequency.unit)}` as DurationFragment]()
  );

  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='12px'>
        <Typography variant='body1'>Take</Typography>
        <ScheduleSelect
          value={props.schedule.windows}
          onChange={(e) => updateWindow(e.target.value as string)}
          variant='standard'
          data-testid='create-backup-policy-frequency-select'
        >
          {frequencyMapping.map((r) => (
            <MenuItem key={r.windows} value={r.windows}>
              <Typography variant='body1'>{r.text.toLowerCase()}</Typography>
            </MenuItem>
          ))}
        </ScheduleSelect>
        <Typography variant='body1'>and retain for</Typography>
        <>
          <Button
            ref={anchorRef}
            onClick={() => setPopoverOpen(true)}
            data-testid='create-backup-policy-retention-select'
          >
            {`${retentionByFragment} ${selectedFrequency.unit}`}
            <i className='material-symbols-arrow-drop-down-rounded text-xl' />
          </Button>
          <Popover
            open={popoverOpen}
            anchorEl={anchorRef.current}
            onClose={() => setPopoverOpen(false)}
          >
            <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
              <Typography variant='subtitle1'>
                Choose a retention time counter
              </Typography>
              <DurationSelector
                fragment={selectedFrequency.unit}
                duration={duration}
                onChange={updateRetention}
              />
            </Box>
          </Popover>
        </>
        <Typography variant='body1'>on</Typography>
        <ScheduleSelect
          sx={{
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
          }}
          data-testid='create-backup-policy-vault-select'
          variant='standard'
          value={props.schedule.vaultId || 0}
          open={false}
          native={false}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {(!props.schedule.vaultId || !vault) && (
            <MenuItem value={props.schedule.vaultId || 0}>
              <Typography variant='body1'>Choose Vault</Typography>
            </MenuItem>
          )}

          {props.schedule.vaultId && vault && (
            <MenuItem value={props.schedule.vaultId}>
              <VaultTag
                vault={vault}
                sx={{ margin: 0, marginRight: '16px', cursor: 'pointer' }}
              />
            </MenuItem>
          )}
        </ScheduleSelect>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            {
              testId: 'duplicate-backup-policy',
              text: 'Duplicate',
            },
            {
              testId: 'delete-backup-policy',
              text: 'Delete',
              menuItemProps: { disabled: !props.deleteAllowed },
            },
          ]}
          onOptionSelected={(option: OptionType): void => {
            switch ((option as OptionMenuItemType).text) {
              case 'Duplicate':
                props.onDuplicate(props.schedule);
                break;
              case 'Delete':
                props.onDelete(props.schedule);
                break;
            }
          }}
        />
      </Schedule>
      <BackupVaultSelectionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(vault) => {
          props.onUpdate({ ...props.schedule, vaultId: vault.id });
          setDialogOpen(false);
        }}
        scheduleWindow={(
          frequencyMapping.find((f) => f.windows === props.schedule.windows)
            ?.text || ''
        ).toLowerCase()}
        selectedVaults={[props.schedule.vaultId]}
      />
    </ScheduleContainer>
  );
}
