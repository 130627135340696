/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AmiBlockDevice {
  /**
  * The device name.
  */
  'name': string;
  /**
  * The device volume type.
  */
  'type': string;
  /**
  * The device volume size in bytes.
  */
  'sizeBytes': number;
  /**
  * The device volume IOPS.
  */
  'iops'?: number;
  /**
  * The device volume throughput.
  */
  'throughput'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "iops",
      "baseName": "iops",
      "type": "number",
      "format": ""
    },
    {
      "name": "throughput",
      "baseName": "throughput",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AmiBlockDevice.attributeTypeMap;
  }

  public constructor() {
  }
}

