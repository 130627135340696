import { Divider, Stack, Switch, Typography } from '@mui/material';
import type { Provider } from '@repo/api-gw-sdk';
import React from 'react';

import { OtherSnapshotsMultipleDots } from '@/components/snapshots/otherSnapshotsMultipleDots';
import { CloudProviders } from '@/data/inventory/data';

interface OtherSnapshotsToggleProps {
  cloudProvider: Provider;
}

export default function OtherSnapshotsToggle(props: OtherSnapshotsToggleProps) {
  const { cloudProvider } = props;
  const [showOtherSnapshots, setShowOtherSnapshots] = React.useState(false);

  return (
    <>
      <Stack
        padding='16px 40px'
        direction='row'
        gap='12px'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack direction='row' gap='12px' alignItems='center'>
          <OtherSnapshotsMultipleDots />
          <Typography>{`Show ${CloudProviders[cloudProvider]?.displayName} snapshots`}</Typography>
        </Stack>
        <Switch
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#F1A81B',
            },
          }}
          checked={showOtherSnapshots}
          onChange={(e) => setShowOtherSnapshots(e.target.checked)}
          size='small'
        />
      </Stack>
      <Divider />
    </>
  );
}
