/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AzureSubscription {
  /**
  * The subscription resource ID
  */
  'id': string;
  /**
  * The subscription ID
  */
  'subscriptionId': string;
  /**
  * The tenant ID
  */
  'tenantId'?: string;
  /**
  * The subscription name
  */
  'name': string;
  /**
  * The subscription state
  */
  'state'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "subscriptionId",
      "baseName": "subscriptionId",
      "type": "string",
      "format": ""
    },
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "state",
      "baseName": "state",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureSubscription.attributeTypeMap;
  }

  public constructor() {
  }
}

