import { Stack, Tooltip, Typography, type TooltipProps } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

interface TooltipContentProps {
  isAuthorized?: boolean;
  title: string | null;
}

const TooltipContent = (props: TooltipContentProps) => {
  return (
    <Stack marginX='4px' direction='column' gap='4px'>
      {props.title && (
        <Typography
          className={classnames('text-xs', {
            'font-semibold': !props.isAuthorized,
          })}
          color='var(--mui-palette-primary-dark)'
        >
          {props.title}
        </Typography>
      )}
      {!props.isAuthorized && (
        <Typography
          width='176px'
          color='var(--mui-palette-primary-dark)'
          className='text-xs'
        >
          An admin has blocked this action for your current user role.
        </Typography>
      )}
    </Stack>
  );
};

interface AuthorizationTooltipProps extends Omit<TooltipProps, 'title'> {
  isAuthorized?: boolean;
  title?: string;
}

export const AuthorizationTooltip = (props: AuthorizationTooltipProps) => {
  const { children, isAuthorized = true, title, ...tooltipProps } = props;

  return (
    <Tooltip
      {...tooltipProps}
      disableHoverListener={!title && isAuthorized}
      title={
        <TooltipContent isAuthorized={isAuthorized} title={title || null} />
      }
    >
      {children}
    </Tooltip>
  );
};
