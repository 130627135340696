/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AwsDatabaseDestinationAwsRds } from '../models/AwsDatabaseDestinationAwsRds';

export class AwsDatabaseDestination {
  'awsRds': AwsDatabaseDestinationAwsRds;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "awsRds",
      "baseName": "awsRds",
      "type": "AwsDatabaseDestinationAwsRds",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsDatabaseDestination.attributeTypeMap;
  }

  public constructor() {
  }
}

