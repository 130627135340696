import { Box, Stack, Typography } from '@mui/material';
import {
  BackupStatus,
  type BackupVault,
  type InventoryResource,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import MainDialog from '@/components/dialogs/mainDialog';
import InfoSnackbar from '@/components/infoSnackbar';
import BackupVaultCard from '@/components/vaults/backupVaultCard';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import RetentionSelector from '../../settings/controls/retentionSelector';

interface TakeSnapshotDialogProps {
  entities: InventoryResource[];
  onClose: () => void;
}

export default function TakeSnapshotDialog(props: TakeSnapshotDialogProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [retentionInDays, setRetentionInDays] = useState(7);
  const [selectedVault, setSelectedVault] = useState<BackupVault | undefined>();
  const { entities, onClose } = props;
  const dal = useDAL();
  const { data: vaults, loading } = useBackupVaults();
  const providerVaults = vaults?.filter(
    (vault) => vault.cloudProvider === entities[0].cloudProvider
  );

  const triggerTakeSnapshot = async () => {
    if (!selectedVault) {
      return;
    }

    await Promise.all(
      entities.map((resource) => {
        if (
          resource.backupStatus === BackupStatus.Terminated ||
          resource.backupStatus === BackupStatus.Disconnected
        ) {
          return Promise.resolve();
        }

        return dal.jobs.backup.create(
          resource.id,
          selectedVault.id,
          retentionInDays
        );
      })
    );

    setSnackbarOpen(true);
    onClose();
  };

  const entitiesNames = props.entities.map((x) => x.resourceName).join(',');

  return (
    <>
      <MainDialog
        isOpen={true}
        isLoading={loading}
        header={{
          title: 'Take Snapshot',
          subtitle: 'Select retention and vault to take an on-demand backup.',
          onClose,
        }}
        footer={{
          primaryButton: {
            'data-testid': 'take-snapshot-button',
            iconClass: 'material-symbols-backup-outline-rounded',
            text: 'Take snapshot',
            disabled: loading || !selectedVault || retentionInDays < 1,
            onClick: triggerTakeSnapshot,
          },
          secondaryButton: {
            'data-testid': 'cancel-take-snapshot-button',
            onClick: onClose,
            text: 'Cancel',
          },
        }}
      >
        <Stack gap='24px'>
          <Stack gap='12px' paddingX='40px'>
            <Typography>Retention (calculated from now)</Typography>
            <RetentionSelector onChange={setRetentionInDays} />
          </Stack>
          <Stack gap='12px'>
            <Typography paddingLeft='40px'>Choose a vault:</Typography>
            <Stack overflow='scroll' marginY='12px' direction='row' gap='24px'>
              {loading
                ? [...Array(3)].map((_, index) => (
                    <BackupVaultCard key={index} loading={true} />
                  ))
                : providerVaults?.map((vault) => (
                    <Box
                      key={vault.id}
                      className='first:pl-[40px] last:pr-[40px]'
                    >
                      <BackupVaultCard
                        style={{ width: '320px', height: '184px' }}
                        data={vault}
                        editable={false}
                        clickable
                        onClick={setSelectedVault}
                        selected={vault === selectedVault}
                      />
                    </Box>
                  ))}
            </Stack>
          </Stack>
        </Stack>
      </MainDialog>
      <InfoSnackbar
        message={`Started backup job for ${entitiesNames}`}
        isOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
}
