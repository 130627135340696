import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

const title = 'Subnet*';

export const AWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
  hideLabel,
  availabilityZoneScope,
}: {
  accountId?: string;
  regionName?: string;
  subnetId?: string;
  availabilityZoneScope?: string;
  onChange: (subnetId?: string, isUserChange?: boolean) => void;
  hideLabel?: boolean;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  return (
    <InnerAWSSubnetSelect
      accountId={accountId}
      regionName={regionName}
      subnetId={subnetId}
      onChange={onChange}
      hideLabel={hideLabel}
      availabilityZoneScope={availabilityZoneScope}
    />
  );
};

const InnerAWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
  hideLabel,
  availabilityZoneScope,
}: {
  accountId: string;
  regionName: string;
  subnetId?: string;
  availabilityZoneScope?: string;
  onChange: (subnetId?: string, event?: boolean) => void;
  hideLabel?: boolean;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.listAwsSubnets.list(
    accountId,
    regionName
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (subnetId && !body?.subnets?.some((x) => x.id === subnetId)) {
      onChange(undefined);
    }
  }, [body?.subnets, isLoading, onChange, subnetId]);

  if (!body?.subnets?.length) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      {!hideLabel && <FormLabel>{title}</FormLabel>}
      <Select
        data-testid='awsSubnetSelect'
        size='small'
        value={subnetId || ''}
        onChange={(event) => onChange(event.target.value, !!event)}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
      >
        {body.subnets
          .filter(
            (x) =>
              !availabilityZoneScope ||
              x.availabilityZone === availabilityZoneScope
          )
          .map((subnet, index) => (
            <MenuItem
              data-testid={`awsSubnetOption-${index}`}
              key={subnet.id}
              value={subnet.id}
            >
              {subnet.id}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
