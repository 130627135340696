/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class CreateApiCredentialsRequest {
  /**
  * The name of the api credential user
  */
  'givenName': string;
  /**
  * The role ID of the api credential user
  */
  'roleId': string;
  /**
  * The projects the api credential user has access to
  */
  'projects': Array<string>;
  /**
  * The date the api credentials expires
  */
  'expiresAt'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "givenName",
      "baseName": "givenName",
      "type": "string",
      "format": ""
    },
    {
      "name": "roleId",
      "baseName": "roleId",
      "type": "string",
      "format": ""
    },
    {
      "name": "projects",
      "baseName": "projects",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "expiresAt",
      "baseName": "expiresAt",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CreateApiCredentialsRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

