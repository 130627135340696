import type { InventoryResource } from '@repo/api-gw-sdk';

import ConfirmationDialog from '@/components/dialogs/confirmationDialog';
import { useDAL } from '@/data/dal';

interface ExcludeFromBackupProps {
  isOpen: boolean;
  entities: InventoryResource[];
  onClose: () => void;
}

export default function ExcludeFromBackupDialog(props: ExcludeFromBackupProps) {
  const { entities, isOpen, onClose } = props;
  const dal = useDAL();

  const excludeFromBackup = async () => {
    await Promise.all(
      entities.map((entity) =>
        dal.inventory.resources.excludeResourceFromBackup(entity.id)
      )
    );
    onClose();
  };
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title='Exclude from backup'
      description="Eon doesn't back up or report violations for excluded resources."
      onCancel={onClose}
      onConfirm={excludeFromBackup}
      buttonIconClass='material-symbols-hide-source-outline-rounded'
      buttonTitle='Exclude'
    />
  );
}
