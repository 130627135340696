/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureResourceGroup } from '../models/AzureResourceGroup';

export class AzureResourceGroups {
  'resourceGroups': Array<AzureResourceGroup>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceGroups",
      "baseName": "resourceGroups",
      "type": "Array<AzureResourceGroup>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureResourceGroups.attributeTypeMap;
  }

  public constructor() {
  }
}

