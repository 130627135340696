import { Box, Stack, Button, Typography, Dialog } from '@mui/material';

import { Icon } from '@/components/shared/icon';

export function RestartAutoClassificationPanel({
  open,
  onClose,
  onSave,
  title,
  description,
}: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  title: string;
  description: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog open={open}>
      <Box>
        <Stack className='h-full'>
          <Stack
            justifyContent='space-between'
            direction='row-reverse'
            alignItems='center'
            padding='16px 24px'
          >
            <Icon
              onClick={onClose}
              iconClass='material-symbols-close-rounded'
            />
          </Stack>

          <Stack alignItems={'center'} sx={{ mb: '40px', px: '40px' }}>
            <Box sx={{ maxWidth: '340px', textAlign: 'center' }}>
              <h1 className='font-semibold text-xl'>{title}</h1>
            </Box>
            <Box
              className='font-light'
              sx={{ maxWidth: '580px', textAlign: 'center' }}
            >
              <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                {description}
              </Box>
              <Stack direction={'row'} justifyContent={'center'} gap='12px'>
                <Box>
                  <Button onClick={onClose}>
                    <Typography>Cancel</Typography>
                  </Button>
                </Box>
                <Box>
                  <Button onClick={onSave} variant='outlined'>
                    <i className='material-symbols-motion-play-outline-rounded text-xl'></i>
                    <Typography marginLeft='8px'>Resume</Typography>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
