import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { ResourceType } from '@repo/api-gw-sdk';
import { useMemo } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import type { AzureDiskConfigState } from '../restore/restoreAzureVMWizard';

export const VirtualMachineSizeSelect = ({
  accountId,
  location,
  vmSize,
  onChange,
  disksState,
  originalVMSize,
}: {
  accountId?: string;
  location?: string;
  vmSize?: string;
  disksState: AzureDiskConfigState[] | undefined;
  onChange: (vmSize: string) => void;
  originalVMSize: string | undefined;
}) => {
  if (!accountId || !location) {
    return <EmptySelect title='Virtual Machine Size*' selectClass='h-[58px]' />;
  }

  return (
    <InnerVirtualMachineSizeSelect
      accountId={accountId}
      location={location}
      vmSize={vmSize}
      onChange={onChange}
      disksState={disksState}
      originalVMSize={originalVMSize}
    />
  );
};

const InnerVirtualMachineSizeSelect = ({
  accountId,
  location,
  vmSize,
  onChange,
  disksState,
  originalVMSize,
}: {
  accountId: string;
  location: string;
  vmSize?: string;
  disksState: AzureDiskConfigState[] | undefined;
  onChange: (vmSize: string) => void;
  originalVMSize: string | undefined;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.azureRestoreConfig.listSKUs(
    accountId,
    location,
    ResourceType.AzureVirtualMachine
  );

  const SKUChoices = useMemo(() => {
    const dataDisksLength = (disksState?.length ?? 1) - 1;
    const originalVMSKU = body?.skus?.find((x) => x.name === originalVMSize);
    const diskHyperVGenerations = Array.from(
      new Set(disksState?.map((d) => d.diskSettings.diskHyperVGeneration))
    ).filter(Boolean);
    return (body?.skus ?? []).filter((sku) => {
      const skuGenerations = sku.capabilities?.HyperVGenerations?.split(',');
      return (
        (sku.capabilities?.CpuArchitectureType ?? '') ===
          (originalVMSKU?.capabilities?.CpuArchitectureType ?? '') &&
        Number(sku.capabilities?.MaxDataDiskCount ?? '') >= dataDisksLength &&
        diskHyperVGenerations.every((g) => skuGenerations?.includes(g))
      );
    });
  }, [disksState, body?.skus, originalVMSize]);

  if (!body?.skus?.length) {
    return <EmptySelect title='Virtual Machine Size*' selectClass='h-[58px]' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Virtual Machine Size*</FormLabel>
      <Select
        className='h-[58px]'
        size='small'
        value={vmSize || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {SKUChoices.map((vmSize) => (
          <MenuItem key={vmSize.name} value={vmSize.name}>
            {vmSize.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
