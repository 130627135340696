/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class CloudAccountConfigurationAwsSecurityGroup {
  'key': string;
  'securityGroupId': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "key",
      "baseName": "key",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroupId",
      "baseName": "securityGroupId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CloudAccountConfigurationAwsSecurityGroup.attributeTypeMap;
  }

  public constructor() {
  }
}

